import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { SingleUserOrb } from "../../pages/inApp/yourOrbit/components/singleUserOrb";
import * as OrbitCanvasUtilities from "../../pages/inApp/yourOrbit/utilities/orbitCanvasUtils";

import { useAuthenticationClientContextTwo } from "../../pages/testing/auth2Flow/onboardingContextClientTwo";
import { getUserNetwork } from "../../utils/helpers/ourAPIHelpers";

const DIV_SIZE = 500;
const RADIUS = 150;

export default function FrameIndex() {
  const [network, setNetwork] = useState([]);
  const { currentUser } = useAuthenticationClientContextTwo();
  const [loading, setLoading] = useState(true);
  const [center, setCenter] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });

  useEffect(() => {
    // Fetch and process network data
    if (currentUser) {
      OrbitCanvasUtilities.fetchAndProcessNetworkData(
        getUserNetwork,
        currentUser,
        setNetwork,
        setLoading,
        RADIUS,
        center
      );
    }
  }, [currentUser]);
  console.log(network);
  return (
    <html lang="en">
      <head>
        <Helmet>
          <meta charSet="utf-8" />
          <title>hunter</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="vNext" />
          <meta property="og:image" content="http://...image-question.png" />
          <meta
            property="og:description"
            content="Choose your color: Green, Purple, Red, Blue"
          />
        </Helmet>
      </head>
      <body>
        <div className="flex w-screen h-screen bg-white  items-center justify-center">
          <div className="flex flex-col w-[600px] h-[400px] bg-insetPrimary border border-1 border-[#E7E8ED] rounded-[18px] shadow-[0_2px_3px_rgba(0,0,0,0.1)] p-4">
            <img src={currentUser.pfp_url} className="h-12 w-12 rounded-full" />
          </div>
        </div>
      </body>
    </html>
  );
}
