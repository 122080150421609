import { ComponentProps, useState } from "react";

export const SegmentControl = () => {
  const [activeSegment, setActiveSegment] = useState(0);
  return (
    <div className="text-white rounded-[12px] recess">
      <div className="bg-grayscale-300 rounded-[12px] relative w-full">
        <Control style={{ "--left": `${(100 / 3) * activeSegment}%` }} />
        <div className="z-1 w-full flex relative">
          <button
            data-active={activeSegment === 0}
            onClick={() => setActiveSegment(0)}
            className="group w-1/3 p-3 data-[active=true]:opacity-100 opacity-50 flex items-center justify-center gap-4"
          >
            <div className="flex items-center gap-2">Slow</div>
            <span
              className={[
                "text-sm bg-white/20 rounded-full px-2 group-data-[active=true]:bg-teal-400/30",
                "transtion-color delay-200",
              ].join(" ")}
            >
              0.00005
            </span>
          </button>
          <button
            data-active={activeSegment === 1}
            onClick={() => setActiveSegment(1)}
            className="group w-1/3 p-3 data-[active=true]:opacity-100 opacity-50 flex items-center justify-center gap-4"
          >
            <div className="flex items-center gap-2">Normal</div>
            <span
              className={[
                "text-sm bg-white/20 rounded-full px-2 group-data-[active=true]:bg-teal-400/30",
                "transtion-color delay-200",
              ].join(" ")}
            >
              0.0005
            </span>
          </button>
          <button
            data-active={activeSegment === 2}
            onClick={() => setActiveSegment(2)}
            className="group w-1/3 p-3 data-[active=true]:opacity-100 opacity-50 flex items-center justify-center gap-4"
          >
            <div className="flex items-center gap-2">Fast</div>
            <span
              className={[
                "text-sm bg-white/20 rounded-full px-2 group-data-[active=true]:bg-teal-400/30",
                "transtion-color delay-200",
              ].join(" ")}
            >
              0.005
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
const Control = ({ className, ...props }) => {
  return (
    <div
      {...props}
      className={[
        "w-1/2 h-full absolute z-[0] top-0 left-[--left] p-1 transition-all",
        className,
      ].join(" ")}
    >
      <div className="bg-gradient-to-b from-grayscale-400/70 to-grayscale-500/70 h-full w-full rounded-[9px] shadow-[inset_0_1px_0.5px_rgba(255,255,255,0.2)]"></div>
    </div>
  );
};
export const SolUSDToggle = () => {
  const [activeSegment, setActiveSegment] = useState(0);
  const numberOfSegments = 2; // Since there are two buttons
  return (
    <div className="text-white rounded-[12px] recess font-medium">
      <div className="bg-grayscale-300/70 rounded-[12px] relative w-full">
        <Control
          style={{
            "--left": `${(50 / (numberOfSegments - 1)) * activeSegment}%`,
          }}
        />
        <div className="z-1 w-full flex relative">
          <button
            data-active={activeSegment === 0} // Changed from 1 to 0
            onClick={() => setActiveSegment(0)}
            className="group w-1/2 px-3 data-[active=true]:opacity-100 opacity-50 flex items-center justify-center gap-4"
          >
            <div className="flex items-center gap-2">ETH</div>
          </button>
          <button
            data-active={activeSegment === 1} // Changed from 2 to 1
            onClick={() => setActiveSegment(1)}
            className="group w-1/2 px-3 py-1.5 data-[active=true]:opacity-100 opacity-50 flex items-center justify-center gap-4"
          >
            <div className="flex items-center gap-2">USD</div>
          </button>
        </div>
      </div>
    </div>
  );
};
