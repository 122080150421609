import React, { useState, useEffect } from "react";

import "./index.css";

import NavBar from "./components/navBar";
import Router from "./routes/routesIndex";
import { getUser } from "./pages/authentication/onboardingHelpers";
import { useAuthenticationClientContextTwo } from "./pages/testing/auth2Flow/onboardingContextClientTwo";
function App() {
  const isLoading = false;
  if (isLoading) {
    return <div className="w-screen h-screen bg-ourBlue-400">Loading...</div>;
  }

  return (
    <div className="App ">
      <Router />
    </div>
  );
}
export default App;
