import React, { useState, useCallback, useEffect } from "react";
import { fabric } from "fabric";

export const processDataOnCanvas = (
  data,
  canvasRef,
  SCALE_FACTOR,
  size = 500
) => {
  try {
    if (canvasRef.current && (data.drawing || data.images || data.text)) {
      const canvas = new fabric.Canvas(canvasRef.current, {
        height: size,
        width: size,
        selectable: false,
        interactive: false,
      });

      // Process images
      data.images?.forEach((imgData) => {
        fabric.Image.fromURL(imgData.src, (img) => {
          img.set({
            left: imgData.left * SCALE_FACTOR,
            top: imgData.top * SCALE_FACTOR,
            scaleX: imgData.scaleX * SCALE_FACTOR,
            scaleY: imgData.scaleY * SCALE_FACTOR,
            angle: imgData.angle,
            opacity: imgData.opacity,
          });
          img.selectable = false;
          canvas.add(img);
        });
      });

      // Process drawing
      data.drawing?.forEach((drawingData) => {
        const pathString = drawingData.path
          .map((segment) => segment.join(" "))
          .join(" ");
        const path = new fabric.Path(pathString, {
          ...drawingData,
          fill: drawingData.fill || "",
          stroke: drawingData.stroke || "",
          strokeWidth: drawingData.strokeWidth,
          scaleX: SCALE_FACTOR,
          scaleY: SCALE_FACTOR,
          left: drawingData.left * SCALE_FACTOR,
          top: drawingData.top * SCALE_FACTOR,
        });
        path.selectable = false;
        canvas.add(path);
      });

      // Process text
      data.text?.forEach((textData) => {
        const textObj = new fabric.Text(textData.text, {
          ...textData,
          left: textData.left * SCALE_FACTOR,
          top: textData.top * SCALE_FACTOR,
          fontSize: textData.fontSize * SCALE_FACTOR,
        });
        textObj.selectable = false;
        canvas.add(textObj);
      });

      return () => {
        if (canvas) {
          // Dispose of the canvas instance
          canvas.dispose();
          canvas = null; // Clear the reference
        }
      };
    }
  } catch (error) {
    console.error("Error processing cell data:", error);
  }
};
