import FlutedGlass from "../../authentication/flutedGlass";
import "./networkImport.css";
import solIcon from "../../../assets/SOL.png";
import ethereumLofo from "../../../assets/etherIcon.png";
import baseIcon from "../../../assets/icons/baseIcon.webp";
export default function NetworkImportIndex() {
  const headerSubheaderText = (
    <>
      <p className="text-3xl font-bold text-ourPurple-900">
        Build your Social Graph
      </p>
      <p className="w-4/5">
        We are building an on chain social network, but we need your help. To
        simplify the process of including your friends and community members,
        add your addresses below to easily connect with friends across all
        chains. <br />
        Learn more about our goals here.
      </p>
    </>
  );
  return (
    <div className="flex w-full h-full primary-bg rounded-2xl items-center justify-center">
      <div className="flex w-1/2 h-1/2 items-center justify-center">
        <FlutedGlass>
          <div className="flex flex-col w-full p-4 items-center text-center gap-2">
            {headerSubheaderText}
            <AddressInputField
              icon={<img src={baseIcon} className="h-4 w-4" alt="cardano" />}
              placeholder={"Chain"}
            />
          </div>
        </FlutedGlass>
      </div>
    </div>
  );
}
function AddressInputField({ value, onChange, showError, placeholder, icon }) {
  const errorRingColor = showError ? "ring-red-500" : "ring-ourTeal-800";
  return (
    <div
      className={`flex w-full items-center justify-center bg-white group text-gray-600 font-sans px-4 py-2 gap-2 text-md rounded-xl border border-1 border-borderPrimary shadow-md focus-within:ring-2 focus-within:ring-ourTeal-800 focus-within:outline-none`}
    >
      {icon}
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full focus:ring-0 focus:outline-none"
      />
    </div>
  );
}
