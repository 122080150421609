import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowUpRight, ChevronDown, Clipboard } from "lucide-react";
import { getOwnedCells } from "../../../utils/helpers/ourAPIHelpers";
import { useAuthenticationClientContextTwo } from "../../testing/auth2Flow/onboardingContextClientTwo";
import { truncateAddress } from "../../../utils/helpers/formattingHelpers";

import "./scrollbar.css";
import SingleCellForWall from "../yourWall/singleCellForWall";
import SingleCellForBrickDisplay from "./singleCellForBrickDisplay";
import solIcon from "../../../assets/solono.png";
import placeholder3d from "../../../assets/icons/placeholder3d.png";
import ProfileHeader from "./profileHeader";

import ActivityDisplay from "./activityDisplay";

// -------||----------------

export default function YourBricksIndex() {
  const [bricks, setBricks] = useState([]);
  const [userBricks, setUserBricks] = useState([]);
  // console.log(userBricks);
  const { currentUser } = useAuthenticationClientContextTwo();
  const loading = false;
  const error = false;

  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("yourCells");
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (tab) {
      setActiveTab(tab);
    }
  }, [location.search]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    navigate(`?tab=${tab}`); // Update the URL with the new tab
  };
  useEffect(() => {
    let isMounted = true;
    async function fetchBricks() {
      if (currentUser) {
        try {
          const ownedCells = await getOwnedCells(currentUser.id);
          if (isMounted) {
            // Filter out bricks where wall_id matches currentUser.wall_id
            const userOwnBricks = ownedCells.filter(
              (brick) => brick.wall_id === currentUser.wall_id
            );
            setUserBricks(userOwnBricks);

            // Set the remaining bricks
            const otherBricks = ownedCells.filter(
              (brick) => brick.wall_id !== currentUser.wall_id
            );
            setBricks(otherBricks);
          }
        } catch (error) {
          console.error("Error fetching owned cells:", error);
        }
      }
    }

    fetchBricks();
    return () => {
      isMounted = false;
    };
  }, [currentUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching bricks. Please try again later.</div>;
  }

  const yourCellsDisplay = (
    <>
      {activeTab === "yourCells" && (
        <div className="grid grid-cols-4 grid-rows-4  overflow-hidden gap-2 ">
          {userBricks.map((brick, index) => (
            <UpdatedBrickDisplay key={index} brick={brick} />
          ))}
          {bricks.map((brick, index) => (
            <UpdatedBrickDisplay key={index} brick={brick} />
          ))}
          {/* <UpdatedBrickDisplay /> */}
        </div>
      )}
    </>
  );

  const activityDisplay = (
    <>{activeTab === "activity" && <ActivityDisplay />}</>
  );
  return (
    <div className="w-full h-full overflow-x-hidden space-y-2 scrollbar-custom">
      <ProfileHeader currentUser={currentUser} />
      <div className="flex w-full gap-2">
        <TabBarButton
          tabName="Your Cells"
          isActive={activeTab === "yourCells"}
          onClick={() => handleTabClick("yourCells")}
        />
        <TabBarButton
          tabName="Activity"
          isActive={activeTab === "activity"}
          onClick={() => handleTabClick("activity")}
        />
        <TabBarButton
          tabName="Watch List"
          isActive={activeTab === "watchList"}
          onClick={() => handleTabClick("watchList")}
        />
      </div>
      {yourCellsDisplay}
      {activityDisplay}
    </div>
  );
}
function TabBarButton({ tabName, isActive, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`flex w-max px-4 py-2 rounded-lg  font-semibold cursor-pointer text-sm ${
        isActive
          ? "bg-ourPurple-500 text-gray-900"
          : "hover:bg-ourPurple-500 text-gray-400"
      }`}
    >
      <p>{tabName}</p>
    </div>
  );
}
{
  /* <div className="grid grid-cols-5 grid-rows-4 overflow-auto ">
{bricks.map((brick, index) => (
  <SingleBrickDisplay key={index} brick={brick} />
))}
</div> */
}
// function ProfileHeader({ currentUser }) {
//   const [copySuccess, setCopySuccess] = useState("");

//   const handleCopyClick = () => {
//     navigator.clipboard
//       .writeText(currentUser.ref_code_to_share)
//       .then(() => {
//         setCopySuccess("Copied!");
//         setTimeout(() => setCopySuccess(""), 5000);
//       })
//       .catch((err) => console.error("Could not copy text: ", err));
//   };
//   return (
//     <>
//       <div className="flex w-full justify-between">
//         <div className="flex flex-col pt-2">
//           <div className="flex gap-2">
//             <img
//               src={currentUser.pfp_url}
//               alt="your pfp"
//               className="h-16 w-16 rounded-full border-2 border-ourTeal-600 "
//             />
//             <span className="flex flex-col">
//               <p className="text-3xl font-sans font-bold">
//                 {currentUser.user_name}
//               </p>
//               <p className="text-sm font-sans font-regular text-gray-500">
//                 {truncateAddress(currentUser.user_address)}
//               </p>
//             </span>
//           </div>
//         </div>
//         <div className="flex flex-col  items-end">
//           <p className="text-lg font-sans font-regular text-gray-500">
//             Referral Code:
//           </p>
//           <span
//             className="flex items-center gap-2 p-2 hover:bg-ourBlue-500 rounded-lg cursor-pointer"
//             onClick={handleCopyClick}
//             title="Click to copy your referral code"
//           >
//             <Clipboard size={16} />
//             <p className="text-sm font-sans font-regular text-gray-500">
//               {copySuccess || currentUser.ref_code_to_share}
//             </p>
//           </span>
//         </div>
//       </div>
//     </>
//   );
// }

function UpdatedBrickDisplay({ brick }) {
  const hasData =
    brick.data &&
    (brick.data.text.length > 0 ||
      brick.data.images.length > 0 ||
      brick.data.drawing.length > 0);

  const navigate = useNavigate();

  const handleEditClick = () => {
    console.log("Navigating with brick:", brick);
    navigate(`/${brick.wall_owner_username}/${brick.cell_id}/edit`, {
      state: { brick },
    });
  };

  return (
    <div
      className="relative bg-white rounded-2xl shadow-sm mb-2 border border-1 overflow-clip group hover:cursor-pointer"
      onClick={handleEditClick}
    >
      <div
        className={`flex ${
          hasData ? "bg-white/50" : "bg-white"
        } h-64 justify-end transition items-center justify-center duration-100 overflow-clip hover:scale-105 transition duration-300`}
      >
        {hasData ? (
          <SingleCellForBrickDisplay
            cellData={brick.data}
            size={400}
            SCALE_FACTOR={400 / 800}
          />
        ) : (
          <div className="flex items-center opacity-30 flex-col gap-1">
            <img
              src={placeholder3d}
              alt="icon"
              className="h-24 w-24 animate-spin-slow "
              style={{ animation: "spin 10s linear infinite" }}
            />
            <p className="text-gray-500 text-xl">So Empty</p>
          </div>
        )}
      </div>
      <div className="flex justify-between p-4 items-center transition duration-300 transform  bg-white gap-2 ">
        <div className="flex items-center gap-2 text-lg ">
          <img
            src={brick.wall_owner_pfp_url}
            alt="cardano pfp"
            className="h-10 w-10 rounded-full"
          />
          <span className="-space-y-1">
            <p>{brick.wall_owner_username}</p>
            <span className="flex gap-1 items-center">
              <img src={solIcon} alt="cardano icon" className="h-5 w-5" />
              <p className="text-sm text-gray-400">{brick.current_price}</p>
            </span>
          </span>
        </div>
        <span className="flex px-2 py-1 h-min rounded-lg bg-gray-200 items-center text-gray-500">
          {`${brick.x_position}${brick.y_position}`}
        </span>
      </div>
      {/* Buttons appearing on hover
      <div className="absolute bottom-0 left-0 right-0 flex justify-center px-4 py-3 bg-ourTeal-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 gap-4">
        <button className="text-white text-md">
          <div className="flex gap-1 items-center">View </div>
        </button>
      </div> */}
    </div>
  );
}
