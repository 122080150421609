// orbitCanvasUtilities.js

// Calculate the position of each user orb in the canvas
export const calculatePosition = (index, total, circleRadius, CENTER) => {
  const angle = (index / total) * 2 * Math.PI;
  return {
    x: CENTER.x + circleRadius * Math.cos(angle),
    y: CENTER.y + circleRadius * Math.sin(angle),
  };
};

// Fetch and process network data
export const fetchAndProcessNetworkData = async (
  getUserNetwork,
  currentUser,
  setNetwork,
  setLoading,
  RADIUS,
  CENTER
) => {
  const now = Date.now();
  const cachedData = localStorage.getItem("networkData");
  let data;

  if (cachedData) {
    const { lastChecked, network } = JSON.parse(cachedData);
    if (now - lastChecked > 300000) {
      data = await fetchNetworkData(getUserNetwork, currentUser);
    } else {
      data = network;
    }
  } else {
    data = await fetchNetworkData(getUserNetwork, currentUser);
  }

  processNetworkData(data, setNetwork, setLoading, RADIUS, CENTER);
};

const fetchNetworkData = async (getUserNetwork, currentUser) => {
  try {
    const response = await getUserNetwork(currentUser.id);
    localStorage.setItem(
      "networkData",
      JSON.stringify({ network: response, lastChecked: Date.now() })
    );
    return response;
  } catch (error) {
    console.error("Error fetching user network:", error);
    return { relationships: [] };
  }
};

const processNetworkData = (data, setNetwork, setLoading, RADIUS, CENTER) => {
  const sortedNetwork = data.relationships.sort(
    (a, b) => a.closeness - b.closeness
  );
  let friendsInCurrentCircle = 6;
  let currentCircleCount = 0;
  let currentCircleRadius = RADIUS;
  let totalFriendsAllocated = 0;

  const withPositions = sortedNetwork.map((user, index) => {
    if (currentCircleCount >= friendsInCurrentCircle) {
      totalFriendsAllocated += friendsInCurrentCircle;
      currentCircleRadius += RADIUS;
      const remainingFriends = sortedNetwork.length - totalFriendsAllocated;
      friendsInCurrentCircle = Math.min(
        remainingFriends,
        friendsInCurrentCircle + 2
      );
      currentCircleCount = 0;
    }

    const position = calculatePosition(
      currentCircleCount,
      friendsInCurrentCircle,
      currentCircleRadius,
      CENTER
    );
    currentCircleCount++;
    return { ...user, position };
  });

  setNetwork(withPositions);
  setLoading(false);
};
export const processOtherUserNetwork = ({
  data,
  setNetwork,
  setLoading,
  RADIUS,
  CENTER,
}) => {
  console.log("from the process other", data);

  const sortedNetwork = data.relationships.sort(
    (a, b) => a.closeness - b.closeness
  );

  let friendsInCurrentCircle = 6;
  let currentCircleCount = 0;
  let currentCircleRadius = RADIUS;
  let totalFriendsAllocated = 0;

  const withPositions = sortedNetwork.map((user, index) => {
    if (currentCircleCount >= friendsInCurrentCircle) {
      totalFriendsAllocated += friendsInCurrentCircle;
      currentCircleRadius += RADIUS;
      const remainingFriends = sortedNetwork.length - totalFriendsAllocated;
      friendsInCurrentCircle = Math.min(
        remainingFriends,
        friendsInCurrentCircle + 2
      );
      currentCircleCount = 0;
    }

    const position = calculatePosition(
      currentCircleCount,
      friendsInCurrentCircle,
      currentCircleRadius,
      CENTER
    );
    currentCircleCount++;
    return { ...user, position };
  });
  console.log("from process other", withPositions);
  setNetwork(withPositions);
  setLoading(false);
};

export async function getOtherUserNetwork({
  userId,
  getUserNetwork,
  setLoading,
  RADIUS,
  CENTER,
  setNetwork,
}) {
  try {
    setLoading(true);
    const response = await getUserNetwork(userId);

    // Pass parameters as an object to processOtherUserNetwork
    processOtherUserNetwork({
      data: response,
      setNetwork,
      setLoading,
      RADIUS,
      CENTER,
    });

    // No need to setNetwork(processedData) here, as it's already done inside processOtherUserNetwork
    // setLoading(false) is also handled inside processOtherUserNetwork
  } catch (error) {
    console.error("Error fetching other user's network:", error);
    setLoading(false);
    setNetwork([]);
  }
}
