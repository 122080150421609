import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebase";
import {
  PrimaryButton,
  PrimaryButtonWithDisable,
} from "../../../components/elements/primaryButton";
import { useAuthenticationClientContextTwo } from "./onboardingContextClientTwo";
import FlutedGlass from "../../authentication/flutedGlass";
import { PrimaryInputWithErrorStateAndOnChange } from "../../../components/elements/primaryInput";
import CustomWalletButton from "../walletConnectionUI/CustomWalletButton";
import { createNewUser } from "../../../utils/helpers/ourAPIHelpers";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { X } from "lucide-react";
import { Connection, PublicKey } from "@solana/web3.js";

export default function CreateAccountStep() {
  const { referrer, firebaseUser, goToStep, updateCurrentUser } =
    useAuthenticationClientContextTwo();
  const wallet = useWallet();
  const [username, setUsername] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [ourUser, setOurUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); // State to store the error message
  const [meetsMinimumSolBalance, setMeetsMinimumSolBalance] = useState(false);
  const { connection } = useConnection(); // Get the connection from the context

  const sanitizeUsername = (displayName) =>
    displayName
      .replace(/\s+/g, "")
      .replace(/[^a-zA-Z_]/g, "")
      .toLowerCase()
      .substring(0, 20);

  const isButtonDisabled = () =>
    !isWalletConnected ||
    username.trim().length === 0 ||
    displayName.trim().length === 0 ||
    username.length > 20 ||
    displayName.length > 25;

  const checkSolanaBalance = async () => {
    if (wallet.publicKey) {
      const balance = await connection.getBalance(
        new PublicKey(wallet.publicKey)
      );
      const solBalance = balance / 1e9; // Convert lamports to SOL
      const meetsMinimum = solBalance >= 0.07;
      setMeetsMinimumSolBalance(meetsMinimum);
    }
  };

  const generateReferralCode = () =>
    Math.floor(100000 + Math.random() * 900000).toString();

  useEffect(() => {
    setIsWalletConnected(wallet.connected);

    if (wallet.connected) {
      checkSolanaBalance();
      const refCodeUsed = referrer.refCodeToShare;
      const referrerId = referrer.id;
      const newUser = {
        id: firebaseUser.uid,
        user_name: username,
        display_name: displayName,
        pfp_url: firebaseUser.providerData[0].photoURL,
        twitter_id: firebaseUser.providerData[0].uid,
        last_active: Date.now(),
        has_launched: true,
        user_address: wallet.publicKey.toString(),
        user_has_address: true,
        ref_code_used: refCodeUsed,
        ref_code_to_share: generateReferralCode(),
        no_of_codes_left: 20,
        wall_id: firebaseUser.uid,
        no_of_points: 100,
        referrer_id: referrerId,
        completed_onboarding: false,
        is_verified: false,
      };
      setOurUser(newUser);
      updateCurrentUser(newUser);
    }
  }, [wallet.connected, firebaseUser, username, displayName]);

  useEffect(() => {
    setUsername(sanitizeUsername(firebaseUser.displayName));
    setDisplayName(firebaseUser.displayName);
  }, [firebaseUser.displayName]);

  const handleCreateAccount = async () => {
    if (ourUser && !isButtonDisabled()) {
      try {
        const newPfpUrl = await uploadProfilePicture(
          storage, // You need to pass your Firebase storage instance here
          firebaseUser.uid,
          firebaseUser.providerData[0].photoURL
        );
        const updatedUser = { ...ourUser, pfp_url: newPfpUrl };
        await createNewUser(updatedUser);
        if (meetsMinimumSolBalance) {
          goToStep("showGiftClaim");
        } else {
          goToStep("noSolInWallet");
        }
      } catch (error) {
        setErrorMessage(error.message);
        setTimeout(() => setErrorMessage(""), 3000);
      }
    }
  };

  return (
    <div className="flex flex-col w-[28em] h-[35em] gap-1 justify-center">
      <div className="flex w-[28em] h-[29em]">
        <FlutedGlass>
          <div className="flex flex-col w-full h-full  justify-between p-4">
            <div className="flex flex-col gap-2 w-full items-center">
              <img
                src={firebaseUser.providerData[0].photoURL}
                className="h-24 w-24 rounded-full "
              />
              <p className=" text-center text-2xl font-bold text-gray-900">
                {" "}
                Welcome to kido, <br />
                {firebaseUser.displayName}
              </p>
              <div className="flex flex-col justify-start w-full">
                <p className="pl-1 text-gray-600">Username</p>
                <PrimaryInputWithErrorStateAndOnChange
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Username"
                />
              </div>
              <div className="flex flex-col justify-start w-full">
                <p className="pl-1 text-gray-600">Display Name</p>
                <PrimaryInputWithErrorStateAndOnChange
                  value={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                  placeholder="Display Name"
                />
              </div>
            </div>

            <CustomWalletButton />
            <PrimaryButtonWithDisable
              action={handleCreateAccount}
              title={"Create Account"}
              color={"ourPurple"}
              disabled={isButtonDisabled()}
            />
          </div>
        </FlutedGlass>
      </div>
      {errorMessage && (
        <div
          className={`flex gap-2 items-center bg-red-400 rounded-lg p-2 font-bold text-lg text-white transition-opacity  duration-200 ${
            errorMessage ? "opacity-100" : "opacity-0"
          }`}
          style={{ transitionProperty: "opacity, visibility" }}
        >
          <X />
          {errorMessage}
        </div>
      )}
    </div>
  );
}

async function uploadProfilePicture(storage, userId, imageUrl) {
  try {
    // Fetch the image from the URL
    const response = await fetch(imageUrl);
    const imageBlob = await response.blob();

    // Create a reference in Firebase Storage
    const uniqueId = uuidv4();
    const imageRef = ref(storage, `profile_pictures/${userId}/${uniqueId}`);

    // Upload the image
    const snapshot = await uploadBytes(imageRef, imageBlob);
    const downloadURL = await getDownloadURL(snapshot.ref);

    return downloadURL;
  } catch (error) {
    console.error("Error uploading profile picture to Firebase", error);
    throw error;
  }
}
