const network = "devnet";

export function heliusXRayLink(txId) {
  return ` https://xray.helius.xyz/tx/${txId}?network=${network}`;
}
export function shyftLink(txId) {
  return `https://translator.shyft.to/tx/${txId}?cluster=${network}`;
}
export function solanaExplorerLink(txId) {
  return `https://explorer.solana.com/tx/${txId}?cluster=${network}`;
}
// https://translator.shyft.to/tx/2qb3AxkfDd4s5FDEdJjsYVTVmWe7U4VfzzqhLCYCbvse5kDqqKbTny4CJ8S1hvWZ1wDRdd4zp8nrtXVAbxWtwDXF?cluster=devnet
