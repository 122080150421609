// helpers.js
export function extractUuidFromBlobUrl(blobUrl) {
  return blobUrl.split("/").pop();
}

export async function handleSaveCanvasText(formattedData, client, cellId) {
  for (const text of formattedData.text) {
    const insertTextSql = `
      INSERT INTO cell_texts (text_id, origin_x, origin_y, left, top, width, height, scale_x, scale_y, angle, flip_x, flip_y, opacity, fill, stroke, stroke_width, font_family, font_weight, font_size, text, underline, overline, linethrough, text_align, font_style, line_height, text_background_color, char_spacing, styles, direction, path_align, path_side, cell_id)
      VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9, $10, $11, $12, $13, $14, $15, $16, $17, $18, $19, $20, $21, $22, $23, $24, $25, $26, $27, $28, $29, $30, $31, $32, $33);
    `;
    const values = [
      text.textId,
      text.originX,
      text.originY,
      text.left,
      text.top,
      text.width,
      text.height,
      text.scaleX,
      text.scaleY,
      text.angle,
      text.flipX,
      text.flipY,
      text.opacity,
      text.fill,
      text.stroke,
      text.strokeWidth,
      text.fontFamily,
      text.fontWeight,
      text.fontSize,
      text.text,
      text.underline,
      text.overline,
      text.linethrough,
      text.textAlign,
      text.fontStyle,
      text.lineHeight,
      text.textBackgroundColor,
      text.charSpacing,
      JSON.stringify(text.styles),
      text.direction,
      text.pathAlign,
      text.pathSide,
      cellId,
    ];
    await client.query(insertTextSql, values);
  }
}

export async function handleSaveCanvasDrawings(formattedData, client, cellId) {
  for (const drawing of formattedData.drawing) {
    const insertDrawingSql = `
      INSERT INTO cell_drawings (drawing_id, origin_x, origin_y, left, top, width, height, scale_x, scale_y, angle, flip_x, flip_y, opacity, fill, stroke, stroke_width, stroke_dash_array, stroke_line_cap, stroke_dash_offset, stroke_line_join, stroke_uniform, stroke_miter_limit, shadow, visible, background_color, fill_rule, paint_first, global_composite_operation, skew_x, skew_y, path, cell_id)
      VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9, $10, $11, $12, $13, $14, $15, $16, $17, $18, $19, $20, $21, $22, $23, $24, $25, $26, $27, $28, $29, $30, $31, $32, $33);
    `;
    const values = [
      drawing.drawingId,
      drawing.originX,
      drawing.originY,
      drawing.left,
      drawing.top,
      drawing.width,
      drawing.height,
      drawing.scaleX,
      drawing.scaleY,
      drawing.angle,
      drawing.flipX,
      drawing.flipY,
      drawing.opacity,
      drawing.fill,
      drawing.stroke,
      drawing.strokeWidth,
      JSON.stringify(drawing.strokeDashArray),
      drawing.strokeLineCap,
      drawing.strokeDashOffset,
      drawing.strokeLineJoin,
      drawing.strokeUniform,
      drawing.strokeMiterLimit,
      JSON.stringify(drawing.shadow),
      drawing.visible,
      drawing.backgroundColor,
      drawing.fillRule,
      drawing.paintFirst,
      drawing.globalCompositeOperation,
      drawing.skewX,
      drawing.skewY,
      JSON.stringify(drawing.path),
      cellId,
    ];
    await client.query(insertDrawingSql, values);
  }
}

export async function handleSaveCanvasImages(formattedData, client, cellId) {
  for (const image of formattedData.images) {
    const insertImageSql = `
      INSERT INTO cell_images (image_id, image_url, origin_x, origin_y, width, height, scale_x, scale_y, angle, flip_x, flip_y, opacity, shadow, stroke_uniform, stroke_miter_limit, crop_x, crop_y, cell_id)
      VALUES ($1, $2, $3, $4, $5, $6, $7, $8, $9, $10, $11, $12, $13, $14, $15, $16, $17, $18);
    `;
    const values = [
      image.imageId,
      image.src,
      image.originX,
      image.originY,
      image.width,
      image.height,
      image.scaleX,
      image.scaleY,
      image.angle,
      image.flipX,
      image.flipY,
      image.opacity,
      JSON.stringify(image.shadow),
      image.strokeUniform,
      image.strokeMiterLimit,
      image.cropX,
      image.cropY,
      cellId,
    ];
    await client.query(insertImageSql, values);
  }
}

// other helper functions can go here
export async function saveCanvasToDb(formattedData, userId, cellId) {
  // Insert Images
  // Insert Drawings
}
