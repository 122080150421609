import { Check, Minus, Plus } from "lucide-react";
import {
  getFriendshipStatus,
  sendFriendRequest,
} from "../../utils/helpers/ourAPIHelpers";

import { useAuthenticationClientContextTwo } from "../../pages/testing/auth2Flow/onboardingContextClientTwo";
import { useEffect, useState } from "react";

export default function FriendStatusButton({ friend_id, ignore }) {
  const { currentUser } = useAuthenticationClientContextTwo();

  const [status, setStatus] = useState("not");
  /* THREE DISPLAY OPTIONS FOR FRIEND REQUEST STATUS*/
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (ignore) {
        setStatus("CONFIRMED");
      } else {
        async function fetchFriendshipStatus() {
          try {
            const data = await getFriendshipStatus({
              current_user_id: currentUser.id,
              friend_id: friend_id,
            });
            setStatus(data.status); // Set the status state to the 'status' field from the response
          } catch (error) {
            console.error("Failed to fetch friendship status:", error);
          }
        }

        fetchFriendshipStatus();
      }
    }, 1500);

    return () => clearTimeout(timeoutId);
  }, [currentUser.id, friend_id, ignore]);
  const confirmedFriends = (
    <div className="flex h-6 w-6 rounded-full bg-ourTeal-600 items-center justify-center p-0.5 hover:scale-110 transition duration-300 ease hover:cursor-pointer">
      <Check className="stroke-ourTeal-900" size={18} />
    </div>
  );
  const pendingApproval = (
    <div className="flex h-6 w-6 rounded-full bg-ourTeal-600 items-center justify-center p-0.5 hover:scale-110 transition duration-300 ease hover:cursor-pointer">
      <Minus className="stroke-ourTeal-900" size={18} />
    </div>
  );
  const requestFriend = (
    <div className="flex h-6 w-6 rounded-full bg-ourTeal-600 items-center justify-center p-0.5 hover:scale-110 transition duration-300 ease hover:cursor-pointer">
      <Plus className="stroke-ourTeal-900" size={18} />
    </div>
  );
  /* FUNCTION TO HANDLE DISPLAY BASED ON FRIEND STATUS */

  function handleIconDisplay() {
    if (status.toUpperCase() === "CONFIRMED") {
      return confirmedFriends;
    } else if (status === "pending") {
      return pendingApproval;
    } else {
      return requestFriend;
    }
  }
  /* FUNCTION TO HANDLE CLICK OF BUTTON */
  function handleButton() {
    if (status != "confirmed")
      sendFriendRequest({
        requested_user_id: currentUser.id,
        requesting_user_id: friend_id,
      });
  }
  return <button onClick={() => handleButton()}>{handleIconDisplay()}</button>;
}
