import React, { useEffect, useState } from "react";
import BaseWallWrapper from "../components/modularWall/baseWallWrapper";
import { useParams } from "react-router-dom";
import { WallDisplayProvider } from "../utils/contexts/wallDisplayContext";
import WallComponent from "../components/modularWall/baseWallComponent";
import { getCellsForUsername } from "../utils/helpers/ourAPIHelpers";
import { useAuthenticationClientContextTwo } from "./testing/auth2Flow/onboardingContextClientTwo";
import LoadingDots from "../components/placeholders/loadingDots";

export default function OtherUserWall() {
  const [cells, setCells] = useState(null); // Use null as the initial state
  const { username } = useParams();
  const { currentUser } = useAuthenticationClientContextTwo();
  useEffect(() => {
    const fetchCells = async () => {
      try {
        // Ensure the current user ID is available before fetching
        if (currentUser && currentUser.id) {
          const fetchedCells = await getCellsForUsername(
            username,
            currentUser.id
          );
          setCells(fetchedCells);
        }
      } catch (error) {
        console.error("Error fetching cells. NO ERROR:", error);
      }
    };

    if (username) {
      fetchCells();
    }
  }, [username, currentUser]);

  return (
    <WallDisplayProvider>
      {cells ? (
        <WallComponent cells={cells} />
      ) : (
        <div>
          <LoadingDots />
        </div>
      )}
    </WallDisplayProvider>
  );
}
