import React, { useState, useEffect } from "react";
import { QRCode } from "react-qrcode-logo";
import FlutedGlass from "../../authentication/flutedGlass";
import {
  PrimaryButton,
  SecondaryButtonWithDisable,
} from "../../../components/elements/primaryButton";
import logo from "../../../assets/icons/flower.svg";
import solanaIcon from "../../../assets/SOL.png";
import { useAuthenticationClientContextTwo } from "./onboardingContextClientTwo";
import { useConnection } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { useNavigate } from "react-router-dom";
import "./claimCells.css";
import { BarChart, Clipboard, Cuboid, Droplets } from "lucide-react";
import { truncateAddress } from "../../../utils/helpers/formattingHelpers";
import LoadingDots from "../../../components/placeholders/loadingDots";
import { SolanaIcon } from "../../../assets/icons/iconComponents";
export default function ClaimGiftedCellsView() {
  const { currentUser } = useAuthenticationClientContextTwo();
  const { connection } = useConnection();
  const navigate = useNavigate();
  const [balance, setBalance] = useState(0);
  const [isBalanceSufficient, setIsBalanceSufficient] = useState(false);
  console.log(currentUser);

  const fetchBalance = async () => {
    if (currentUser.user_address) {
      const userPublicKey = new PublicKey(currentUser.user_address);
      const userBalance = await connection.getBalance(userPublicKey);
      const solBalance = userBalance / 1e9; // Convert lamports to SOL
      console.log(solBalance);
      setBalance(solBalance);
      setIsBalanceSufficient(solBalance >= 0.1);
    }
  };

  useEffect(() => {
    fetchBalance();
  }, [currentUser.user_address, connection]);

  const goToOrbit = () => {
    navigate("/orbit");
  };

  const qrSection = (
    <div className="flex-col  gap-2 space-y-2 m-1">
      <QRCode
        value={currentUser.user_address}
        size={200}
        fgColor="#2D8F87"
        bgColor="#fff"
        logoImage={logo}
        logoWidth={100}
        logoHeight={100}
        logoOpacity={0.4}
        eyeRadius={12}
        qrStyle="dots"
      />

      <div className="flex justify-between h-12 items-center gap-2 px-4 py-2 bg-gradient-to-b from-white to-stone-200/10 shadow-[0_1px_3px_rgba(0,0,0,0.2)] rounded-[12px]">
        {truncateAddress(currentUser.user_address)}
        <div className="border-r border-grayscale-300/50 h-8"></div>
        <Clipboard className="stroke-grayscale-400 hover:stroke-grayscale-900 hover:cursor-pointer hover:scale-98 transformation transition duration-300 opacity active:stroke-ourTeal-900 active:fill-ourTeal-500" />
      </div>
    </div>
  );

  const lowBalanceView = (
    <>
      {qrSection}
      <div className="flex flex-col gap-3">
        <p className="text-2xl font-sans text-center font-bold text-grayscale-600">
          Please add at least 0.1 SOL to your Wallet to continue
        </p>
        <PrimaryButton title={"Continue"} disabled={!isBalanceSufficient} />
        <button
          onClick={fetchBalance}
          className="refresh-balance-button text-grayscale-400"
        >
          {balance.toFixed(2)} | Refresh Balance
        </button>
      </div>
    </>
  );

  return (
    <div className="flex flex-col w-full h-full bg-gradient-to-b from-white to-stone-200/10 shadow-[0_1px_3px_rgba(0,0,0,0.2)] rounded-[12px] p-4 items-center justify-between">
      {isBalanceSufficient ? <RandomCellCollect /> : lowBalanceView}
    </div>
  );
}

function RandomCellCollect() {
  const placeholderP = {
    pfp_url:
      "https://pbs.twimg.com/profile_images/1541856870163431425/heNZQVyk_normal.jpg",
    one_w_vol: "12",
    cell_count: 1,
    total_price: "12",
    user_name: "hunter",
  };
  return (
    <div className="flex flex-col w-full h-full  items-center justify-center">
      <p className="text-3xl text-grayscale-600 font-bold">Collect Airdrop</p>
      <p className="text-sm text-grayscale-500 font-medium text-center">
        As a thank you for joining Kido, every user receives 6 cells from random
        users on the platform.
      </p>
      <div className="flex w-full h-full items-center justify-center gap-2">
        <div className="flex flex-col w-full justify-center gap-2">
          <div className="flex w-full gap-2 items-center justify-between">
            <SingleCellForClaim portfolioItem={placeholderP} />
            <SingleCellForClaim portfolioItem={placeholderP} />
            <SingleCellForClaim portfolioItem={placeholderP} />
          </div>
          <div className="flex w-full gap-2 items-center justify-center">
            <SingleCellForClaim portfolioItem={placeholderP} />
            <SingleCellForClaim portfolioItem={placeholderP} />
            <SingleCellForClaim portfolioItem={placeholderP} />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full items-center">
        <div className="flex flex-col w-full">
          <PrimaryButton title={"Mint"} />
        </div>
      </div>
    </div>
  );
}

function SingleCellForClaim({ portfolioItem }) {
  return (
    <div className="flex w-52 h-full inter bg-gradient-to-b from-white to-stone-200/10 shadow-[0_1px_3px_rgba(0,0,0,0.2)] rounded-[12px] px-2 py-1 flex-grow">
      <div className=" flex flex-col w-full  rounded-xl gap-2 p-2">
        <span className="flex flex-col w-full items-center justify-center">
          <img src={portfolioItem.pfp_url} className="w-12 h-12 rounded-full" />
          <p className="text-grayscale-800 inter text-md">
            {portfolioItem.user_name}
          </p>
        </span>
        <span className="flex gap-1 w-full justify-between items-center">
          <span className="flex gap-2">
            <Droplets className="stroke-grayscale-400" size={18} />
            <p className="text-grayscale-500 inter text-xs"> Volume (1w)</p>
          </span>
          <span className="flex items-center gap-[4px]">
            <SolanaIcon size={"3"} />
            <p className="text-grayscale-800 inter text-sm">
              {portfolioItem.one_w_vol}
            </p>
          </span>
        </span>
        <span className="flex gap-1 w-full justify-between items-center">
          <span className="flex gap-2">
            <Cuboid className="stroke-grayscale-400" size={18} />
            <p className="text-grayscale-500 inter text-xs">Cell</p>
          </span>

          <p className="text-grayscale-800 inter text-sm">
            x{portfolioItem.cell_count}
          </p>
        </span>
        <span className="flex gap-1 w-full justify-between items-center">
          <span className="flex gap-2">
            <BarChart className="stroke-grayscale-400" size={18} />

            <p className="text-grayscale-500 inter text-xs">Mkt Cap</p>
          </span>

          <p className="text-grayscale-800 inter text-sm">
            {portfolioItem.total_price}
          </p>
        </span>
      </div>
    </div>
  );
}
