import React, { createContext, useContext, useEffect, useState } from "react";

// Create the context
const WallDisplayContext = createContext();

// Hook to use the context
export const useWallDisplayContext = () => useContext(WallDisplayContext);

// Provider component
export const WallDisplayProvider = ({ children }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(0.55);
  const [showPrices, setShowPrices] = useState(false);
  const [isClick, setIsClick] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showCanvas, setShowCanvas] = useState(true);
  const [userCells, setUserCells] = useState([]);
  const [displayedUser, setDisplayedUser] = useState([]);
  const [selectedCell, setSelectedCell] = useState([]);
  const [showMarketHelper, setShowMarketHelper] = useState(false);
  const [cellShoppingCart, setCellShoppingCart] = useState([]);
  const [selectedDisplayOption, setSelectedDisplayOption] = useState("wall");

  useEffect(() => {
    console.log(selectedDisplayOption);
  }, [selectedDisplayOption]);

  const addToShoppingCart = (cell) => {
    setCellShoppingCart((prevCart) => {
      // Extract user_address from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const userAddress = urlParams.get("userAddress");

      // Check if the cell is already in the shopping cart
      const isCellInCart = prevCart.some(
        (cartItem) => cartItem.cell_id === cell.cell_id
      );
      if (!isCellInCart) {
        // If it's not in the cart, add it with the user_address
        return [...prevCart, { ...cell, wall_owner_address: userAddress }];
      }
      // If it's already in the cart, return the previous cart without adding
      return prevCart;
    });
  };
  const removeFromShoppingCart = (cell) => {
    setCellShoppingCart((prevCart) => {
      // Filter out the cell to be removed
      return prevCart.filter((cartItem) => cartItem.cell_id !== cell.cell_id);
    });
  };
  return (
    <WallDisplayContext.Provider
      value={{
        isDragging,
        setIsDragging,
        startPos,
        setStartPos,
        offset,
        setOffset,
        scale,
        setScale,
        showPrices,
        setShowPrices,
        isClick,
        setIsClick,
        loading,
        setLoading,
        userCells,
        setUserCells,
        showCanvas,
        setShowCanvas,
        displayedUser,
        setDisplayedUser,
        selectedCell,
        setSelectedCell,
        showMarketHelper,
        setShowMarketHelper,
        cellShoppingCart,
        setCellShoppingCart,
        selectedDisplayOption,
        setSelectedDisplayOption,
        addToShoppingCart,
        removeFromShoppingCart,
      }}
    >
      {children}
    </WallDisplayContext.Provider>
  );
};
