import { useState, useEffect } from "react";

const useSolanaPrice = () => {
  const [price, setPrice] = useState(
    localStorage.getItem("solPrice")
      ? parseFloat(localStorage.getItem("solPrice"))
      : null
  );
  const [isLoading, setIsLoading] = useState(!price);
  const PRICE_FETCH_INTERVAL = 1000; // 15 minutes in milliseconds

  useEffect(() => {
    const fetchPrice = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
        );
        const data = await response.json();
        setPrice(data.solana.usd);
        localStorage.setItem("solPrice", data.ethereum.usd);
        localStorage.setItem("lastFetched", Date.now());
      } catch (error) {
        console.error("Failed to fetch Solana price:", error);
      } finally {
        setIsLoading(false);
      }
    };

    const lastFetched = localStorage.getItem("lastFetched");
    const shouldFetch =
      !lastFetched ||
      Date.now() - new Date(parseInt(lastFetched)).getTime() >
        PRICE_FETCH_INTERVAL;

    if (shouldFetch) {
      fetchPrice();
    } else {
      setIsLoading(false);
    }
  }, []);

  return { price, isLoading };
};

export default useSolanaPrice;
