import React, { useState } from "react";
import farcasterIcon from "../../assets/farcasterIcon.png";
import { useUser, FarcasterKitProvider } from "farcasterkit";
import twitterLogo from "../../assets/icons/xLogo.png";
import { SecondaryButtonWifIcon } from "../../components/elements/primaryButton";
import { UserCheckIcon } from "lucide-react";
import TwitterAuthUtils from "./utils/twitterConnectUtils";

export default function AddNetwork() {
  return (
    <FarcasterKitProvider>
      <InnerAddNetwork />
    </FarcasterKitProvider>
  );
}

function InnerAddNetwork() {
  const { data, loading } = useUser({ fid: "240865" });

  function handleFarcasterPress() {
    if (loading) {
      console.log("Loading user data...");
    } else if (data) {
      console.log("Farcaster user data:", data);
      // You can now use the 'data' object to perform further actions
    } else {
      console.error("No user data found");
    }
  }
  const twitterAuthUtils = new TwitterAuthUtils(
    "WEUxaWVOVUVGTlotQmE2Q2NCWXU6MTpjaQ",
    process.env.REACT_APP_TWITTER_CLIENT_SECRET,
    "http://localhost:3000/testingtwo"
  );
  const codeVerifier =
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15);

  // Function to handle Twitter authorization
  async function handleTwitterPress() {
    // Generate a random state and code challenge for OAuth security
    const state = Math.random().toString(36).substring(2, 15);
    const codeChallenge = codeVerifier; // Assuming codeVerifier is already defined

    // Store the code verifier and state in sessionStorage for later use
    sessionStorage.setItem("codeVerifier", codeVerifier);
    sessionStorage.setItem("oauthState", state);

    // Get the authorization URL
    const authUrl = twitterAuthUtils.getAuthorizeUrl(state, codeChallenge);
    //localhost:3000/testingtwo?state=xiyqq6x8n3d&code=RjZ1MGlndW93QkxLTmt6aThLQzhhc1ZTM1oxOTctaEtRcmpfZ2F3dFRGaF9sOjE3MDY4ODYxNDgwMDg6MTowOmFjOjE
    // Redirect the user to the authorization URL
    http: window.location.href = authUrl;
  }
  async function handleRedirectAfterTwitterAuth() {
    // Parse the query string of the current URL
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    // Verify the state matches the one you stored before redirecting
    const storedState = sessionStorage.getItem("oauthState");
    if (state !== storedState) {
      throw new Error("State mismatch");
    }

    if (code) {
      console.log("code exists");
      try {
        // Exchange the code for an access token
        const tokenData = await twitterAuthUtils.exchangeCodeForToken(
          code,
          codeVerifier
        );
        const accessToken = tokenData.access_token;

        // Use the access token to get the followers and following lists
        const followersList = await twitterAuthUtils.getFollowersList(
          accessToken,
          "771497465019191296"
        ); // Replace 'user-id' with the actual user ID
        const followingList = await twitterAuthUtils.getFollowingList(
          accessToken,
          "771497465019191296"
        ); // Replace 'user-id' with the actual user ID

        // Do something with the followers and following lists
        console.log("Followers:", followersList);
        console.log("Following:", followingList);
      } catch (error) {
        console.error("Error during Twitter authorization:", error);
      }
    } else {
      console.log("no code");
    }
  }
  return (
    <div className="flex w-full h-full items-center justify-center p-12">
      <div className="flex flex-col w-2/3 h-2/3 bg-insetPrimary p-4">
        <div className="flex flex-col p-2 items-center">
          <p className="text-3xl font-bold mb-2">Bring your Network</p>
          <div className="flex  flex-col w-1/2 border border-1 border-grayscale-300/30 rounded-xl p-3 bg-white/30 shadow-[inset_0_1px_1px_rgba(255,255,255,0.9)]">
            <div className="flex w-full px-4 py-2 justify-between">
              <p className="text-2xl font-semibold text-grayscale-500 w-full">
                Farcaster
              </p>
              <div
                className={`w-full border border-[2px] border-ourPurple-500/50 rounded-[16px] group transition-all duration-20 border-ourPurple-800/40 font-regular inter active:scale-95 overflow-visible	 transition transform spring duration-400 `}
              >
                <button
                  onClick={handleFarcasterPress}
                  className={`flex gap-2 w-full items-center justify-center bg-gradient-to-t from-ourPurple-800 to-ourPurple-700 via-ourPurple-800/90 py-2 px-6 text-white font-bold rounded-[14px] duration-10 border border-ourPurple-900 border-1 inter `}
                >
                  <img src={farcasterIcon} className="h-6 w-6" />
                  <p className="font-bold text-white">Connect</p>
                </button>
              </div>
            </div>
            <hr className="border-grayscale-600/20" />
            <div className="flex w-full px-4 py-2 justify-between">
              <p className="text-2xl font-semibold text-grayscale-500 w-full">
                Twitter
              </p>
              <div
                className={`w-full border border-[2px] border-grayscale-500/50 rounded-[16px] group transition-all duration-20 border-grayscale-800/40 font-regular inter active:scale-95 overflow-visible	 transition transform spring duration-400 `}
              >
                <button
                  onClick={handleTwitterPress}
                  className={`flex gap-2 w-full items-center justify-center bg-gradient-to-t from-grayscale-800 to-grayscale-700 via-grayscale-800/90 py-2 px-6 text-white font-bold rounded-[14px] duration-10 border border-grayscale-900 border-1 inter `}
                >
                  <img src={twitterLogo} className="h-4 w-4" />
                  <p className="font-bold text-white">Connect</p>
                </button>
              </div>
            </div>
            <BaseIconButton IconComponent={UserCheckIcon} title={"hi"} />
            <IconButtonForToolbar
              IconComponent={UserCheckIcon}
              isActive={true}
            />
          </div>
          <p>0x PPL</p>
        </div>
      </div>
    </div>
  );
}
function BaseIconButton({ IconComponent, title }) {
  const baseStyles = `flex font-bold text-sm w-full p-2 rounded-[10px] border border-1 border-white/0 stroke-ourPurple-900 bg-[#F4F4F4FC]  items-center justify-center transition duration-300 opacity`;
  const hoverStyles = `hover:bg-grayscale-400/10 hover:shadow-[inset_0_1px_1px_rgba(255,255,255,0.9)] hover:border-[#6969690F]`;
  const activeStyles = `active:shadow-none`;
  return (
    <div className="relative">
      <button
        title={title}
        style={{
          cursor: "pointer",
          position: "relative",
          zIndex: 1,
        }}
        className={`${baseStyles} ${hoverStyles} ${activeStyles}`}
      >
        <IconComponent size="20" color="" />
      </button>
    </div>
  );
}
export function IconButtonForToolbar({ IconComponent, isActive, onClick }) {
  // Base styles for the neumorphic effect and layout
  const baseStyles =
    "h-full w-min flex items-center justify-center p-2 rounded-[8px] transition duration-300";

  // Neumorphic styling for the inactive state with a purple theme
  const inActiveStyles = `
       
      stroke-ourPurple-700`;

  // Neumorphic styling for the active state, slightly indented look with a darker theme
  const activeStyles = [
    " font-bold text-sm ",
    " w-full p-2",
    "rounded-[10px] ",
    "active:shadow-none",
    "bg-ourPurple-600 shadow-[inset_0_1px_1px_rgba(255,255,255,0.5)] border border-ourPurple-800/30 stroke-white",
  ].join(" ");

  // Combining styles based on the isActive prop
  const fullStyle = isActive
    ? `${baseStyles} ${activeStyles}`
    : `${baseStyles} ${inActiveStyles}`;

  return (
    <button className={fullStyle} onClick={onClick}>
      <IconComponent className="stroke-current" />{" "}
    </button>
  );
}
