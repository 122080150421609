import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import * as OrbitEventHandlers from "../../pages/inApp/yourOrbit/utilities/orbitEventHandlers";
import * as OrbitCanvasUtilities from "../../pages/inApp/yourOrbit/utilities/orbitCanvasUtils";
import { getUserNetwork } from "../../utils/helpers/ourAPIHelpers";
import OrbitNetworkView from "../../pages/inApp/yourOrbit/components/orbitNetworkView";

const DIV_SIZE = 8000;
const RADIUS = 250;
const CENTER = { x: DIV_SIZE / 2, y: DIV_SIZE / 2 };
const scaleMin = 0.3;
const scaleMax = 2;
const scaleIncrement = 0.2;
const DRAG_THRESHOLD = 5;

function SimpleOrbitContainer({ userId, displayedUser }) {
  const [loading, setLoading] = useState(true);
  const [userNetwork, setNetwork] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: -3477, y: -3770 });
  const [scale, setScale] = useState(0.8);
  const [isClick, setIsClick] = useState(true);
  const navigate = useNavigate();
  const divRef = useRef(null);
  const onWheel = (e) =>
    OrbitEventHandlers.handleWheel(
      e,
      scale,
      setScale,
      scaleMax,
      scaleMin,
      scaleIncrement
    );

  const onMouseDown = (e) => {
    OrbitEventHandlers.handleMouseDown(
      e,
      offset,
      setIsDragging,
      setIsClick,
      setStartPos
    );
  };
  const onMouseMove = (e) => {
    OrbitEventHandlers.handleMouseMove(
      e,
      isDragging,
      startPos,
      setIsClick,
      setOffset
    );
  };
  const onMouseUp = () => {
    OrbitEventHandlers.handleMouseUp(setIsDragging, setIsClick);
  };

  useEffect(() => {
    // Add wheel event listener
    const div = divRef.current;
    if (div) {
      div.addEventListener("wheel", onWheel);
    }
    return () => {
      if (div) {
        div.removeEventListener("wheel", onWheel);
      }
    };
  }, [onWheel]);

  const initialOffset = useCallback(
    () => OrbitEventHandlers.calculateInitialOffsetForSimplified(DIV_SIZE),
    [DIV_SIZE]
  );

  useEffect(() => {
    // Set initial offset and handle window resize
    const initialOffset =
      OrbitEventHandlers.calculateInitialOffsetForSimplified(DIV_SIZE);
    setOffset(initialOffset);
    console.log(initialOffset);

    const handleResize = () => setOffset(initialOffset);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch and process network data for the user with userId
    if (userId) {
      OrbitCanvasUtilities.getOtherUserNetwork({
        userId,
        getUserNetwork, // This should be a function that fetches network data for a given userId
        setLoading,
        RADIUS,
        CENTER,
        setNetwork,
      });
    } else {
      console.log("no user id");
    }
  }, [userId]);
  return (
    <div
      className="flex flex-col bg-ourBlue-400 text-ourBlue-800 shadow-lg  font-sans h-full w-full rounded-3xl p-6"
      ref={divRef}
      style={{
        transition: "width 0.5s",
        position: "relative",
        overflow: "hidden",
      }}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseUp}
      onMouseUp={onMouseUp}
    >
      {" "}
      <div
        style={{
          width: "8000px",
          height: "8000px",
          position: "absolute",
          cursor: isDragging ? "grabbing" : "grab",
          transform: `translate(${offset.x}px, ${offset.y}px) scale(${scale})`,
          transition: isDragging ? "none" : "transform 0.2s",
        }}
        onMouseDown={onMouseDown}
        className="bg-ourBlue-400 "
      >
        <OrbitNetworkView
          network={{ data: userNetwork, loading }}
          currentUser={{ id: userId }} // Pass the userId as currentUser
          CENTER={CENTER}
          isCurrentUserView={false}
          onOrbClick={() => console.log("CLlik")}
          displayedUser={{
            pfp_url: displayedUser.pfp_url,
            user_name: displayedUser.user_name,
            id: displayedUser.id,
          }}
        />
      </div>
    </div>
  );
}

export default SimpleOrbitContainer;
{
  /*  */
}
