import { useEffect, useState } from "react";
import etherIcon from "../../../../../assets/etherIcon.png";
import { useWallDisplayContext } from "../../../../../utils/contexts/wallDisplayContext";
import { getWallOwners } from "../../../../../utils/helpers/ourAPIHelpers";

export default function CellStatsContainer({ currentUser, selectedCell }) {
  const { displayedUser } = useWallDisplayContext();
  return (
    <div className="flex flex-col gap-1 rounded-[14px] px-4 py-2 bg-gradient-to-b from-white to-stone-200/20 shadow-[0_1px_3px_rgba(0,0,0,0.2)]">
      <div className="flex justify-between  ">
        <span className="flex flex-col -space-y-2 items-center">
          <span className="flex items-center gap-1">
            <img src={etherIcon} className="h-4 w-4" alt="cardano Icon" />
            <p className="text-[22px] font-semibold text-grayscale-800">
              {selectedCell.current_price}
            </p>
          </span>
          <p className="text-[14px] text-grayscale-500">Current Price</p>
        </span>
        <span className="flex flex-col -space-y-2 items-center">
          <span className="flex items-center gap-1">
            <img src={etherIcon} className="h-4 w-4" alt="cardano Icon" />
            <p className="text-[22px] font-semibold text-grayscale-800">
              {" "}
              {displayedUser.friend_market_cap}
            </p>
          </span>
          <p className="text-[14px] text-grayscale-500">Wall Mkt Cap</p>
        </span>
        <span className="flex flex-col -space-y-2 items-center">
          <span className="flex items-center gap-1">
            <p className="text-[22px] font-semibold text-grayscale-800">
              332k{" "}
            </p>
          </span>
          <p className="text-[14px] text-grayscale-500">Wall Views</p>
        </span>
      </div>
    </div>
  );
}
export function WallOwnersRow({ currentUser, selectedCell }) {
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchUsers = async () => {
      const fetchedUsers = await getWallOwners(selectedCell.wall_id);
      setUsers(fetchedUsers);
    };
    fetchUsers();
  }, [selectedCell.wall_id]);
  return (
    <div className="flex flex-col gap-1 items-start rounded-[14px] px-4 py-1.5 bg-gradient-to-b from-white to-stone-200/20 shadow-[0_1px_3px_rgba(0,0,0,0.2)]  max-h-18">
      <p className="text-sm text-grayscale-400/70">Wall Owners</p>
      <div className="flex -space-x-1">
        {users.map((user) => (
          <img
            key={user.id}
            src={user.pfp_url}
            className="h-8 w-8 rounded-full opacity-60 hover:opacity-100 border border-2 border-ourPurple-500 hover:border-ourPurple-600 transition duration-500 opacity hover:z-20"
          />
        ))}
      </div>
    </div>
  );
}
