import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthenticationClientContextTwo } from "../pages/testing/auth2Flow/onboardingContextClientTwo";
import {
  Icon,
  ChevronRight,
  ChevronLeft,
  TrendingUp,
  Orbit,
  Store,
  Globe,
  Settings,
  BrickWall,
  Cuboid,
  Bell,
  Crown,
  Microscope,
  User,
  Search,
} from "lucide-react";
import { PrimaryButton } from "./elements/primaryButton";
import { useWallet } from "@solana/wallet-adapter-react";
import flower from "../assets/icons/flower.svg";
import kido from "../assets/icons/kido.svg";
function NavBar({
  isExpanded,
  setIsExpanded,
  setShowSettingsPopover,
  showNotificationsPopover,
  setShowNotificationsPopover,
}) {
  const location = useLocation();
  const { currentUser, isAuthenticated } = useAuthenticationClientContextTwo();
  const [notifications, setNotifications] = useState(true);
  const toggleSize = () => {
    // console.log(window.innerWidth, window.innerHeight);
    setIsExpanded(!isExpanded);
  };
  const { disconnect } = useWallet();
  const handleDisconnect = () => {
    disconnect();
  };

  return (
    <div
      className={`flex flex-col bg-white  font-sans h-full rounded-2xl shadow-[0_1px_3px_rgba(0,0,0,0.1)] transition-all duration-500 overflow-cliped ease ${
        isExpanded ? "w-60" : "w-20"
      }`}
    >
      <div className="flex flex-col items-center p-4">
        <div className="my-2">
          {isExpanded ? (
            <img src={kido} alt="kido" className="w-36" />
          ) : (
            <img src={flower} alt="kido" className="h-12 w-12" />
          )}
        </div>
        <div className="flex flex-col space-between w-full text-ourTeal-900">
          <NavBarRow
            rowItem={"Your Orbit"}
            icon={<Orbit />}
            isExpanded={isExpanded}
            path="/orbit"
          />
          <NavBarRow
            rowItem={"Explore"}
            icon={<Search strokeWidth={3} />}
            isExpanded={isExpanded}
            path="/explore"
          />
          <NavBarRow
            rowItem={"Your Wall"}
            icon={<BrickWall />}
            isExpanded={isExpanded}
            path="/wall"
          />
          <NavBarRow
            rowItem={"Your Bricks"}
            icon={<User />}
            isExpanded={isExpanded}
            path="/yourbricks"
          />
        </div>
        <div
          className={`absolute top-16 rounded-full w-8 h-8 flex items-center justify-center bg-white  border border-1 transition-all duration-500 ${
            isExpanded ? "left-56" : "left-[4rem]"
          }`}
        >
          <button
            onClick={toggleSize}
            className="rounded-full flex justify-center items-center"
            style={{ transition: "transform 0.3s" }}
          >
            {isExpanded ? (
              <ChevronLeft className="stroke-ourTeal-900" />
            ) : (
              <ChevronRight className="stroke-ourTeal-900" />
            )}
          </button>
        </div>

        <div className="absolute bottom-4 p-2 w-max space-y-2 ">
          <button
            className="flex justify-center items-center p-2 gap-2 border border-1 border-white transition-all duration-500 hover:bg-ourTeal-400/80 hover:border hover:border-ourTeal-600 group rounded-lg"
            onClick={() => setShowNotificationsPopover(true)}
          >
            {notifications && (
              <div className=" absolute bottom-[67px] left-[30px] w-3 h-3 bg-ourTeal-900 rounded-full  group-hover:scale-110"></div>
            )}

            <Bell className="stroke-gray-500 fill-gray-200 group-hover:stroke-ourTeal-900 group-hover:fill-ourTeal-500 group-hover:rotate-[6deg] " />
            {isExpanded && (
              <span className="flex items-center gap-2">
                <p className="text-gray-500 font-semibold group-hover:text-ourTeal-900">
                  Notifications
                </p>
              </span>
            )}
          </button>
          <button
            className="flex justify-center items-center p-2 gap-2 border border-1 border-white transition-all duration-500 hover:bg-ourTeal-400/80 hover:border hover:border-ourTeal-600 group rounded-lg"
            onMouseEnter={() => setShowSettingsPopover(true)}
            onMouseLeave={() => setShowSettingsPopover(false)}
          >
            <Settings className="stroke-gray-500 fill-gray-200 group-hover:stroke-ourTeal-900 group-hover:fill-ourTeal-500 group-hover:rotate-[32deg] transition duration-400 " />
            {isExpanded && (
              <span className="flex items-center gap-2">
                <p className="text-gray-500 font-semibold group-hover:text-ourTeal-900">
                  Settings
                </p>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default NavBar;

function NavBarRow({ rowItem, icon, isExpanded, path }) {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = path === location.pathname;

  // Define the base style for the row
  const baseStyle =
    "p-2 my-1 w-full font-semibold rounded-lg border border-white/10 ";
  // Define the active style when the row is active
  const activeStyle = "bg-ourTeal-500 border border-ourTeal-700/60";
  // Define the hover style for when the row is hovered over
  const hoverStyle =
    "hover:bg-ourTeal-400/80 hover:border hover:border-ourTeal-600";
  // Define the normal style for when the row is not active
  const normalStyle = "text-gray-500";
  // Combine the styles, using activeStyle if the row is active, otherwise use normalStyle
  const combinedStyle = `${baseStyle} ${isActive ? activeStyle : normalStyle}`;

  // Modify the icon's class based on the isActive state
  const iconStyle = `stroke-current ${
    isActive ? "text-ourTeal-900 fill-ourTeal-600" : "text-gray-400"
  }`;

  const handleNavigation = () => {
    navigate(path);
  };

  return (
    <button
      onClick={handleNavigation}
      className={`${combinedStyle} ${isActive ? "" : hoverStyle}`}
    >
      <div
        className={`flex items-center ${isExpanded ? "" : "justify-center"}`}
      >
        {/* Apply the iconStyle to the icon */}
        {React.cloneElement(icon, { className: iconStyle })}
        {isExpanded && (
          <p
            className={`ml-2 ${
              isActive ? "text-ourTeal-900" : "text-gray-500"
            }`}
          >
            {rowItem}
          </p>
        )}
      </div>
    </button>
  );
}

function UserDisplaySection({ currentUser, isExpanded }) {
  return (
    <div className="flex items-center justify-center w-min gap-1 mb-4 bg-ourTeal-400 rounded-xl ">
      <img
        src={currentUser.pfp_url}
        alt="userPfp"
        className="h-10 w-10 rounded-full "
      />
      {isExpanded && (
        <div className="flex flex-col items-start -space-y-1 w-48">
          <p className="truncate max-w-42 font-bold text-gray-700 text-lg">
            {currentUser.display_name}
          </p>
          <p className="font-regular text-md text-gray-400">
            {`${currentUser.user_address.substring(
              0,
              4
            )}...${currentUser.user_address.substring(
              currentUser.user_address.length - 6
            )}`}
          </p>
        </div>
      )}
    </div>
  );
}
