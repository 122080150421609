import React, { useState, useEffect } from "react";
import SingleCellForBrickDisplay from "../../../yourBricks/singleCellForBrickDisplay";
import CellStatsContainer, { WallOwnersRow } from "./CellStatsContainer";
import { CellInfoContainer } from "../../../../../components/modularWall/cellModalInner";
import { useWallDisplayContext } from "../../../../../utils/contexts/wallDisplayContext";

import { CellIconButtonRow } from "../../../../../components/modularWall/cellModalInner";
import placeholderImage from "../../../../../assets/icons/kidoUFO.png";
import NeumorphicButton from "../../../../../components/elements/neumorphicButton";
import { useNavigate } from "react-router-dom";
import ufoWifFriends from "../../../../../assets/icons/ufoWifFriends.png";
import { motion } from "framer-motion";
import "./sidebarStyling.css";
import FloatingUFOWifFriends from "../../../../../components/placeholders/floatingUFOContainer";
/**
 * UnmintedCellSidebarContainer handles the display and interactions with the sidebar
 * for an unminted cell within the user's orbit.
 *
 * @param {Object} props - Component props
 * @param {Object} props.currentUser - The current user object
 */
export default function UnmintedCellSidebarContainer({ currentUser }) {
  const {
    selectedCell,
    setSelectedCell,
    displayedUser,
    setSelectedDisplayOption,
    setCellShoppingCart,
    cellShoppingCart,
    setShowMarketHelper,
    addToShoppingCart,
  } = useWallDisplayContext();
  const navigate = useNavigate();
  // State to manage the button text based on the cell status and ownership
  const [buttonText, setButtonText] = useState("Purchase Cell");

  // State to manage the dynamic sizing of the cell display
  const [cellSize, setCellSize] = useState(428);
  // State to handle which placeholder if the cell isn't decorates
  // Action to be performed when the button is clicked
  const buttonAction = () => {
    if (buttonText === "Edit Cell") {
      console.log("uihg");
      navigate(`/${displayedUser.user_name}/${selectedCell.cell_id}/edit`);
    } else if (buttonText === "Submit Offer") {
      console.log("I haven't set this up yut sorry ");
    } else {
      console.log("fdsdfafds");
      setSelectedDisplayOption("checkout");
      addToShoppingCart(selectedCell);
      setShowMarketHelper(true);
    }
  };

  // Effect to update the cell size based on the window width
  useEffect(() => {
    const newSize = window.innerWidth > 1401 ? window.innerWidth * 0.3 : 428;
    setCellSize(Math.min(newSize, 500));
  }, [window.innerWidth]);

  // Effect to update the button text based on the selected cell's status and ownership
  useEffect(() => {
    if (currentUser.id === selectedCell.owner_id) {
      setButtonText("Edit Cell");
    } else if (!selectedCell.is_enabled) {
      setButtonText("Purchase Cell");
    } else {
      setButtonText("Submit Offer");
    }
  }, [currentUser.id, selectedCell]);

  // Determine the content to display based on cell status and ownership
  const renderCellContent = () => {
    const isOwnerWithoutLatestData =
      currentUser.id === selectedCell.owner_id &&
      !selectedCell.latest_version_data;
    const isCellDisabled = !selectedCell.is_enabled;
    const shouldShowUFO = isOwnerWithoutLatestData || isCellDisabled;

    if (shouldShowUFO) {
      return <FloatingUFOWifFriends cellSize={cellSize} />;
    } else {
      return (
        <SingleCellForBrickDisplay
          key={selectedCell.cell_id}
          cellData={selectedCell.latest_version_data}
          size={cellSize}
          SCALE_FACTOR={cellSize / 800}
          setCellData={setSelectedCell}
        />
      );
    }
  };
  return (
    <div className="flex flex-col w-full h-full justify-between bg-white shadow-md  p-2 inter-font">
      <span
        style={{ maxHeight: `${cellSize - 50}px` }}
        className="bg-gradient-to-b from-white to-stone-200/10 shadow-[0_1px_3px_rgba(0,0,0,0.2)] rounded-[14px] overflow-hidden"
      >
        {renderCellContent()}
      </span>
      {!selectedCell.is_enabled && (
        <>
          <CellStatsContainer
            currentUser={currentUser}
            selectedCell={selectedCell}
          />
        </>
      )}
      <WallOwnersRow currentUser={currentUser} selectedCell={selectedCell} />
      <CellInfoContainer currentUser={currentUser} cell={selectedCell} />
      <span className="flex flex-col w-full gap-2">
        <div className="group px-[3px] py-[6px] rounded-[12px] bg-gradient-to-b from-white to-stone-200/40 shadow-[0_1px_3px_rgba(0,0,0,0.2)]  w-full">
          <CellIconButtonRow cell={selectedCell} />
        </div>
        {/* <NeumorphicButton text={buttonText} action={buttonAction} /> */}
        <button
          className={[
            " font-semibold text-md",
            " py-[12px] min-w-[128px] w-full",
            "rounded-[10px] ",
            "active:shadow-none",
            "bg-ourTeal-600 shadow-[inset_0_1px_1px_rgba(255,255,255,0.4)] border border-ourTeal-800 text-ourTeal-900",
          ].join(" ")}
          onClick={buttonAction}
          // disabled={disabled}
          // disabled
          // ? "opacity-50 cursor-not-allowed bg-ourGold-900 shadow-[inset_0_1px_1px_rgba(255,255,255,0.4)] border border-ourGold-800 text-grayscale-900"
          // :
        >
          {buttonText}
        </button>
      </span>
    </div>
  );
}
