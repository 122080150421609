import useSolanaPrice from "../../../../utils/hooks/useSolPrice";
import etherIcon from "../../../../assets/etherIcon.png";
import { Twitter } from "lucide-react";
import { useWallet } from "@solana/wallet-adapter-react";
import { DiscordIcon } from "../../../../assets/icons/discord";
import "../marketStyles.css";

export default function MarketFooter() {
  const { connected, connecting, disconnecting } = useWallet();

  let statusText = "Disconnected";
  let statusColorClass = "bg-red-500"; // Red for disconnected

  if (connecting || disconnecting) {
    statusText = "Connecting";
    statusColorClass = "bg-yellow-500"; // Yellow for connecting/disconnecting
  } else if (connected) {
    statusText = "Connected";
    statusColorClass = "bg-green-500"; // Green for connected
  }

  return (
    <div className="flex w-full bg-white p-2 rounded-lg shadow-md space-x-2 px-4 font-regular justify-between footer-style text-grayscale-600">
      <span className="flex gap-2 items-center justify-center">
        <span className="relative flex h-2 w-2 items-center justify-center">
          <span
            className={`animate-ping absolute inline-flex h-full w-full rounded-full ${statusColorClass} opacity-50`}
          ></span>
          <span
            className={`relative inline-flex rounded-full h-2 w-2 ${statusColorClass}`}
          ></span>
        </span>
        <p className="text-grayscale-600 text-sm">{statusText}</p>
      </span>

      <div className="flex items-center gap-1">
        <img src={etherIcon} alt="sol" className="h-3 w-3" />
        <p className="text-grayscale-600 text-sm">$2900.21</p>
      </div>
      <span className="flex items-center gap-1">
        <p className="text-sm text-grayscale-400">1D VOL:</p>
        <p className="text-sm font-semibold text-grayscale-400"> $2,232,234</p>
      </span>
      <span className="flex gap-3">
        <Twitter className="text-ourBlue-600 h-6 w-6 hover:scale-110 transition duration-300 ease hover:text-ourBlue-900 hover:fill-ourBlue-900" />
        <a
          href="https://discord.gg/yjy3J25ZjR"
          target="_blank"
          rel="noopener noreferrer"
        >
          <DiscordIcon
            className={
              "text-ourPurple-600 h-6 w-6 hover:scale-110 transition duration-300 ease hover:text-ourPurple-900"
            }
          />
        </a>
      </span>
    </div>
  );
}

const ToggleSwitch = ({ isOn, onToggle }) => {
  return (
    <div
      className={`w-10 h-6 flex items-center bg-gray-300 rounded-lg p-1 cursor-pointer ${
        isOn && "bg-blue-500"
      }`}
      onClick={onToggle}
      style={{
        boxShadow: "inset 0 0 10px #D3D3D3",
        transition: "width 0.3s",
        position: "relative", // Position relative for absolute child elements
        boxShadow: "drop 0 0 10px #D3D3D3",
      }}
    >
      {/* Switch */}
      <div
        className={`bg-white w-4 h-4 rounded-md shadow-md transform ${
          isOn ? "translate-x-5" : "translate-x-0"
        } transition-transform`}
      ></div>
    </div>
  );
};
