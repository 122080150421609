import React from "react";
import { SingleUserOrb } from "./singleUserOrb";
import "../orbitStyle.css";
import { UserWallModal } from "./orbClickedModal";
import Modal from "react-modal";

const OrbitNetworkView = ({
  network,
  onOrbClick,
  currentUser,
  CENTER,
  isCurrentUserView,
  popoverVisible,
  popoverPosition,
  onClosePopover,
  handleViewOrbit,
  handleViewWall,
  displayedUser,
}) => {
  // console.log(network);
  if (!network.data) return <div>...loading </div>; // or you can return a loader or any placeholder you wish
  return (
    <>
      {!network.loading && (
        <SingleUserOrb
          user={isCurrentUserView ? currentUser : displayedUser}
          position={CENTER}
          onClick={() => console.log(network.user)}
          isCurrentUser={isCurrentUserView}
        />
      )}
      {!network.loading &&
        network.data.map((user, index) => (
          <SingleUserOrb
            key={index}
            user={user}
            position={user.position}
            isSelectedUser={
              displayedUser && user.friend_id === displayedUser.friend_id
            }
            onClick={() => onOrbClick(user, user.position)}
          />
        ))}
      {popoverVisible && (
        <Modal
          isOpen={popoverVisible}
          onRequestClose={onClosePopover}
          contentLabel="Search Modal"
          className={
            "justify-center items-center rounded-[16px] orbit-wall-modal-center"
          }
          overlayClassName="orbit-wall-modal-overlay-style"
          appElement={document.getElementById("root") || undefined} // Add this line
        >
          <UserWallModal
            displayedUser={displayedUser}
            onClose={onClosePopover}
            hideModal={onClosePopover}
          />
        </Modal>
      )}
    </>
  );
};

export default OrbitNetworkView;
