export const handleOrbClick = (
  user,
  position,
  setPopoverVisible,
  setSelectedUser,
  setPopoverPosition,
  setOffset,
  setScale,
  setOffsetPreClick,
  offset
) => {
  const newScale = 1.2;
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  setOffsetPreClick(offset);
  console.log(user);
  const orbPositionScaled = {
    x: position.x * newScale,
    y: position.y * newScale,
  };

  const newOffset = {
    x: -orbPositionScaled.x + viewportWidth - 200,
    y: -orbPositionScaled.y + viewportHeight,
  };
  window.history.pushState(
    {},
    "",
    `${window.location.pathname}?userAddress=${user.user_address}`
  );
  setPopoverVisible(true);
  setSelectedUser(user);
  setPopoverPosition(position);
  setOffset(newOffset);
  setScale(newScale);
};

export const handleClosePopover = (
  setPopoverVisible,
  setSelectedUser,
  setScale,
  setOffset,
  offsetPreClick
) => {
  setPopoverVisible(false);
  setSelectedUser(null);
  setScale(0.8);
  setOffset(offsetPreClick);
};
export const handleViewOrbit = (
  user,
  setLoading,
  getNetwork,
  setOtherUserNetwork
) => {
  console.log("View orbit for", user);
};

export const handleViewWall = (user) => {
  console.log("Viewing wall for", user);
  console.log("Viewing wall for", user);
};
