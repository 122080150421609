export async function checkRefCode({ refCode }) {
  try {
    const response = await fetch(
      `https://www.api.wallz.social/verify-ref-code?refCode=${encodeURIComponent(
        refCode
      )}`
    );
    const data = await response.json();

    if (response.ok) {
      console.log(data);
      return data;
    } else {
      // Error verifying referral code, throw an error with the message
      throw new Error(data.message);
    }
  } catch (error) {
    // Network error, throw the error
    throw error;
  }
}

export async function getUsersByTwitterId(twitterIds) {
  try {
    const response = await fetch(
      `https://www.api.wallz.social/get-users-by-twitter-id`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ twitterIds }),
      }
    );

    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      // Handle response errors
      throw new Error(data.message);
    }
  } catch (error) {
    // Handle network errors
    throw error;
  }
}
export async function createUser(user, goToNextStep) {
  try {
    const response = await fetch("https://www.api.wallz.social/create-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    await response.json();
    goToNextStep(); // Proceed to the next step after successful account creation
  } catch (error) {
    console.error("Failed to create user:", error);
  }
}

export async function getUser(userId) {
  console.log("Fetching data for user ID:", userId);

  try {
    const response = await fetch(
      `https://www.api.wallz.social/get-user?id=${encodeURIComponent(userId)}`
    );
    const data = await response.json();

    if (response.ok) {
      console.log(data);
      return data;
    } else {
      // Error getting the user
      throw new Error(data.message);
    }
  } catch (error) {
    // Network error, throw the error
    throw error;
  }
}

export async function updateUserData(userId, updates) {
  try {
    const response = await fetch("/update-user", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, updates }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    console.log("User updated successfully");
  } catch (error) {
    console.error("Failed to update user:", error);
  }
}
