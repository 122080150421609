import { Navigate, useRoutes } from "react-router-dom";
import { authRoutes } from "./routegroups/authenticationRoutes";
import { inAppRoutes } from "./routegroups/inAppRoutes";
import LandingPage from "../pages/landingPage/landingPage";
import TestingIndex from "../pages/testing/testingIndex";
import UserProfile from "../pages/common/userProfile";
import AuthenticationPage from "../pages/authentication/authenticationPage";
import PublicWall from "../pages/common/publicWall";
import OtherUserWall from "../pages/otherUserWall";
import InAppLayout from "../components/layoutProvider/layoutWIthNavBar";
import TestingTwo from "../pages/testing/testing2/testingTwoIndex";
import GeoBlockedPage from "../pages/geoBlockedPage";
import AddNetwork from "../pages/authentication/newAddFriends";
import FrameIndex from "../components/frames/frameIndex";

export default function Router({ isAuthenticated }) {
  // Define the common routes
  const commonRoutes = [
    { path: "/", element: <LandingPage />, index: true },
    { path: "/:username/wall/public", element: <PublicWall /> },
    { path: "/testingTwo", element: <AddNetwork /> },
    { path: "/geoblocked", element: <GeoBlockedPage /> },
    { path: "/frame", element: <FrameIndex /> },
    { path: "/landing", element: <LandingPage /> },
  ];

  // Define the public routes for unauthenticated users
  const publicRoutes = [
    ...authRoutes,
    { path: "*", element: <Navigate to="/auth" replace /> },
  ];

  // Combine the routes based on the authentication status
  const routes = useRoutes([
    ...commonRoutes,
    ...inAppRoutes, // This now includes protected routes with the ProtectedRoute component
    ...authRoutes, // Assuming these are additional public routes for authentication
  ]);

  return routes;
}
