const DRAG_THRESHOLD = 5;

export const handleWheel = (
  e,
  scale,
  setScale,
  scaleMax,
  scaleMin,
  scaleIncrement
) => {
  if (e.altKey) {
    e.preventDefault();
    let newScale = scale;
    if (e.deltaY < 0 && scale < scaleMax) {
      newScale = Math.min(scale + scaleIncrement, scaleMax);
    } else if (e.deltaY > 0 && scale > scaleMin) {
      newScale = Math.max(scale - scaleIncrement, scaleMin);
    }
    setScale(newScale);
  }
};

export const calculateInitialOffset = (DIV_SIZE) => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  return {
    x: (viewportWidth - DIV_SIZE) / 2 - 100,
    y: (viewportHeight - DIV_SIZE) / 2 - 24,
  };
};
export const calculateInitialOffsetForSimplified = (DIV_SIZE) => {
  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;
  return {
    x: (viewportWidth - DIV_SIZE) / 2 - 200,
    y: (viewportHeight - DIV_SIZE) / 2 - 200,
  };
};
export const handleMouseDown = (
  e,
  offset,
  setIsDragging,
  setIsClick,
  setStartPos
) => {
  setIsDragging(true);
  setIsClick(true);
  setStartPos({ x: e.clientX - offset.x, y: e.clientY - offset.y });
};

export const handleMouseMove = (
  e,
  isDragging,
  startPos,
  setIsClick,
  setOffset
) => {
  if (isDragging) {
    const x = e.clientX - startPos.x;
    const y = e.clientY - startPos.y;
    setIsClick(!(Math.abs(x) > DRAG_THRESHOLD || Math.abs(y) > DRAG_THRESHOLD));
    setOffset({ x, y });
  }
};

export const handleMouseUp = (setIsDragging, setIsClick) => {
  setIsDragging(false);
  setIsClick(true);
};

export const navigateToUserWall = (userName, isDragging, navigate) => {
  if (!isDragging) {
    navigate(`/${userName}/wall`);
  }
};
