export default function OurListItemWithAvatar({ user, subheader, header }) {
  return (
    <div className="flex justify-between items-center hover:bg-ourBlue-400 p-2 rounded-xl transition-all ease-in duration-100">
      <span className="flex gap-1">
        <img
          src={`${user.pfp}`}
          alt="hunterdiamond"
          className="h-10 w-10 rounded-full"
        />
        <span className="flex flex-col -space-y-1">
          <p className="font-bold">{user.username}</p>
          <p className="font-regular text-sm">{subheader}</p>
        </span>
      </span>

      <p>{header}</p>
    </div>
  );
}
