import { useState, useEffect, useRef, useCallback } from "react";
import { useCanvasContext } from "./canvasContext";
import _ from "lodash";
import { fabric } from "../../lib/custom.fabric.build";
import { v4 as uuidv4 } from "uuid"; // Import the UUID library
import "./canvasIndex.css";
import FontFaceObserver from "fontfaceobserver";

const FabricCanvas = () => {
  const {
    brushColor,
    brushSize,
    addImage,
    textValue,
    saveCanvasState,
    handleAddImage,
    imageFiles,
    isCanvasDisabled,
    selectedFont,
    fontSize,
    setSelectedMode,
    selectedMode,
    sendObjectBackward,
    sendObjectForward,
    canvasRef,
    canvas,
    setCanvas,
    handleEraser,
  } = useCanvasContext();
  const [fontLoaded, setFontLoaded] = useState(false);

  // Preload and use fonts

  function loadFont() {
    const fontObserver = new FontFaceObserver(selectedFont);
    fontObserver
      .load()
      .then(() => {
        setFontLoaded(true);
      })
      .catch((e) => {
        console.error("Error loading font");
      });
  }
  useEffect(() => {
    if (canvas) {
      canvas.selection = !isCanvasDisabled; // Disable selection
      canvas.forEachObject((obj) => {
        obj.selectable = !isCanvasDisabled; // Make objects non-selectable
        obj.evented = !isCanvasDisabled; // Disable events on objects
        obj.set({ erasable: true });
      });

      if (isCanvasDisabled) {
        canvas.discardActiveObject(); // Deselect any selected object
        canvas.requestRenderAll();
      }
    }
  }, [canvas, isCanvasDisabled]);
  useEffect(() => {
    // Initialize canvas
    loadFont();
    const newCanvas = new fabric.Canvas(canvasRef.current, {
      // GABBAGOOL
      height: 800,
      width: 800,
      backgroundColor: "rgba(217, 232, 249, 0.5)",
    });
    setCanvas(newCanvas);

    return () => {
      newCanvas.dispose(); // Cleanup on unmount
    };
  }, []);
  // // Preload and use fonts
  useEffect(() => {
    loadFont();
  }, [selectedFont]);

  useEffect(() => {
    if (isCanvasDisabled) {
      return; // Do not add or modify objects
    }

    if (canvas && selectedMode === "ENTERING_TEXT" && fontLoaded) {
      const textbox = new fabric.Textbox("Type here", {
        left: canvas.width / 2 - 75,
        top: canvas.height / 2 - 50,
        width: 150,
        fontFamily: selectedFont,
        fontSize: fontSize,
        fill: brushColor,
        editable: true,
      });
      canvas.add(textbox).setActiveObject(textbox);
      textbox.on("editing:exited", () => {
        canvas.off("text:editing:exited");
        setSelectedMode(null);
      });
    }
  }, [canvas, selectedMode, fontSize, fontLoaded]);
  useEffect(() => {
    if (isCanvasDisabled) {
      return; // Do not add or modify objects
    }

    if (canvas && selectedFont) {
      const activeObject = canvas.getActiveObject();

      // Check if the active object is a textbox
      if (activeObject && activeObject.type === "textbox") {
        activeObject.set("fontFamily", selectedFont);
        activeObject.set("fill", brushColor);
        canvas.requestRenderAll(); // Re-render the canvas to apply the new font
      }
    }
  }, [canvas, selectedFont, brushColor]); // Depend on canvas and selectedFont

  const deleteSelectedObject = useCallback(() => {
    // Only allow deletion if not currently adding text
    if (isCanvasDisabled) {
      return; // Do not add or modify objects
    }

    if (selectedMode != "ENTERING_TEXT") {
      const activeObject = canvas?.getActiveObject();
      if (activeObject) {
        if (activeObject.type === "activeSelection") {
          // For groups, iterate over each object and remove it
          activeObject.forEachObject((obj) => canvas.remove(obj));
        } else {
          // For single objects, just remove the object
          canvas.remove(activeObject);
        }
        canvas.discardActiveObject(); // Deselect the group
        canvas.requestRenderAll();
        saveCanvasState(canvas);
      }
    }
  }, [canvas, saveCanvasState, selectedMode]); // Include isAddingText in the dependencies

  useEffect(() => {
    if (isCanvasDisabled) {
      return; // Do not add or modify objects
    }

    const handleKeyDown = (event) => {
      // Check if the user is editing text and if Delete or Backspace is pressed
      if (
        selectedMode != "ENTERING_TEXT" &&
        canvas?.getActiveObject() &&
        (event.key === "Delete" || event.key === "Backspace")
      ) {
        event.preventDefault(); // Prevent default behavior
        deleteSelectedObject();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [deleteSelectedObject, canvas, selectedMode]); // Include isAddingText in the dependencies

  // Effect for debouncing canvas state saving
  const debouncedSaveCanvasState = useCallback(
    _.debounce(() => saveCanvasState(canvas), 1000),
    [canvas, saveCanvasState]
  );

  // Effect for updating canvas settings and attaching event listeners
  useEffect(() => {
    if (isCanvasDisabled || !canvas) {
      return; // Do not add or modify objects
    }

    const handleCanvasChange = () => debouncedSaveCanvasState();
    const handlePathCreated = (event) => {
      // Check if the created path is from the EraserBrush
      if (selectedMode === "ERASER") {
        saveCanvasState(canvas);
      }
    };

    canvas.isDrawingMode = selectedMode === "DRAWING";
    if (canvas.isDrawingMode) {
      canvas.freeDrawingBrush.color = brushColor;
      canvas.freeDrawingBrush.width = parseInt(brushSize);
    }
    if (selectedMode === "ERASER") {
      handleEraser();
      canvas.freeDrawingBrush.width = parseInt(brushSize);
    }

    canvas.on("object:modified", handleCanvasChange);
    canvas.on("object:added", handleCanvasChange);
    canvas.on("path:created", handlePathCreated);

    return () => {
      canvas.off("object:modified", handleCanvasChange);
      canvas.off("object:added", handleCanvasChange);
      canvas.off("path:created", handlePathCreated);
    };
  }, [
    canvas,
    selectedMode,
    brushColor,
    brushSize,
    debouncedSaveCanvasState,
    saveCanvasState,
  ]);

  // Effect for adding images to canvas
  // Effect for adding images to canvas
  useEffect(() => {
    if (isCanvasDisabled) {
      return; // Do not add or modify objects if disabled
    }

    // Check if addImage is not null and has a url property
    if (canvas && addImage && addImage.url) {
      fabric.Image.fromURL(
        addImage.url,
        (img) => {
          if (img.getElement()) {
            // Check if the image element is available
            const scale = Math.min(
              canvas.width / img.width,
              canvas.height / img.height
            );
            img.set({
              scaleX: scale,
              scaleY: scale,
              left: canvas.width / 2 - (img.width * scale) / 2,
              top: canvas.height / 2 - (img.height * scale) / 2,
              uuid: addImage.id,
            });

            canvas.add(img);
            canvas.renderAll();
            saveCanvasState(canvas);
          } else {
            console.error("Failed to load image:", addImage.url);
          }
        },
        {
          crossOrigin: "anonymous", // Handle cross-origin images
        }
      );
    }
  }, [addImage, isCanvasDisabled]);

  return <canvas ref={canvasRef} />;
};

export default FabricCanvas;
