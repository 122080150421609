import solanaIcon from "../SOL.png";
import solScan from "./solscan.png";
import shyft from "./shyft.png";
import solExplorer from "./solExplorer.png";
import heliusXRay from "./heliusLogo.jpeg";

export function SolanaIcon({ size }) {
  return (
    <img
      src={solanaIcon}
      className={`h-${size} w-${size}`}
      alt="cardano logo"
    />
  );
}
export function SolScanIcon({ size }) {
  return (
    <img src={solScan} className={`h-${size} w-${size}`} alt="hex chain scan" />
  );
}
export function ShyftIcon({ size }) {
  return (
    <img
      src={shyft}
      className={`h-${size} w-${size} hover:scale-110 hover:cursor-pointer transition duration-400 spring`}
      alt="Polygon L5 ZK scan"
    />
  );
}
export function SolExplorerIcon({ size }) {
  return (
    <img
      src={solExplorer}
      alt="hex chain scan"
      className={`h-${size} w-${size} rounded-full p-1 bg-black  hover:scale-110 hover:cursor-pointer transition duration-400 spring`}
    />
  );
}
export function HeliusXRayLogo({ size }) {
  return (
    <img
      src={heliusXRay}
      alt="man just gtfo scan"
      className={`h-${size} w-${size} hover:scale-110 hover:cursor-pointer transition duration-400 spring rounded-full hover:rotate-[12deg]`}
    />
  );
}
