import React, { useState, useEffect } from "react";
import PrimaryToggle from "../../pages/testing/testing2/toggleSwitch/primaryToggle";
import SingleCellForPurchaseRow from "./singleCellForPurchaseRow";
import PricingSection from "./checkoutPricingSection";
import ActionButtonRow from "./actionButtonRow";
import useSolanaPrice from "../../utils/hooks/useSolPrice";
import { useAuthenticationClientContextTwo } from "../../pages/testing/auth2Flow/onboardingContextClientTwo";
import { PublicKey } from "@solana/web3.js";
import { sendSol } from "../../pages/testing/testing2/txHooks/basic_send";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { getNetworkFeesForTransaction } from "../../pages/testing/testing2/txHooks/basic_send";
import { SolanaIcon } from "../../assets/icons/iconComponents";
import { truncateAddress } from "../../utils/helpers/formattingHelpers";
import { useWallDisplayContext } from "../../utils/contexts/wallDisplayContext";
import DidPressPurchaseView from "./transactionSentView";
import { SolUSDToggle } from "../elements/toggleUSD";
import { Transaction } from "@solana/web3.js";
import { pressPurchase } from "../../utils/hooks/chain/mint_cell";
import MultiCellMint from "./multiCellMint";

export default function CheckoutModalDisplay() {
  const {
    cellShoppingCart,
    setCellShoppingCart,
    setSelectedDisplayOption,
    setShowMarketHelper,
    displayedUser,
  } = useWallDisplayContext();
  const [showSOL, setShowSOL] = useState(true);
  const [userBalance, setUserBalance] = useState(0);
  const [didPressPurchase, setDidPressPurchase] = useState(false);
  const [estNetworkFee, setEstNetworkFee] = useState(0);
  const [txOutcome, setTxOutcome] = useState("");
  const { connection } = useConnection();
  const { price, isLoading } = useSolanaPrice();
  const { currentUser } = useAuthenticationClientContextTwo();
  const [txIsLoading, setTxIsLoading] = useState(false);
  const wallet = useWallet();

  const mintFeePerCell = 0.000015;

  const mint_fees = cellShoppingCart.length * mintFeePerCell;

  const mint_price = cellShoppingCart.reduce(
    (acc, cell) => acc + Number(cell.current_price),
    0
  );
  const total = mint_fees + mint_price;
  const fetchBalance = async () => {
    if (currentUser.user_address) {
      const userPublicKey = new PublicKey(currentUser.user_address);
      const userBalance = await connection.getBalance(userPublicKey);
      const solBalance = userBalance / 1e9; // Convert lamports to SOL
      setUserBalance(solBalance);
    }
  };
  // console.log(cellShoppingCart);
  useEffect(() => {
    fetchBalance();
  }, [currentUser.user_address, connection]);

  const fetchNetworkFee = async () => {
    if (!currentUser.user_address) {
      console.error("User address is not available.");
      return;
    }
    const mint_fees = cellShoppingCart.length * mintFeePerCell;

    setEstNetworkFee(mint_fees);
    console.log(estNetworkFee);
  };

  useEffect(() => {
    fetchNetworkFee();
  }, [cellShoppingCart, connection]);
  function cancelPurchase() {
    setSelectedDisplayOption("wall");
    setShowMarketHelper(false);
  }
  // console.log("displayedUser", displayedUser);
  async function purchaseCell() {
    const no_of_cells = cellShoppingCart.length;
    setTxIsLoading(true);
    const result = await pressPurchase({
      setDidPressPurchase,
      cells: cellShoppingCart,
      user_minting: currentUser.user_address,
      no_of_cells,
      tx_total: total,
      wallet,
      connection,
      displayedUser,
      setIsLoading: setTxIsLoading,
      currentUser,
    });
    if (result.success) {
      console.log(`Transaction successful with txId: ${result.txId}`);
      console.log(result);
      setTxOutcome(result);
    } else {
      console.error(`Transaction failed: ${result.error}`);
      setTxOutcome(result);
      setTxIsLoading(false);
      // Handle failed transaction here
    }
  }
  // console.log(cellShoppingCart);
  const pre_checkout_view = (
    <>
      <PreCheckoutView
        cellShoppingCart={cellShoppingCart}
        userBalance={userBalance}
        total={total}
        mint_price={mint_price}
        price={price}
        estNetworkFee={estNetworkFee}
      />
      <ActionButtonRow
        primaryAction={() => purchaseCell()}
        secondaryAction={() => cancelPurchase()}
        userBalance={userBalance}
        cartTotal={total}
      />
    </>
  );
  return (
    <div className="flex flex-col w-full h-full p-4 bg-white">
      {didPressPurchase ? (
        <>
          <DidPressPurchaseView
            result={txOutcome}
            isLoading={txIsLoading}
            purchaseCell={purchaseCell}
          />
        </>
      ) : (
        pre_checkout_view
      )}
    </div>
  );
}

//------------------

function PreCheckoutView({
  cellShoppingCart,
  userBalance,
  total,
  mint_price,
  price,
  estNetworkFee,
}) {
  const [showSOL, setShowSOL] = useState(true);
  const no_of_cells = cellShoppingCart.length;
  const no_of_tx_req = Math.ceil(no_of_cells / 3);
  const headerText = cellShoppingCart.length > 0 ? "Checkout" : "";
  const leadingInfoSection = (
    <div className="flex py-2 items-center w-full">
      <div className="flex items-center gap-2 text-grayscale-600 justify-center ">
        <div className="flex h-full items-center gap-2 px-4 py-2 g-gradient-to-b from-white to-stone-200/10 shadow-[0_1px_3px_rgba(0,0,0,0.2)] rounded-[12px]">
          <p className="text-grayscale-500 text-sm">Wallet Balance:</p>
          <p className="text-grayscale-500">{userBalance.toFixed(3)}</p>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <div className="flex w-full  justify-between items-center">
        <p className="text-grayscale-600 font-medium text-2xl mb-1">
          {headerText}
        </p>
        <SolUSDToggle />
      </div>
      <hr className="my-2 border-t border-grayscale-300/50" />
      <div className="flex flex-col overflow-auto gap-2 scrollbar-custom flex-grow">
        {cellShoppingCart.length > 0 ? (
          cellShoppingCart.map((cell) => (
            <SingleCellForPurchaseRow
              key={cell.id}
              showSOL={showSOL}
              solPrice={price}
              cell={cell}
            />
          ))
        ) : (
          <div className="flex justify-center items-center h-full">
            <p>Empty Cart</p>
          </div>
        )}
      </div>
      <hr className="my-2 border-t border-grayscale-300/50" />
      Balance: {userBalance}
      <div className="flex w-full justify-between"></div>
      <PricingSection
        showSOL={showSOL}
        txCost={{ mint_price, estNetworkFee, total }}
        solPrice={price}
        no_of_tx={no_of_tx_req}
      />
    </>
  );
}
