import { X, XCircle, Check } from "lucide-react";
import solIcon from "../../assets/SOL.png";
import { useAuthenticationClientContextTwo } from "../../pages/testing/auth2Flow/onboardingContextClientTwo";
import { truncateAddress } from "../../utils/helpers/formattingHelpers";
import React, { useState, useEffect } from "react";
import {
  acceptFriendRequest,
  getFriendRequests,
  denyFriendRequest,
  getPortfolioData,
} from "../../utils/helpers/ourAPIHelpers";
import { useSpring, animated } from "@react-spring/web";
import { Droplets, Cuboid, AlignVerticalDistributeEnd } from "lucide-react";
import "../../pages/inApp/yourBricks/scrollbar.css";
import YourWallUpdate from "./modal/recentTradeBar";
import emptyUFO from "../../assets/icons/kidoUFO.png";
import "./notificationModal.css";
import Avatar from "../elements/avatar";
import { PrimaryButton } from "../elements/primaryButton";

export default function NotificationModalInner({
  setShowNotificationsPopover,
}) {
  const [activeTab, setActiveTab] = useState("Mint");
  const { currentUser } = useAuthenticationClientContextTwo();
  const [friendRequests, setFriendRequests] = useState([]); // Initialize as an array
  const removeDisplayBar = (requestingUserId) => {
    setFriendRequests((currentRequests) =>
      currentRequests.filter(
        (request) => request.requesting_user_id !== requestingUserId
      )
    );
  };
  useEffect(() => {
    // Assuming getFriendRequests is an async function
    const fetchRequests = async () => {
      let fetchedRequests = await getFriendRequests(currentUser.id);
      // Sort in descending order of no_of_mutuals
      fetchedRequests.sort((a, b) => b.no_of_mutuals - a.no_of_mutuals);
      setFriendRequests(fetchedRequests);
    };
    fetchRequests();
  }, [currentUser]);

  // -----------------------------------------------------------
  const exitButton = (
    <button
      onClick={() => setShowNotificationsPopover(false)}
      className="absolute top-0 left-0 flex px-1 py-1 "
    >
      <XCircle className="stroke-red-600 fill-red-300 opacity-40 hover:opacity-100 shadow-sm hover:cursor-pointer hover:scale-110 transition duration-800 ease" />
    </button>
  );
  const Tab = ({ name }) => (
    <div
      className={`flex flex-col text-lg rounded-lg px-[12px] py-[6px] font-semibold text-grayscale-800 transition duration-300 opacity hover:cursor-pointer ${
        activeTab === name
          ? "bg-grayscale-300/40"
          : "hover:bg-ourPurple-500 opacity-40 hover:opacity-80 "
      }`}
      onClick={() => setActiveTab(name)}
    >
      <p className="font-provider">{name}</p>
    </div>
  );
  const tabBar = (
    <div className="flex mt-8 gap-2">
      <Tab name={"Mint"} />
      <Tab name="Friend Requests" />
      <Tab name="Your Wall" />
      <Tab name="Your Cells" />
    </div>
  );

  const friendRequestDisplay = (
    <div className="flex flex-col gap-1 overflow-auto max-h-96 pb-10 scrollbar-custom">
      {friendRequests && friendRequests.length > 0 ? (
        friendRequests.map((requestingUser) => (
          <SingleUserDisplayBar
            key={requestingUser.requesting_user_id}
            requestingUser={requestingUser}
            currentUser={currentUser}
            removeDisplayBar={removeDisplayBar}
          />
        ))
      ) : (
        <div className=" h-full w-full  mt-2 flex flex-col gap-4  items-center justify-center ">
          <img src={emptyUFO} className="h-54 w-54" />
          <p className="text-grayscale-600 text-center text-lg">
            No friend requests at the moment.
          </p>
        </div>
      )}
    </div>
  );

  function handleCurrentlySelectedTab() {
    switch (activeTab) {
      case "Mint":
        return <AcceptMintRow />;
      case "Friend Requests":
        return friendRequestDisplay;
      case "Your Wall":
        // TODO: Implement Wall Activity view
        return <YourWallUpdate />;
      case "Your Cells":
        // TODO: Implement Your Cells view
        return <YourCellsOverview currentUser={currentUser} />;
      default:
        return <div>Select a tab to view content</div>;
    }
  }
  return (
    <div className="relative">
      {exitButton}
      <div className="flex flex-col gap-2">{tabBar}</div>
      {handleCurrentlySelectedTab()}
    </div>
  );
}
function YourCellsOverview({ currentUser }) {
  const [portfolioData, setPortfolioData] = useState([]);

  useEffect(() => {
    const fetchPortfolio = async () => {
      const fetchedData = await getPortfolioData(currentUser.id);
      setPortfolioData(fetchedData || []);
    };
    fetchPortfolio();
  }, [currentUser]);

  return (
    <div className="grid grid-cols-3 gap-2 w-full h-full mt-2">
      {portfolioData.map((portfolioItem) => (
        <SingleCellForOverview
          key={portfolioItem.cellId}
          portfolioItem={portfolioItem}
        />
      ))}
    </div>
  );
}

function SingleCellForOverview({ portfolioItem }) {
  return (
    <div className="flex w-full h-full inter ">
      <div className=" flex flex-col w-full  bg-gradient-to-b from-white to-stone-200/20 shadow-[0_1px_3px_rgba(0,0,0,0.15)] border border-1 border-stone-200/20 rounded-xl gap-2 p-2">
        <span className="flex w-full items-center justify-center">
          <img src={portfolioItem.pfp_url} className="w-12 h-12 rounded-full" />
        </span>
        <span className="flex gap-1 w-full justify-between items-center">
          <span className="flex gap-2">
            <Droplets className="stroke-grayscale-400" size={18} />
            <p className="text-grayscale-500 inter text-xs"> Volume (1w)</p>
          </span>
          <span className="flex items-center gap-[4px]">
            <img
              src={solIcon}
              alt="cardano icon"
              className="h-[12px] w-[12px] opacity-60"
            />
            <p className="text-grayscale-800 inter text-sm">
              {portfolioItem.one_w_vol}
            </p>
          </span>
        </span>
        <span className="flex gap-1 w-full justify-between items-center">
          <span className="flex gap-2">
            <Cuboid className="stroke-grayscale-400" size={18} />
            <p className="text-grayscale-500 inter text-xs">You Own</p>
          </span>

          <p className="text-grayscale-800 inter text-sm">
            x{portfolioItem.cell_count}
          </p>
        </span>
        <span className="flex gap-1 w-full justify-between items-center">
          <span className="flex gap-2">
            <img
              src={solIcon}
              alt="cardano icon"
              className="h-[18px] w-[18px] opacity-60"
            />
            <p className="text-grayscale-500 inter text-xs">Mkt Cap</p>
          </span>

          <p className="text-grayscale-800 inter text-sm">
            {portfolioItem.total_price}
          </p>
        </span>
      </div>
    </div>
  );
}
function SingleUserDisplayBar({
  requestingUser,
  currentUser,
  removeDisplayBar,
}) {
  const [requestStatus, setRequestStatus] = useState("pending");

  // Define the spring animation for fading and scaling
  const fadeAndScale = useSpring({
    to: {
      opacity: requestStatus === "pending" ? 1 : 0,
      transform: requestStatus === "pending" ? "scale(1)" : "scale(0.8)",
    },
    config: { duration: 2000 },
  });

  useEffect(() => {
    if (requestStatus !== "pending") {
      const timer = setTimeout(() => {
        removeDisplayBar(requestingUser.requesting_user_id);
      }, 1900);
      return () => clearTimeout(timer);
    }
  }, [requestStatus, removeDisplayBar, requestingUser.requesting_user_id]);

  async function handleAcceptRequest() {
    try {
      await acceptFriendRequest({
        requesting_user_id: requestingUser.requesting_user_id,
        requested_user_id: currentUser.id,
      });
      setRequestStatus("accepted");
    } catch (error) {
      console.error("Error accepting friend request:", error);
    }
  }

  async function handleDenyRequest() {
    try {
      await denyFriendRequest({
        requesting_user_id: requestingUser.requesting_user_id,
        requested_user_id: currentUser.id,
      });
      setRequestStatus("denied");
    } catch (error) {
      console.error("Error denying friend request:", error);
    }
  }

  const requestHandledStyle =
    requestStatus === "accepted" ? "bg-ourGreen-600/80" : "bg-red-500/80";
  const requestHandledIcon =
    requestStatus === "accepted" ? (
      <Check className="text-white" />
    ) : (
      <X className="text-white" />
    );

  return (
    <animated.div style={fadeAndScale} className="">
      <div
        className={`flex justify-between items-center p-2 rounded-xl transition-all duration-500 ease-in-out ${
          requestStatus !== "pending" ? requestHandledStyle : ""
        }`}
      >
        {requestStatus !== "pending" ? (
          <div className="flex justify-center items-center w-full">
            {requestHandledIcon}
          </div>
        ) : (
          <>
            <div className="flex gap-2 items-center">
              <img
                src={requestingUser.requesting_user_pfp}
                alt="requesting_user_pfp"
                className="h-10 w-10 rounded-full"
                loading="lazy"
              />
              <span className="flex flex-col items-start -space-y-1">
                <p className="font-semibold  text-xl text-grayscale-800">
                  {requestingUser.requesting_user_name}
                </p>
                <span className="flex items-center gap-2">
                  <a
                    href={`https://solscan.io/account/${requestingUser.requesting_user_address}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="font-regular text-sm text-grayscale-800">
                      {truncateAddress(requestingUser.requesting_user_address)}
                    </p>
                  </a>
                </span>
              </span>
            </div>
            <div className="flex gap-2 items-center">
              <div className="flex flex-col space-y-0">
                <p className="font-normal text-sm text-grayscale-500">
                  {requestingUser.no_of_mutuals} Mutuals
                </p>
              </div>
              <button
                onClick={handleDenyRequest}
                className="bg-red-400/60 px-2 py-1 rounded-lg text-red-900 font-medium hover:scale-105 transition duration-600 ease"
              >
                <X />
              </button>
              <button
                onClick={handleAcceptRequest}
                className="bg-ourGreen-400 px-2 py-1 rounded-lg text-ourGreen-900 font-medium hover:scale-105 transition duration-600 ease"
              >
                <Check />
              </button>
            </div>
          </>
        )}
      </div>
    </animated.div>
  );
}

function AcceptMintRow() {
  return (
    <div className="flex w-full p-2 h-min justify-between bg-grayscale-300/10 mt-1 rounded-xl">
      <div className="flex gap-4 items-center ">
        <div className="flex gap-2 items-center ">
          <Avatar
            source={
              "https://firebasestorage.googleapis.com/v0/b/wallz696969.appspot.com/o/profile_pictures%2FsFmD7zHdT8P4f4S8xmtcRvhxHvI2%2F00b86fd1-8ba6-4243-a2e5-e1d4d13b5aec?alt=media&token=798c547a-8ba8-43e7-9b33-a7f06d00264d"
            }
            size={"8"}
          />
          <div className="flex flex-col -space-y-1">
            <p className="text-grayscale-900">yashwant</p>
            <p className="text-sm text-grayscale-400">@ywc</p>
          </div>
        </div>
      </div>
      <div className="flex gap-4 items-center">
        <button
          className={[
            "text-ourPurple-700 font-bold text-sm items-center flex gap-3 justify-center",
            " py-[8px] min-w-[128px]",
            "rounded-[10px] border border-1 border-ourPurple-600/20",
            "bg-ourPurple-600/40 shadow-[inset_0_1px_1px_rgba(255,255,255,0.4)]",
            "active:shadow-none",
            "active:scale-95 transform transition duration-200",
          ].join(" ")}
        >
          Claim
        </button>
      </div>
    </div>
  );
}
