import george from "../assets/landingPageImages/georgie.png";
import { motion } from "framer-motion";

export default function GeoBlockedPage() {
  return (
    <div className=" flex flex-col h-screen w-screen bg-ourBlue-800 gap-3 items-center">
      <img src={george} />

      <p className="font-bold text-6xl text-red-700 capitalized text-center">
        I'm proud to be an American <br />
        Where at least I know I'm free <br /> And I won't forget the men who
        died <br />
        Who gave that right to me <br /> And I'd gladly stand up next to you{" "}
        <br /> And defend Her still today <br /> 'Cause there ain't no doubt I
        love this land
        <br /> God Bless the U.S.A.
      </p>
    </div>
  );
}
