import React, { useState, useEffect } from "react";
import NavBar from "../navBar";
import SettingsPopover from "./settingsPopover";
import Modal from "react-modal";
import NotificationModalInner from "./notificationModalInner";
import "./layoutProvider.css";

const InAppLayout = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showSettingsPopover, setShowSettingsPopover] = useState(false);
  const [showNotificationsPopover, setShowNotificationsPopover] =
    useState(false);
  const [popoverTimeoutId, setPopoverTimeoutId] = useState(null);

  const handleShowPopover = () => {
    // Clear any existing timer
    if (popoverTimeoutId) {
      clearTimeout(popoverTimeoutId);
      setPopoverTimeoutId(null);
    }

    // Show the popover without starting a new timer
    // Show the popover
    setShowSettingsPopover(true);

    // Start a timer to automatically hide the popover if the user doesn't interact with it
    const timeoutId = setTimeout(() => {
      setShowSettingsPopover(false);
    }, 600); // 600ms delay before hiding the popover if not interacted with
    setPopoverTimeoutId(timeoutId);
  };

  const handleMouseEnterPopover = () => {
    // Clear the timer to keep the popover open while the user is interacting with it
    if (popoverTimeoutId) {
      clearTimeout(popoverTimeoutId);
      setPopoverTimeoutId(null);
    }
  };

  const handleMouseLeavePopover = () => {
    // Start a new timer to hide the popover after a delay when the user leaves the popover
    const timeoutId = setTimeout(() => {
      setShowSettingsPopover(false);
    }, 600); // 600ms delay before hiding the popover
    setPopoverTimeoutId(timeoutId);
  };

  return (
    <div className="flex bg-insetPrimary w-screen h-screen p-2 space-x-4">
      <NavBar
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        setShowSettingsPopover={handleShowPopover}
        showNotificationsPopover={showNotificationsPopover}
        setShowNotificationsPopover={setShowNotificationsPopover}
      />
      {showSettingsPopover && (
        <SettingsPopover
          isExpanded={isExpanded}
          onMouseLeave={handleMouseLeavePopover}
          onMouseEnter={handleMouseEnterPopover}
        />
      )}
      {showNotificationsPopover && (
        <Modal
          isOpen={showNotificationsPopover}
          onRequestClose={() => setShowNotificationsPopover(false)}
          contentLabel="Notifications Modal"
          className={
            "w-1/2 bg-white p-4 justify-center items-center rounded-2xl h-1/2 noti-modal-center"
          }
          overlayClassName="noti-overlay-style"
        >
          <NotificationModalInner
            setShowNotificationsPopover={setShowNotificationsPopover}
          />
        </Modal>
      )}
      <main className="flex-grow">{children}</main>
    </div>
  );
};

export default InAppLayout;
