import React from "react";
import { ProtectedRoute } from "../protectedRoutes";
import InAppLayout from "../../components/layoutProvider/layoutWIthNavBar";

import OrbitIndex from "../../pages/inApp/yourOrbit/orbitIndex";
import AuthenticatedTrending from "../../pages/inApp/authenticatedTrending";
import AuthenticatedMarket from "../../pages/inApp/market/authenticatedMarket";
import YourBricksIndex from "../../pages/inApp/yourBricks/yourBricksIndex";
import EditingSingleBrick from "../../pages/inApp/yourBricks/editingSingleBrick";
import YourWallIndex from "../../pages/inApp/yourWall/yourWallIndex";
import OtherUserWall from "../../pages/otherUserWall";
import TestingIndex from "../../pages/testing/testingIndex";
import NetworkImportIndex from "../../pages/inApp/importNetwork/networkImportIndex";
export const inAppRoutes = [
  {
    path: "/orbit",
    element: (
      <ProtectedRoute>
        <InAppLayout>
          <OrbitIndex />
        </InAppLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/trending",
    element: (
      <ProtectedRoute>
        <InAppLayout>
          <AuthenticatedTrending />
        </InAppLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/explore",
    element: (
      <ProtectedRoute>
        <InAppLayout>
          <AuthenticatedMarket />
        </InAppLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/yourbricks",
    element: (
      <ProtectedRoute>
        <InAppLayout>
          <YourBricksIndex />
        </InAppLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/wall",
    element: (
      <ProtectedRoute>
        <InAppLayout>
          <YourWallIndex />
        </InAppLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/:username/:cellId/:edit",
    element: (
      <ProtectedRoute>
        <InAppLayout>
          <EditingSingleBrick />
        </InAppLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/:username/wall",
    element: (
      <ProtectedRoute>
        <InAppLayout>
          <OtherUserWall />
        </InAppLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/import-network",
    element: (
      <ProtectedRoute>
        <InAppLayout>
          <NetworkImportIndex />
        </InAppLayout>
      </ProtectedRoute>
    ),
  },
  {
    path: "/testing",
    element: (
      <ProtectedRoute>
        {/* <InAppLayout> */}
        <TestingIndex />
        {/* </InAppLayout> */}
      </ProtectedRoute>
    ),
  },
];
