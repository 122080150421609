import React, { useRef, useState, useEffect } from "react";
import "../orbitStyle.css";

export function SingleUserOrb({
  user,
  position,
  onClick,
  isCurrentUser = false,
  isSelectedUser = false,
}) {
  const [loadImage, setLoadImage] = useState(false);
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setLoadImage(true);
          observer.disconnect();
        }
      },
      { rootMargin: "200px" } // Adjust rootMargin as needed for early loading
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  const currentUserClass = isCurrentUser ? "is-current-user" : "";
  const selectedUserClass = isSelectedUser ? "orb-selected-user" : "";
  // Combine necessary class names based on the user's state
  const orbClassNames = `flex flex-col gap-1 items-center no-select orb-container orb-float ${currentUserClass} ${selectedUserClass}`;

  return (
    <div
      style={{
        left: `${position.x}px`,
        top: `${position.y}px`,
      }}
      className={orbClassNames}
      onClick={onClick}
      onMouseEnter={() => (imageRef.current.style.cursor = "pointer")}
      onMouseLeave={() => (imageRef.current.style.cursor = "")}
      ref={imageRef}
    >
      {loadImage && (
        <img
          src={user.pfp_url}
          alt={user.user_name}
          className="h-16 w-16 rounded-full"
        />
      )}
      <p className="text-xl">{user.user_name}</p>
    </div>
  );
}
