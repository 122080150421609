import React, { useMemo, useState, useEffect } from "react";
import {
  useWallDisplayContext,
  WallDisplayProvider,
} from "../../utils/contexts/wallDisplayContext";
import { getCellsForWall } from "../../utils/helpers/ourAPIHelpers";
import SingleCellForWall from "../../pages/inApp/yourWall/singleCellForWall";

import { useAuthenticationClientContextTwo } from "../../pages/testing/auth2Flow/onboardingContextClientTwo";
// ---------------
export default function WallComponentSimplified({
  userId,
  cells: externalCells,
  showCellModal,
  setShowCellModal,
  isOtherUserWall = false,
  selectedUser,
}) {
  const { currentUser } = useAuthenticationClientContextTwo();
  const {
    isDragging,
    setIsDragging,
    startPos,
    setStartPos,
    offset,
    setOffset,
    scale,
    setScale,
    showPrices,
    setShowPrices,
    isClick,
    setIsClick,
    loading,
    setLoading,
    userCells,
    setUserCells,
    displayedUser,
    setDisplayedUser,
    selectedCell,
    setSelectedCell,
    addToShoppingCart,
  } = useWallDisplayContext();

  const toggleShowPrices = () => setShowPrices(!showPrices);

  // Function to find and set displayed user for cell at x = 'J', y = 16
  const findAndSetDisplayedUserForCell = () => {
    const targetCell = userCells.find(
      (cell) => cell.x_position === "J" && cell.y_position === "16"
    );
    if (targetCell) {
      setDisplayedUser({
        username: targetCell.wall_owner_username,
        pfp_url: targetCell.pfp_url,
        owner_id: targetCell.owner_id,
      }); // Assuming 'wall_owner_username' is the desired property
    } else {
      console.log("Cell not found or does not exist");
    }
  };
  let clickStartTime;

  const DRAG_THRESHOLD = 30; // Adjust the pixel threshold for drag
  const CLICK_TIME_THRESHOLD = 300; // Increase to 300 milliseconds

  let dragDetected = false;

  const handleMouseDown = (e) => {
    setIsClick(true);
    setStartPos({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y,
    });
    clickStartTime = new Date().getTime(); // Record start time
    dragDetected = false; // Reset drag detection
  };

  const handleMouseMove = (e) => {
    const x = e.clientX - startPos.x;
    const y = e.clientY - startPos.y;
    if (!isDragging) {
      if (Math.abs(x) > DRAG_THRESHOLD || Math.abs(y) > DRAG_THRESHOLD) {
        const currentTime = new Date().getTime();
        if (currentTime - clickStartTime > CLICK_TIME_THRESHOLD) {
          setIsDragging(true); // Confirm it's a drag
          setIsClick(false); // Not a click
          dragDetected = true; // Mark drag as detected
        }
      }
    }

    if (isDragging) {
      setOffset({ x, y });
    }
  };

  const handleMouseUp = (e) => {
    setIsDragging(false);
    if (!dragDetected && isClick && selectedCell) {
      handleCellClick(selectedCell); // Handle as click
    }
    setIsClick(false); // Reset click flag
  };

  const handleWheel = (e) => {
    if (e.altKey) {
      // e.preventDefault(); // Prevent the default scrolling behavior
      const newScale = scale + e.deltaY * -0.003; // Adjust this value to control the zoom sensitivity
      // Ensure newScale is within allowed bounds
      const boundedScale = Math.min(Math.max(newScale, MIN_SCALE), MAX_SCALE);
      handleScaleChange(boundedScale);
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", handleWheel);
    return () => window.removeEventListener("wheel", handleWheel);
  }, [scale]);

  const MIN_SCALE = 0.5; // Define minimum scale
  const MAX_SCALE = 1.7; // Define maximum scale

  function handleCellClick(cell) {
    if (!isDragging) {
      if (!cell.is_enabled && cell.current_price > 0) {
        addToShoppingCart(cell);
      }
      setSelectedCell(cell);
      setShowCellModal(true);
      // if (selectedCell) {
    }
  }
  // Function to convert x_position and y_position to grid coordinates, considering 'has_flipped'
  const getPosition = (x, y, is_enabled, has_flipped, allCells) => {
    const xCoord = x.charCodeAt(0) - "A".charCodeAt(0); // Convert 'A' to 0, 'B' to 1, etc.
    let yCoord = parseInt(y) - 1; // Convert '01' to 0, '02' to 1, etc.

    if (is_enabled && has_flipped) {
      // Find the cell that currently occupies the target position after flipping
      const targetYCoord = 19 - yCoord; // Invert y-coordinate
      const targetCell = allCells.find(
        (cell) =>
          cell.x_position === x &&
          cell.y_position === (targetYCoord + 1).toString()
      );

      if (targetCell) {
        // Swap positions with the target cell
        yCoord = parseInt(targetCell.y_position) - 1;
      } else {
        // If no cell is found in the target position, use the inverted y-coordinate
        yCoord = targetYCoord;
      }
    }

    return { x: xCoord, y: yCoord };
  };

  const handleScaleChange = (newScale) => {
    setScale(newScale);
  };
  useEffect(() => {
    // printScreenSize();
    let offsetX = -689;
    let offsetY = -664;
    setScale(0.65);
    // Check if the screen width is less than 1440 pixels and adjust offsets
    if (window.innerWidth < 1441) {
      offsetX = -901;
      offsetY = -891;
      setScale(0.55);
    }

    setOffset({ x: offsetX, y: offsetY });
    // Continue with the rest of your effect
    if (externalCells) {
      setUserCells(externalCells);
      findAndSetDisplayedUserForCell();
      setLoading(false); // Assume external cells are already loaded
    } else if (userId) {
      getCellsForWall({ wall_id: userId, user_id: currentUser.id })
        .then((response) => {
          setUserCells(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching cells:", error);
          setLoading(false);
        });
    }
  }, [userId, externalCells, setUserCells, setLoading]);

  const printScreenSize = () => {
    console.log(
      `Screen Width: ${window.innerWidth}, Screen Height: ${window.innerHeight}`
    );
  };
  // Generate the grid squares
  const squares = userCells.map((cell, index) => {
    const { x, y } = getPosition(
      cell.x_position,
      cell.y_position,
      cell.is_enabled,
      cell.has_flipped
    );
    return (
      <SingleCellForWall
        key={index}
        cell={cell}
        x={x}
        y={y}
        isDragging={isDragging}
        setIsDragging={setIsDragging}
        scaleFactor={200 / 800}
        onClick={() => handleCellClick(cell)}
      />
    );
  });

  const grid = (
    <div
      style={{
        width: "4000px",
        height: "4000px",
        position: "absolute",
        cursor: isDragging ? "grabbing" : "grab",
        transform: `translate(${offset.x}px, ${offset.y}px) scale(${scale})`,
        transition: isDragging ? "none" : "transform 0.1s",
      }}
      onMouseDown={handleMouseDown}
    >
      <div
        style={{
          width: "4000px",
          height: "4000px",
          position: "absolute",
          cursor: isDragging ? "grabbing" : "grab",
          transform: `translate(${offset.x}px, ${offset.y}px) scale(${scale})`,
          transition: isDragging ? "none" : "transform 0.2s",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(20, 200px)",
            gridTemplateRows: "repeat(20, 200px)",
          }}
        >
          {!loading && squares}
        </div>
      </div>
    </div>
  );

  function handleModalClose() {
    setShowCellModal(false);
    setSelectedCell(null);
  }

  return (
    <div
      className="flex flex-col bg-ourBlue-400 text-ourBlue-800 font-sans h-full w-full rounded-3xl p-6"
      style={{
        boxShadow: "inset 0 0 10px #CDD3FE",
        transition: "width 0.5s",
        position: "relative",
        overflow: "hidden",
      }}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseUp}
      onMouseUp={handleMouseUp}
    >
      {grid}
    </div>
  );
}
