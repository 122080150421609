import React, { useState } from "react";

import OnboardingBackgroundCanvas from "./elements/onboardingBackgroundCanvas";
import {
  AuthenticationClientProviderTwo,
  useAuthenticationClientContextTwo,
} from "../testing/auth2Flow/onboardingContextClientTwo";
import SplashPageContainer from "../testing/auth2Flow/splashPageContainer";
import "./authenticationPage.css";
import WaitlistPage from "../testing/waitlist";
import TwitterConnectStepTwo from "../testing/auth2Flow/connectTwitterStepTwo";
import CreateAccountStep from "../testing/auth2Flow/createAccountStep";
import ClaimGiftedCellsView from "../testing/auth2Flow/claimGiftedCells";

export default function AuthenticationPage() {
  return (
    <div className="app-container bg-insetPrimary">
      <OnboardingBackgroundCanvas />
      <div className="onboarding-overlay">
        <OnboardingProcess />
      </div>
    </div>
  );
}

function OnboardingProcess() {
  const { currentStep } = useAuthenticationClientContextTwo();

  const renderStepComponent = () => {
    switch (currentStep) {
      case "showLandingPage":
        return <SplashPageContainer />;
      case "showWaitList":
        return <WaitlistPage />;
      case "showConnectTwitter":
        return <TwitterConnectStepTwo />;
      case "showCreateAccount":
        return <CreateAccountStep />;
      case "noSolInWallet":
        return <ClaimGiftedCellsView />;
      case "showGiftClaim":
        return <ClaimGiftedCellsView />;
      case "showWallMint":
        return <p>MINT</p>;
      case "showApp":
        return <p>ERROR</p>;
      default:
        return <p>DEFAYKT</p>;
    }
  };

  return renderStepComponent();
}
