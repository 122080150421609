import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthenticationClientContextTwo } from "../../pages/testing/auth2Flow/onboardingContextClientTwo";
import { truncateAddress } from "../../utils/helpers/formattingHelpers";
import solScanIcon from "../../assets/icons/solscan.png";
import shyft from "../../assets/icons/shyft.png";
import {
  Bookmark,
  BookmarkCheck,
  Briefcase,
  Crown,
  Eye,
  Send,
  Share,
  XCircle,
  History,
} from "lucide-react";
import kidoUFO from "../../assets/icons/kidoUFO.png";
import {
  PrimaryButton,
  SmallPrimaryButtonWithDisable,
  SmallSecondaryButtonWithDisable,
} from "../../components/elements/primaryButton";
import { useWallDisplayContext } from "../../utils/contexts/wallDisplayContext";
import SingleCellForWall from "../../pages/inApp/yourWall/singleCellForWall";
import SingleCellForBrickDisplay from "../../pages/inApp/yourBricks/singleCellForBrickDisplay";
import { addBookmark } from "../../utils/helpers/ourAPIHelpers";
import CellStatsContainer, {
  WallOwnersRow,
} from "../../pages/inApp/yourOrbit/components/modal/CellStatsContainer";
import FloatingUFOWifFriends from "../placeholders/floatingUFOContainer";
import CheckoutModalDisplay from "../checkoutDisplay/checkoutModalDisplay";
//------------------------------------
export default function CellModalInner({ closeModal }) {
  const { currentUser } = useAuthenticationClientContextTwo();
  const {
    selectedCell,
    displayedUser,
    addToShoppingCart,
    setSelectedDisplayOption,
    selectedDisplayOption,
  } = useWallDisplayContext();
  const navigate = useNavigate();
  const [cellSize, setCellSize] = useState(calculateCellSize());

  function calculateCellSize() {
    // Example calculation (you can adjust the logic based on your requirements)
    const size = Math.min(window.innerWidth, window.innerHeight) * 0.65; // 60% of the smaller dimension
    return size;
  }

  useEffect(() => {
    const handleResize = () => {
      setCellSize(calculateCellSize());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  if (!selectedCell) {
    return null; // or render a placeholder/alternative UI
  }
  function buttonText() {
    if (selectedCell.is_enabled && selectedCell.owner_id != currentUser.id) {
      return "Make Offer";
    } else if (!selectedCell.is_enabled) {
      return "Purchase";
    } else {
      return "Edit Cell";
    }
  }
  // console.log(displayedUser);
  // console.log(selectedCell);
  function buttonAction() {
    if (selectedCell.is_enabled && selectedCell.owner_id != currentUser.id) {
      return "Make Offer";
    } else if (!selectedCell.is_enabled) {
      addToShoppingCart(selectedCell);
      setSelectedDisplayOption("checkout");
    } else {
      navigate(`/${selectedCell.user_name}/${selectedCell.cell_id}/edit`);
    }
  }
  const renderCellContent = () => {
    const isOwnerWithoutLatestData =
      currentUser.id === selectedCell.owner_id &&
      !selectedCell.latest_version_data;
    const isCellDisabled = !selectedCell.is_enabled;
    const shouldShowUFO = isOwnerWithoutLatestData || isCellDisabled;

    if (shouldShowUFO) {
      return (
        <div style={{ maxWidth: `${cellSize}px`, height: `${cellSize}px` }}>
          <FloatingUFOWifFriends cellSize={cellSize} />
        </div>
      );
    } else {
      return (
        <SingleCellForBrickDisplay
          key={selectedCell.cell_id}
          cellData={selectedCell.latest_version_data}
          size={cellSize}
          SCALE_FACTOR={cellSize / 800}
        />
      );
    }
  };

  const preCheckoutView = (
    <>
      <CellInfoContainer currentUser={currentUser} cell={selectedCell} />
      <TxHistoryContainer cell={selectedCell} />
      <WallOwnersRow currentUser={currentUser} selectedCell={selectedCell} />
      <CellStatsContainer selectedCell={selectedCell} />
      <CellIconButtonRow cell={selectedCell} />
      <PrimaryButton
        title={buttonText()}
        color={"ourPurple"}
        action={() => buttonAction()}
      />
    </>
  );
  return (
    <div className="w-full h-full relative">
      <button onClick={closeModal} className="absolute -left-2 -top-2 z-20">
        <XCircle className="stroke-red-600 fill-red-300 opacity-40 hover:opacity-100 shadow-sm hover:cursor-pointer hover:scale-110 transition duration-300 ease" />
      </button>
      <div className="flex w-full h-full">
        <div
          className={`flex bg-white shadow-md border border-1  border-gray-400 border-opacity-10 h-full w-[${cellSize}] rounded-lg items-center justify-center overflow-hidden`}
        >
          {renderCellContent()}
        </div>
        <div className="pl-2 flex flex-col flex-grow w-1/3 h-full gap-2">
          {selectedDisplayOption == "checkout" ? (
            <CheckoutModalDisplay />
          ) : (
            preCheckoutView
          )}
          {/* */}
        </div>
      </div>
    </div>
  );
}

function InfoRow({ icon: Icon, text, rightContent }) {
  return (
    <span className="w-full flex justify-between items-center group">
      <span className="flex items-center gap-1">
        {Icon && (
          <Icon
            size={16}
            className="stroke-gray-400 group-hover:rotate-[15deg]"
          />
        )}
        <p className="text-sm text-gray-400">{text}</p>
      </span>
      {rightContent && (
        <span className="flex gap-1 items-center">{rightContent}</span>
      )}
    </span>
  );
}
export function CellInfoContainer({ currentUser, cell }) {
  // if (currentUser.id == cell.owner_id) return <></>;
  if (!cell.pfp_url && !cell.latest_version_data && cell.no_of_bookmarks <= 0)
    return <></>;
  return (
    <div className="flex flex-col  rounded-[10px] px-4 py-1.5 bg-gradient-to-b from-white to-stone-200/20 shadow-[0_1px_3px_rgba(0,0,0,0.2)]">
      {/*   w-full"> bg-gradient-to-b from-white to-stone-200/40 shadow-[0_1px_3px_rgba(0,0,0,0.2)] */}
      {cell.pfp_url && (
        <InfoRow
          icon={Crown}
          text={"Owner"}
          rightContent={
            <>
              <img
                src={cell.pfp_url}
                alt="user pfp"
                className="h-4 w-4 rounded-full "
              />
              <p className="text-md text-gray-600 font-regular">
                {cell.user_name}
              </p>
            </>
          }
        />
      )}
      {/* <InfoRow
        icon={Eye}
        text="Views"
        rightContent={<p className="text-gray-600">{cell.views}</p>}
      /> */}
      {cell.latest_version_number && (
        <InfoRow
          icon={History}
          text="Version Number"
          rightContent={
            <p className="text-gray-600">{cell.latest_version_number}</p>
          }
        />
      )}
      {cell.no_of_bookmarks > 0 && (
        <InfoRow
          icon={Bookmark}
          text="Bookmarks"
          rightContent={<p className="text-gray-600">{cell.no_of_bookmarks}</p>}
        />
      )}
    </div>
  );
}

export function CellIconButtonRow({ cell }) {
  const { currentUser, verifyAndFetchUser } =
    useAuthenticationClientContextTwo();
  const [isBookmarked, setIsBookmarked] = useState(false);

  useEffect(() => {
    const bookmarked = currentUser.bookmarked_cells?.includes(cell.cell_id);
    setIsBookmarked(bookmarked);
  }, [currentUser, cell.cell_id]);

  const handleBookmarkClick = async () => {
    try {
      await addBookmark({ cell_id: cell.cell_id, user_id: currentUser.id });
      setIsBookmarked(true);
      verifyAndFetchUser();
      console.log("Bookmark added");
    } catch (error) {
      console.error("Error bookmarking cell:", error);
    }
  };

  return (
    <span className="flex w-full items-center ">
      <span className="flex w-full items-center justify-between  px-2">
        <p className="px-2 py-1 bg-gray-200 rounded-lg text-gray-500 text-sm  h-min">
          {cell.x_position}
          {cell.y_position}
        </p>
        {cell.is_enabled && (
          <a
            href={`https://translator.shyft.to/address/${cell.tx_id}?cluster=devnet&compressed=true`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={shyft}
              alt="cardano scan"
              className="h-[20px] w-[20px] hover:scale-110 transition duration-300 ease hover:cursor-pointer"
            />
          </a>
        )}
        <button onClick={handleBookmarkClick}>
          {isBookmarked ? (
            <BookmarkCheck
              size={20}
              className="stroke-ourTeal-900 fill-ourTeal-600  hover:fill-ourTeal-700 hover:scale-110 transition duration-300 ease hover:cursor-pointer"
            />
          ) : (
            <Bookmark
              size={20}
              className="stroke-ourTeal-700 fill-ourTeal-500 hover:stroke-ourTeal-900 hover:fill-ourTeal-700 hover:scale-110 transition duration-300 ease hover:cursor-pointer"
            />
          )}
        </button>
        <Send
          size={20}
          className="stroke-ourTeal-700 fill-ourTeal-500 hover:stroke-ourTeal-900 hover:fill-ourTeal-700 hover:scale-110 transition duration-300 ease hover:cursor-pointer"
        />
      </span>
    </span>
  );
}

export function TxHistoryContainer({ cell }) {
  return (
    <div className="flex flex-grow flex-col gap-1 h-full">
      <ButtonGroupForDisplayOptions />
      <div className="flex flex-grow items-center justify-center flex-col bg-white h-full rounded-xl px-4 py-2 shadow-md border border-1 border-gray-400 border-opacity-10 gap-2">
        <img src={kidoUFO} alt="a UFO stealing your cardano" className="h-32" />
        <p className="text-gray-400 text-regular">No Trade History Found</p>
      </div>
    </div>
  );
}

{
  /* <div className="bg-[radial-gradient(ellipse_at_right,_var(--tw-gradient-stops))] from-dMode-100/60 via-dMode-200 to-dMode-300   rounded-xl p-[0.05rem]"> */
}

function ButtonGroupForDisplayOptions() {
  const buttonClass =
    "flex justify-center px-2 w-1/3 py-1 text-sm font-medium border border-transparent  hover:bg-ourTeal-400 focus:z-10 focus:outline-none  focus:bg-ourTeal-600 ";
  const activeClass =
    "bg-ourTeal-600 text-ourTeal-900 font-bold border-ourTeal-600";
  const inactiveClass =
    "bg-transparent text-gray-900 hover:text-gray-800 opacity-50";

  const [selectedDisplayOption, setSelectedDisplayOption] = useState("Trades");

  function handleButtonSelection(option) {
    return () => setSelectedDisplayOption(option);
  }
  return (
    <div
      className="flex rounded-[11px] items-center  justify-center w-full p-1 border border-2 border-ourTeal-500/40  "
      role="group"
    >
      <button
        type="button"
        className={`${buttonClass} ${
          selectedDisplayOption === "Trades" ? activeClass : inactiveClass
        } rounded-l-[8px]`}
        onClick={handleButtonSelection("Trades")}
      >
        Trades
      </button>
      <button
        type="button"
        className={`${buttonClass} ${
          selectedDisplayOption === "Versions" ? activeClass : inactiveClass
        } border-l-0 border-r-0`}
        onClick={handleButtonSelection("Versions")}
      >
        Versions
      </button>
      <button
        type="button"
        className={`${buttonClass} ${
          selectedDisplayOption === "Owner" ? activeClass : inactiveClass
        } rounded-r-md`}
        onClick={handleButtonSelection("Owner")}
      >
        Owner
      </button>
    </div>
  );
}
