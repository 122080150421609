import React, { useEffect, useState } from "react";

import { useAuthenticationClientContextTwo } from "../../testing/auth2Flow/onboardingContextClientTwo";

import BaseWallWrapper from "../../../components/modularWall/baseWallWrapper";

export default function YourWallIndex() {
  const { currentUser } = useAuthenticationClientContextTwo();

  return (
    <div className="w-full h-full">
      <BaseWallWrapper userId={currentUser.id} />
    </div>
  );
}
