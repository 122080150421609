import React, { useEffect, useState } from "react";
import {
  ArrowLeftCircle,
  ArrowRightCircle,
  ChevronRight,
  Fullscreen,
  XCircle,
} from "lucide-react";
import { useWallDisplayContext } from "../../../../utils/contexts/wallDisplayContext";
import { useAuthenticationClientContextTwo } from "../../../testing/auth2Flow/onboardingContextClientTwo";

import { OtherUserOrbitBar } from "../../../../components/modularWall/otherUserOrbitHelperbar";
import SimpleOrbitContainer from "../../../../components/modularOrbit/simpleOrbitContainer";
import "./orbModalStyle.css";
import OrbModalWallDisplay from "./modal/orbModalWallDisplay.js";

// ---------------------------------------------------------------------------

export function UserWallModal({ displayedUser, hideModal }) {
  const { currentUser } = useAuthenticationClientContextTwo();
  const {
    selectedCell,
    setSelectedCell,
    setDisplayedUser,
    selectedDisplayOption,
    setSelectedDisplayOption,
    setShowMarketHelper,
  } = useWallDisplayContext();
  const [showSidebar, setShowSidebar] = useState(false);

  function handleHideSidebar() {
    setShowSidebar(false);
    setSelectedCell(null);
    if (selectedDisplayOption == "checkout") {
      setSelectedDisplayOption("wall");
      setShowMarketHelper(false);
    }
  }

  useEffect(() => {
    setDisplayedUser(displayedUser);
    console.log(displayedUser);
  }, [displayedUser]);

  const helperBar = (
    <div className="absolute top-0 left-0 flex w-min h-min gap-2 px-4 py-4 z-50">
      <button onClick={hideModal}>
        <XCircle className="stroke-red-500 fill-red-300 hover:stroke-red-700 hover:fill-red-400 hover:scale-110 transition duration-300 ease hover:cursor-pointer opacity-60 hover:opacity-100" />
      </button>
      {selectedDisplayOption === "checkout" && (
        <button onClick={() => setSelectedDisplayOption("wall")}>
          <ArrowLeftCircle className="stroke-ourGold-800 fill-ourGold-300 hover:stroke-ourGold-900 hover:fill-ourGold-400 hover:scale-110 transition duration-300 ease hover:cursor-pointer opacity-80 hover:opacity-100" />
        </button>
      )}
      <Fullscreen className="stroke-ourGreen-500 fill-ourGreen-300 hover:stroke-ourGreen-700 hover:fill-ourGreen-400 hover:scale-110 transition duration-300 ease hover:cursor-pointer opacity-60 hover:opacity-100" />
    </div>
  );
  function renderDisplayOption() {
    if (["wall", "checkout"].includes(selectedDisplayOption)) {
      return (
        <OrbModalWallDisplay
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          handleHideSidebar={handleHideSidebar}
          displayedUser={displayedUser}
          currentUser={currentUser}
        />
      );
    } else if (selectedDisplayOption === "orbit") {
      return (
        <SimpleOrbitContainer
          userId={displayedUser.friend_id}
          displayedUser={displayedUser}
        />
      );
    }
    return null;
  }

  return (
    <div className="flex flex-col w-full h-full items-center justify-center bg-ourPurple-500">
      <div className="flex bg-insetPrimary w-full h-full rounded-3xl shadow-xl overflow-hidden relative">
        {helperBar}
        <div className="flex w-full h-full bg-ourPurple-400">
          {renderDisplayOption()}
          {selectedDisplayOption != "checkout" && (
            <OtherUserOrbitBar displayedUser={displayedUser} />
          )}
        </div>
      </div>
    </div>
  );
}
