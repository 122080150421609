import React, { useState, useEffect } from "react";

import { useNavigate, Navigate } from "react-router-dom";
import { PrimaryButton } from "../../components/elements/primaryButton";
import { motion } from "framer-motion";

import { ArrowRight, Check } from "lucide-react";
import "./landingPage.css";
import kidoLogo from "../../assets/icons/kido.svg";
import FlutedGlass from "../authentication/flutedGlass";
import SkyAndDatBoiContainer from "./skyAndDayBoiContainer";
import FloatingUFOWifFriends from "../../components/placeholders/floatingUFOContainer";
import "../../components/placeholders/ufoStyles.css";
import {
  KidoK,
  KidoI,
  KidoD,
  KidoO,
  Connect,
  Create,
  Trade,
} from "../../assets/icons/logoLetters/logoLettersExport";
import sky from "../../assets/landingPageImages/sky.png";
import palmTree from "../../assets/landingPageImages/palmTree.png";
import ufoWifFriends from "../../assets/icons/ufoWifFriends.png";
import preRichCar from "../../assets/landingPageImages/prerichcar.png";
import tolyAstro from "../../assets/landingPageImages/tolyAstro.png";
import frame from "../../assets/landingPageImages/frame.png";
import hatNoDog from "../../assets/landingPageImages/hatNoDog.png";

import bonk from "../../assets/landingPageImages/BONK.png";

export default function LandingPage() {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth > 1441 || window.innerHeight > 901);
    };

    // Check on initial mount
    checkScreenSize();

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenSize);

    // Cleanup event listener
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  const ufoWifFriend = (
    <div className="w-full h-full">
      <motion.div
        className="landing-ufo-float relative  top-2/3 left-2/3 transform translate-x-1/2 -translate-y-1/2 z-50"
        transition={{
          duration: 2,
          ease: "easeInOut",
        }}
      >
        <img src={ufoWifFriends} alt="UFO with friends" className="" />
        {/* <img src={bonk} alt="UFO with friends" className="h-6 w-6 " /> */}
        {/* <img
          src={bonk}
          alt="UFO with friends"
          className="h-6 w-6 translate-x-4 translate-y-2 rotate-[12deg]"
        /> */}
      </motion.div>
    </div>
  );

  const palmTrees = (
    <>
      <img
        src={palmTree}
        alt="palm trees briv"
        className="absolute h-[44rem] top-56 left-1/4 transform -translate-x-2/3 translate-y-0 z-20"
      />
      <img
        src={palmTree}
        alt="palm trees briv"
        className="absolute h-[44rem] top-56 right-1/4 transform translate-x-2/3 scale-x-[-1] z-20"
      />
    </>
  );

  const CTA_section = (
    <>
      <Create className="h-[4rem] w-[20rem] items-start absolute left-[33.5rem] top-[25rem]  fill-ourTeal-900 hover:scale-110 transition duration-700 ease z-40" />
      <Connect className="h-[4rem] max-w-[20rem]  items-start absolute left-[32rem]  top-[30rem]   fill-ourTeal-900 hover:scale-110 transition duration-700 ease z-40" />
      <Trade className="h-[4rem] w-[20rem] absolute  left-[33.5rem]  items-start top-[35rem]  transform  transition duration-400 ease  fill-ourTeal-900 hover:scale-110 transition duration-700 ease z-40" />
    </>
  );
  return (
    <div className="w-screen h-screen bg-gradient-to-b from-[#709FD8] via-[#CFE8FF] to-white overflow-clipped">
      <div
        className="w-full h-full max-w-[1441px] max-h-[901px] overflow-hidden fixed   rounded-xl"
        style={{
          backgroundImage: `url(${sky})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          margin: isLargeScreen ? "auto" : "0", // Center the div if isLargeScreen is true
          position: isLargeScreen ? "absolute" : "fixed", // Use absolute positioning to allow centering with margin auto
          top: isLargeScreen ? "50%" : "0",
          left: isLargeScreen ? "50%" : "0",
          transform: isLargeScreen ? "translate(-50%, -50%)" : "none", // Adjust the transform to center the div
        }}
      >
        <div className="relative w-full h-full">
          {ufoWifFriend}
          {palmTrees}
          <LogoSpelling />
          {CTA_section}
          <img
            src={preRichCar}
            alt="palm trees briv"
            className="absolute h-[5rem] bottom-0 right-1/4 transform translate-x-1/2 scale-x-[-1] "
          />
          <img
            src={tolyAstro}
            alt="palm trees briv"
            className="absolute h-[12rem] top-0 right-1/4 transform  toly-float"
          />
          <img
            src={hatNoDog}
            alt="palm trees briv"
            className="absolute h-24 w-[7rem] top-0 left-[25rem] transform  z-30 rotate-[-36deg]"
          />
        </div>
      </div>
    </div>
  );
}

function LogoSpelling() {
  return (
    <div className="absolute w-11/12 h-full top-56 left-12 flex justify-between items-start opacity-90">
      <KidoK className="h-[12rem] transform scale-110 transition duration-400 ease rotate-[-12deg] fill-ourTeal-900 hover:scale-110 transition duration-700 ease" />
      <KidoI className="h-[12rem] transform scale-110 transition duration-400 ease rotate-[-5deg] z-30 fill-ourTeal-900" />
      <KidoD className="h-[12rem] transform scale-110 transition duration-400 ease rotate-[12deg] z-30   fill-ourTeal-900" />
      <KidoO className="h-[12rem] transform scale-110 transition duration-400 ease rotate-[-12deg]  z-30 fill-ourTeal-900" />
    </div>
  );
}
// RBKDoFW7FVabAXoQQkJ93XQx8SxLZAzuxfBZJQ8qHqo
// HXky4o5oMhDxNNzYPUuey2TMQxhpPL2ck5GrBoHKZZ37
// GJRs4FwHtemZ5ZE9x3FNvJ8TMwitKTh21yxdRPqn7npE
// 9dhoieCDdX3qawKK283vtcLmKHnvnMm3VQTKybyBcSdd
