import React, { useMemo, useState, useEffect } from "react";
import {
  useWallDisplayContext,
  WallDisplayProvider,
} from "../../utils/contexts/wallDisplayContext";
import WallComponent, { WallComponentSimplified } from "./baseWallComponent";

export default function BaseWallWrapper({ userId }) {
  return (
    <WallDisplayProvider>
      <WallComponent userId={userId} isOtherUserWall={false} />
    </WallDisplayProvider>
  );
}
