// export function PrimaryInputWithErrorState({ value, showError }) {
//   const errorRingColor = showError ? "ring-red-500" : "ring-ourTeal-800";
//   return (
//     <input
//       type="text"
//       placeholder="Referral Code"
//       value={value}
//       onChange={(e) => setValue(e.target.value)}
//       className={`w-full text-gray-600 font-sans p-2 text-md rounded-lg border border-1 border-borderPrimary shadow-md focus:ring-2 focus:${errorRingColor} focus:outline-none`}
//     />
//   );
// }
export function PrimaryInputWithErrorStateAndOnChange({
  value,
  onChange,
  showError,
  placeholder,
}) {
  const errorRingColor = showError ? "ring-red-500" : "ring-ourTeal-800";
  return (
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={`w-full text-gray-600 font-sans p-2 text-md rounded-xl border border-1 border-borderPrimary shadow-md focus:ring-2 focus:ring-ourTeal-800 focus:outline-none`}
    />
  );
}
export function PrimaryInputWithErrorStateAndOnChangeAndIcon({
  value,
  onChange,
  showError,
  placeholder,
  icon,
}) {
  const errorRingColor = showError ? "ring-red-500" : "ring-ourTeal-800";
  return (
    <div
      className={`flex w-full items-center justify-center bg-white group text-gray-600 font-sans px-4 py-2 gap-2 text-md rounded-xl border border-1 border-borderPrimary shadow-md focus-within:ring-2 focus-within:ring-ourTeal-800 focus-within:outline-none`}
    >
      {icon}
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className="w-full focus:ring-0 focus:outline-none"
      />
    </div>
  );
}
