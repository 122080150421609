import React, { useState, useEffect, useCallback } from "react";
import { useAuthenticationClientContextTwo } from "./auth2Flow/onboardingContextClientTwo";
import ProfileHeader from "./yourBricksComponents/profileHeader";
import { ProfileTabBarRow } from "./yourBricksComponents/profileTabBarRow";
import { ChevronLeft, Circle } from "lucide-react";
import FlutedGlass from "../authentication/flutedGlass";
import WaitlistBackgroundCanvas from "../authentication/elements/waitlistBackgroundCanvas";
import NeumorphicButton from "../../components/elements/neumorphicButton";
import { requestNotificationPermission } from "../../utils/hooks/useDesktopNotifications";
import LandingPage from "../landingPage/landingPage";
import ClaimGiftedCellsView from "./auth2Flow/claimGiftedCells";
export function WaitlistPage() {
  const [notificationEnabled, setNotificationEnabled] = useState(false);

  const handleNotificationRequest = () => {
    requestNotificationPermission((enabled) => {
      setNotificationEnabled(enabled);
      if (enabled) {
        console.log("Notifications enabled");
      } else {
        console.log("Notifications not enabled or denied");
      }
    });
  };

  return (
    <div className="w-full h-full ">
      <FlutedGlass>
        <div className="flex flex-col w-full items-center p-2">
          <h1 className="font-bold text-4xl">Invite Friends to Join!</h1>
          <span className="flex flex-col items-center">
            <h1 className="font-bold text-8xl">20,003</h1>
            <h1 className="font-regular text-2xl text-gray-500">
              Your Waitlist Position
            </h1>
          </span>
          <span className="flex w-1/2 justify-between">
            <h1>Your Referral Code</h1>
            <h1>223</h1>
          </span>
          <p>Invite three friends and skip the line!</p>
          <NeumorphicButton
            text={"Allow Notifications"}
            action={handleNotificationRequest}
          />
        </div>
      </FlutedGlass>
    </div>
  );
}

export default function TestingIndex() {
  const { currentUser } = useAuthenticationClientContextTwo();

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="h-3/5 w-1/2">
        <ClaimGiftedCellsView />
      </div>
    </div>
  );
}
