export function truncateAddress(address) {
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 6
  )}`;
}
export function timeSince(timestamp) {
  const now = new Date();
  const past = new Date(timestamp);
  const secondsPast = Math.floor((now - past) / 1000);

  if (secondsPast < 60) {
    return `${secondsPast}s ago`;
  } else if (secondsPast < 3600) {
    return `${Math.floor(secondsPast / 60)}m ago`;
  } else if (secondsPast < 86400) {
    return `${Math.floor(secondsPast / 3600)}h ago`;
  } else {
    return `${Math.floor(secondsPast / 86400)}d ago`;
  }
}
