import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Buffer } from "buffer";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";

import {
  SolflareWalletAdapter,
  SolletWalletAdapter,
  CoinbaseWalletAdapter,
} from "@solana/wallet-adapter-wallets";

import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { clusterApiUrl } from "@solana/web3.js";
import { AuthenticationClientProviderTwo } from "./pages/testing/auth2Flow/onboardingContextClientTwo";
import { WallDisplayProvider } from "./utils/contexts/wallDisplayContext";

import App from "./App";
global.Buffer = global.Buffer || require("buffer").Buffer;
const root = ReactDOM.createRoot(document.getElementById("root"));
const network = WalletAdapterNetwork.Devnet; // Change to 'Mainnet' or 'Testnet' as needed
const endpoint = clusterApiUrl(network);
const heliusRpcUrl = `https://rpc-proxy.huntertdiamond.workers.dev`;

const wallets = [new SolflareWalletAdapter(), new CoinbaseWalletAdapter()];

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConnectionProvider endpoint={heliusRpcUrl}>
        <WalletProvider wallets={wallets} autoConnect>
          <AuthenticationClientProviderTwo>
            <WallDisplayProvider>
              <WalletModalProvider>
                <App />
              </WalletModalProvider>
            </WallDisplayProvider>
          </AuthenticationClientProviderTwo>
        </WalletProvider>
      </ConnectionProvider>
    </BrowserRouter>
  </React.StrictMode>
);
