import { motion } from "framer-motion";

export default function LoadingDots() {
  return (
    <motion.div
      className="flex space-x-2 justify-center items-center"
      transition={{
        duration: 2,
        ease: "easeInOut",
      }}
    >
      <div className="h-8 w-8 bg-ourTeal-900 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
      <div className="h-8 w-8 bg-ourTeal-900 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
      <div className="h-8 w-8 bg-ourTeal-900 rounded-full animate-bounce"></div>
    </motion.div>
  );
}
