export function SendForwardIcon({ className }) {
  return (
    <svg
      width="176"
      height="176"
      viewBox="0 0 176 176"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      fill="currentColor" // Set fill to currentColor
      className={className} // Apply className prop
    >
      <rect
        x="176"
        y="176"
        width="124.644"
        height="126.182"
        rx="16"
        transform="rotate(180 176 176)"
        // fill="#757575"
      />
      <path
        d="M46.2788 127.182V109.6H19.8238V18.0657H107.329V44.957H125.644V17C125.644 8.16348 118.48 1.00003 109.644 1.00003H17C8.16344 1.00003 0.999988 8.16347 0.999988 17V111.182C0.999988 120.019 8.16343 127.182 17 127.182H46.2788Z"
        // fill="#757575"
        // stroke="#757575"
      />
    </svg>
  );
}
