import React from "react";
import "./flutedGlass.css"; // Assuming you have extracted the styles into this file

const FlutedGlass = ({ type = "fluted", children }) => {
  return (
    <div className="relative w-full h-full rounded-2xl  transition-all duration-75 ease-in ">
      <div className="h-full w-full backdrop-blur-lg rounded-2xl overflow-hidden bg-stone-200/10 shadow-xl"></div>

      <div
        className={`grooves-v absolute h-full w-full top-0 left-0 right-0 bottom-0 rounded-2xl overflow-hidden bg-zinc-400/10 backdrop-blur-xl`}
      ></div>
      {type === "cross" && (
        <div className="grooves-h absolute h-full w-full top-0 left-0 right-0 bottom-0 rounded-2xl overflow-hidden bg-stone-400/10 backdrop-blur-xl"></div>
      )}

      <div className="diffusion-v absolute h-full w-full top-0 left-0 right-0 bottom-0 rounded-2xl overflow-hidden bg-indigo-300/10"></div>

      {/* This div should be the container for the children */}
      <div className="absolute h-full w-full top-0 left-0 right-0 bottom-0 rounded-2xl  border-[1px] border-stone-300/20 z-20 flex justify-center items-center">
        {children} {/* Render children here */}
      </div>
    </div>
  );
};

export default FlutedGlass;

export const SmallShadowFlutedGlass = ({ type = "fluted", children }) => {
  return (
    <div className="relative w-full h-full rounded-2xl  transition-all duration-75 ease-in ">
      <div className="h-full w-full backdrop-blur-lg rounded-2xl overflow-hidden bg-stone-200/10 shadow-md"></div>

      <div
        className={`grooves-v absolute h-full w-full top-0 left-0 right-0 bottom-0 rounded-2xl overflow-hidden bg-zinc-400/10 backdrop-blur-xl`}
      ></div>
      {type === "cross" && (
        <div className="grooves-h absolute h-full w-full top-0 left-0 right-0 bottom-0 rounded-2xl overflow-hidden bg-stone-400/10 backdrop-blur-xl"></div>
      )}

      <div className="diffusion-v absolute h-full w-full top-0 left-0 right-0 bottom-0 rounded-2xl overflow-hidden bg-indigo-300/10"></div>

      {/* This div should be the container for the children */}
      <div className="absolute h-full w-full top-0 left-0 right-0 bottom-0 rounded-2xl  border-[1px] border-stone-300/20 z-20 flex justify-center items-center">
        {children} {/* Render children here */}
      </div>
    </div>
  );
};
