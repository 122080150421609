import OnboardingBackgroundCanvas from "../authentication/elements/onboardingBackgroundCanvas";
import FlutedGlass from "../authentication/flutedGlass";
import "./testing.css";
export default function WaitlistPage() {
  return (
    <div className="onboarding-overlay">
      <FlutedGlass>
        <div className="flex flex-col w-full items-center">
          <h1 className="font-bold text-4xl">Invite Friends to Join!</h1>
          <span className="flex flex-col items-center">
            <h1 className="font-bold text-8xl">20,003</h1>
            <h1 className="font-regular text-2xl text-gray-500">
              Your Waitlist Position
            </h1>
          </span>
          <span className="flex w-1/2 justify-between">
            <h1>Your Referral Code</h1>
            <h1>223</h1>
          </span>
          <p>Invite three friends and skip the line!</p>
        </div>
      </FlutedGlass>
    </div>
  );
}
