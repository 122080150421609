import React, { useState, useEffect } from "react";
import {
  ArrowDown,
  ArrowUp,
  Search,
  BrickWall,
  Palette,
  Eye,
} from "lucide-react";
import { searchForUser } from "../../../utils/helpers/ourAPIHelpers";
import { debounce } from "lodash";
import Modal from "react-modal";
import { Navigate, useNavigate } from "react-router-dom";

import MarketFooter from "./components/marketFooter";
import RecentTradesTable from "./components/recentTradesTable";
import TrendingUsersTable from "./components/trendingUserTable";
import "./marketStyles.css";
import { PrimaryInputWithErrorStateAndOnChangeAndIcon } from "../../../components/elements/primaryInput";
import { truncateAddress } from "../../../utils/helpers/formattingHelpers";
import { SearchModalInner } from "./components/userSearchModal";
import kidoUFO from "../../../assets/icons/kidoUFO.png";
export default function AuthenticatedMarket() {
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const debouncedSearch = debounce(async () => {
      if (searchTerm) {
        const results = await searchForUser(searchTerm);
        setSearchResults(results);
      }
    }, 1000);

    debouncedSearch();

    return () => debouncedSearch.cancel();
  }, [searchTerm]);

  const handleSearchClick = () => {
    setShowSearchModal(true);
  };

  const searchModal = (
    <Modal
      isOpen={showSearchModal}
      onRequestClose={() => setShowSearchModal(false)}
      contentLabel="Search Modal"
      className={
        "w-1/2 bg-ourBlue-500 p-4 justify-center items-center rounded-2xl h-1/2 modal-center"
      }
      overlayClassName="overlay-style"
    >
      <SearchModalInner
        isOpen={showSearchModal}
        onClose={() => setShowSearchModal(false)}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchResults={searchResults}
      />
    </Modal>
  );
  return (
    <div className="flex w-full h-full  flex-col rounded-3xl space-y-2">
      <div
        className="flex space-x-2 placeholderSearchBar w-full items-center  bg-white shadow-sm rounded-xl py-2 px-4 hover:cursor-pointer"
        onClick={handleSearchClick}
      >
        <Search size={18} className="opacity-60" />
        <p className="text-lg font-medium opacity-60">Search</p>
      </div>
      {searchModal}
      <div className="flex h-1/2 w-full gap-2">
        <div className="w-1/3 h-full">
          <div className="flex flex-col h-full ">
            <div className="flex h-full bg-ourPurple-500/60 p-4 rounded-xl">
              <div className="flex w-full flex-col gap-2 items-center justify-center">
                <img src={kidoUFO} alt="cardano UFO" className="h-36 w-36" />
                <p className=" text-lg text-center text-grayscale-600">
                  Your Friends haven't made any purchases.
                  <br /> Thats a shame...
                </p>
              </div>
            </div>
          </div>
        </div>
        <TrendingCellDisplay />
      </div>
      <div className="flex h-1/2 w-full gap-2">
        <div className=" flex flex-col w-3/4 bg-white p-2 shadow-sm rounded-xl">
          <RecentTradesTable />
        </div>
        <div className=" flex flex-col w-1/4">
          <TrendingUsersTable />
        </div>
      </div>
      <MarketFooter />
    </div>
  );
}
function TrendingCellDisplay() {
  return (
    <div className="flex flex-col w-2/3 bg-white rounded-xl shadow-md p-2  gap-1">
      <h1 className="text-xl font-semibold text-grayscale-800">
        Trending Cells
      </h1>
      <div className="flex w-full h-full gap-2">
        <SingleCellOnDisplay />
        <SingleCellOnDisplay />
        <SingleCellOnDisplay />
      </div>
      <div className="flex justify-center gap-2 w-full">
        <span className="w-2 h-2 bg-ourPurple-600 rounded-full"></span>
        <span className="w-2 h-2 bg-ourPurple-500 rounded-full"></span>
        <span className="w-2 h-2 bg-ourPurple-500 rounded-full"></span>
      </div>
    </div>
  );
}
function SingleCellOnDisplay() {
  return (
    <div className=" flex flex-col w-1/3 h-full ">
      <div className="flex flex-grow h-full bg-ourPurple-500/60 rounded-xl"></div>
      <div className=" flex  w-full rounded-lg p-2 justify-between  gap-1">
        <div className="flex w-full gap-1 group transition-all duration-500 ease items-center">
          <BrickWall
            size={18}
            className="stroke-grayscale-300 group-hover:stroke-grayscale-400 transition-all duration-500 ease group-hover:scale-105"
          />
          <p className="text-base text-grayscale-600 font-semibold">hunter</p>
        </div>
        <div className="flex w-full gap-1 group transition-all duration-500 ease items-center">
          <Palette
            className="stroke-grayscale-300 group-hover:stroke-grayscale-400 transition-all duration-500 ease group-hover:scale-105"
            size={18}
          />
          <p className="text-base text-grayscale-600 font-semibold">hunter</p>
        </div>
        <div className="flex w-full gap-1 group transition-all duration-500 ease items-center">
          <Eye
            className="stroke-grayscale-300 group-hover:stroke-grayscale-400 transition-all duration-500 ease group-hover:scale-105"
            size={18}
          />
          <p className="text-base text-grayscale-600 font-semibold">340k</p>
        </div>
      </div>
    </div>
  );
}
