import React, { useState, useEffect, useCallback } from "react";

import { Clipboard } from "lucide-react";
import { truncateAddress } from "../../../utils/helpers/formattingHelpers";

export default function ProfileHeader({ currentUser }) {
  const [copySuccess, setCopySuccess] = useState("");

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(currentUser.ref_code_to_share)
      .then(() => {
        setCopySuccess("Copied!");
        setTimeout(() => setCopySuccess(""), 5000);
      })
      .catch((err) => console.error("Could not copy text: ", err));
  };

  return (
    <>
      <div
        className="relative w-full h-1/4 p-4 bg-ourPurple-500/40  rounded-2xl flex justify-start "
        style={
          {
            // backgroundColor: " rgb(250, 232, 255)",
            // backgroundImage:
            //   "radial-gradient(at 35% 94%, rgb(209, 213, 219) 0, transparent 92%), radial-gradient(at 9% 57%, rgb(29, 78, 216) 0, transparent 84%), radial-gradient(at 49% 86%, rgb(120, 113, 108) 0, transparent 54%), radial-gradient(at 30% 56%, rgb(136, 19, 55) 0, transparent 33%), radial-gradient(at 47% 60%, rgb(253, 224, 71) 0, transparent 65%), radial-gradient(at 55% 43%, rgb(224, 231, 255) 0, transparent 53%);",
          }
        }
      >
        <img
          src={currentUser.pfp_url}
          alt="your pfp"
          className="h-36 w-36 rounded-full border-4 border-ourPurple-500 absolute bottom-0 translate-y-4"
        />
      </div>
      <div className="flex w-full justify-between">
        <div className="flex flex-col pt-2">
          <p className="text-4xl text-grayscale-800 font-bold">
            {currentUser.user_name}
          </p>
          <p className="text-lg font-regular text-grayscale-500">
            {truncateAddress(currentUser.user_address)}
          </p>
        </div>
        <div className="flex flex-col items-end">
          <p className="text-lg font-regular text-grayscale-500">
            Referral Code:
          </p>
          <span
            className="flex items-center gap-2 p-2 hover:bg-ourBlue-500 rounded-lg cursor-pointer"
            onClick={handleCopyClick}
            title="Click to copy your referral code"
          >
            <Clipboard size={16} />
            <p className="text-sm font-sans font-regular text-grayscale-500">
              {copySuccess || currentUser.ref_code_to_share}
            </p>
          </span>
        </div>
      </div>
    </>
  );
}
