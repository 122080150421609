import { v4 as uuidv4 } from "uuid";

import { storage } from "../firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  extractUuidFromBlobUrl,
  handleSaveCanvasImages,
  handleSaveCanvasText,
  handleSaveCanvasDrawings,
} from "./helpers/canvasSaveHelpers";

class SaveCanvas {
  constructor() {}

  formatCanvasData(serializedData) {
    console.log(serializedData);
    let formattedData = {
      images: [],
      text: [],
      drawing: [],
    };

    serializedData.objects.forEach((obj, index) => {
      let commonAttributes = {
        originX: obj.originX,
        originY: obj.originY,
        left: obj.left,
        top: obj.top,
        width: obj.width,
        height: obj.height,
        scaleX: obj.scaleX,
        scaleY: obj.scaleY,
        angle: obj.angle,
        flipX: obj.flipX,
        flipY: obj.flipY,
        opacity: obj.opacity,
        visible: obj.visible,
        skewX: obj.skewX,
        skewY: obj.skewY,
        scale: 7,
        zIndex: index, // Represents the stacking order
      };

      if (obj.type === "image") {
        const imageId = extractUuidFromBlobUrl(obj.src);

        formattedData.images.push({
          ...commonAttributes,
          imageId: imageId,
          src: obj.src,
          shadow: obj.shadow,
          strokeUniform: obj.strokeUniform,
          strokeMiterLimit: obj.strokeMiterLimit,
          cropX: obj.cropX,
          cropY: obj.cropY,
        });
      } else if (
        obj.type === "i-text" ||
        obj.type === "text" ||
        obj.type === "textbox"
      ) {
        formattedData.text.push({
          ...commonAttributes,
          textId: uuidv4(),
          fill: obj.fill,
          stroke: obj.stroke,
          strokeWidth: obj.strokeWidth,
          fontFamily: obj.fontFamily,
          fontWeight: obj.fontWeight,
          fontSize: obj.fontSize,
          text: obj.text,
          underline: obj.underline,
          overline: obj.overline,
          linethrough: obj.linethrough,
          textAlign: obj.textAlign,
          fontStyle: obj.fontStyle,
          lineHeight: obj.lineHeight,
          textBackgroundColor: obj.textBackgroundColor,
          charSpacing: obj.charSpacing,
          styles: obj.styles,
          direction: obj.direction,
          pathAlign: obj.pathAlign,
          pathSide: obj.pathSide,
        });
      } else if (obj.type === "path") {
        formattedData.drawing.push({
          ...commonAttributes,
          drawingId: uuidv4(),
          fill: obj.fill,
          stroke: obj.stroke,
          strokeWidth: obj.strokeWidth,
          strokeDashArray: obj.strokeDashArray,
          strokeLineCap: obj.strokeLineCap,
          strokeDashOffset: obj.strokeDashOffset,
          strokeLineJoin: obj.strokeLineJoin,
          strokeUniform: obj.strokeUniform,
          strokeMiterLimit: obj.strokeMiterLimit,
          shadow: obj.shadow,
          visible: obj.visible,
          backgroundColor: obj.backgroundColor,
          fillRule: obj.fillRule,
          paintFirst: obj.paintFirst,
          globalCompositeOperation: obj.globalCompositeOperation,
          skewX: obj.skewX,
          skewY: obj.skewY,
          path: obj.path,
        });
      }
    });
    return formattedData;
  }

  async saveImageToStorage(imageData, userId, cellId) {
    const { src, imageId } = imageData;

    try {
      const response = await fetch(src);
      const blob = await response.blob();
      const imageRef = ref(storage, `${userId}/${cellId}/${imageId}`);
      await uploadBytes(imageRef, blob);
      const downloadURL = await getDownloadURL(imageRef);
      console.log("Image uploaded to Firebase Storage:", downloadURL);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image to Firebase Storage:", error);
      throw error;
    }
  }
  async saveToServer(userId, cellId, formattedData) {
    console.log(JSON.stringify(formattedData));
    try {
      const response = await fetch("https://www.api.wallz.social/edit-cell", {
        // Replace with your server's URL
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId,
          cellId,
          formattedData,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        console.log("Cell saved successfully:", result);
      } else {
        console.error("Error saving cell:", result.error);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  }
}

export default SaveCanvas;
