import { useState, useEffect, useRef, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  BrickWall,
  Pen,
  Eraser,
  CircleDot,
  Type,
  ImagePlus,
  Settings2,
  Palette,
  FileImage,
  Image,
  Wallet,
  MousePointer2,
  MousePointer,
  PlusCircle,
  ArrowUpToLine,
  ArrowDownToLine,
} from "lucide-react";
import {
  PrimaryButton,
  PrimaryIconButton,
} from "../../../components/elements/primaryButton";
import FabricCanvas from "../../../components/canvas/fabricCanvasIndex";
import EditingBrickToolbar from "../../../components/canvas/editingToolbar";
import {
  CanvasProvider,
  useCanvasContext,
} from "../../../components/canvas/canvasContext";
import "./toolbarStyling.css";
import OurListItemWithAvatar from "../../../components/elements/listItemWithAvatar";
import { TrailingSidebarForEditingPage } from "../../../components/editingBrick/trailingSidebarForEditingPage";
import { useLocation } from "react-router-dom";
import { BlockPicker, ChromePicker } from "react-color";
import FlutedGlass, {
  SmallShadowFlutedGlass,
} from "../../authentication/flutedGlass";
import { getCellById } from "../../../utils/helpers/ourAPIHelpers";
import SendBackIcon from "../../../assets/icons/sendBack";
import { SendForwardIcon } from "../../../assets/icons/sendForward";

export default function EditingSingleBrick() {
  const { username, cellId } = useParams();
  const canvasRef = useRef(null);
  const location = useLocation();
  const [brick, setBrick] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const brickFromState = location.state?.brick;
    if (brickFromState) {
      console.log("brickFromState");
      setBrick(brickFromState);
      setIsLoading(false);
    } else if (!brick) {
      console.log("fetching brick");
      getCellById(cellId).then((fetchedBrick) => {
        const filteredBrick = fetchedBrick[0];
        setBrick(filteredBrick);
        console.log(filteredBrick);
        setIsLoading(false);
      });
    }
  }, [location.state, brick, cellId]);

  if (isLoading) return <div>loading</div>;
  return (
    <CanvasProvider>
      <div className="flex w-full h-full justify-center space-x-2">
        <div className="w-3/4 h-full flex flex-col items-center justify-center gap-4">
          <div className="rounded-xl overflow-hidden shadow-md border border-1 border-grayscale-300/40">
            <FabricCanvas className="" />
          </div>
          <ToolBarWrapper />
        </div>
        {!isLoading && <TrailingSidebarForEditingPage brick={brick} />}
      </div>
    </CanvasProvider>
  );
}

function ToolBarWrapper() {
  const { isCanvasDisabled } = useCanvasContext();
  if (isCanvasDisabled) {
    return;
  }
  return (
    <div className=" flex flex-col gap-4 items-center w-[800px]">
      <HelperBar />

      <CanvasToolBar />
    </div>
  );
}
// Map modes to corresponding icon components
function CanvasToolBar() {
  const {
    modes,
    selectedMode,
    setSelectedMode,
    toggleMode,
    brushColor,
    setBrushColor,
    handleAddImage,
    imageManager,
  } = useCanvasContext();
  const colors = [
    "#256CF9",
    "#2D8F87",
    "#7066FF",
    "#FE692A",
    "#FEA032",
    "#FF808C",
    "#000",
  ];
  const handleColorChange = (color) => {
    setBrushColor(color.hex);
    console.log(color.hex);

    setSelectedMode(null);
  };
  const handleModeChange = (mode) => {
    if (mode === selectedMode) {
      toggleMode(mode);
    } else {
      setSelectedMode(mode);
    }
  };
  // Function to handle file input for images
  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const { url, uniqueId } = imageManager.prepareImageForCanvas(file);

      handleAddImage(file, url, uniqueId);

      // Reset the input after the file is handled
      e.target.value = null;
    }
  };
  return (
    <div className="relative w-[600px]">
      {selectedMode === "CHANGING_COLOR" && (
        <div className="absolute  top-8 transform translate-x-36 -translate-y-full z-20">
          <BlockPicker
            colors={colors}
            triangle="hide"
            onChangeComplete={handleColorChange}
            color={brushColor}
            className="shadow-lg"
          />
        </div>
      )}
      <div className="flex h-18 bg-white px-6 py-2 gap-4 rounded-2xl justify-between items-center shadow-lg ">
        {modes.map((mode) => (
          <IconButtonForToolbar
            key={mode}
            mode={mode}
            isActive={selectedMode === mode}
            onClick={() => handleModeChange(mode)}
          />
        ))}
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageUpload}
          id="image-upload"
        />
        <label htmlFor="image-upload" className="cursor-pointer">
          <IconButtonForToolbar
            onClick={() => document.getElementById("image-upload").click()}
            mode={"ADDING_IMAGE"}
          />
        </label>
      </div>
    </div>
  );
}

function FontDropDown() {
  const { setSelectedFont, selectedFont } = useCanvasContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const fonts = ["Bungee", "Silkscreen", "Comic Sans MS", "Inter", "Kalam"];

  const handleFontChange = (font) => {
    setSelectedFont(font);
    setIsMenuOpen(false);
  };

  return (
    <div className="relative">
      <button
        className="inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-primary focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-white/30 hover:bg-white/40 hover:text-gray-900  active:text-background h-10 w-42 px-4"
        type="button"
        id="font-dropdown"
        aria-haspopup="menu"
        aria-expanded={isMenuOpen}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {selectedFont}
      </button>
      {isMenuOpen && (
        <div className="absolute bottom-full mb-2 w-32 rounded-xl bg-ourTeal-400 shadow-xl z-10">
          {fonts.map((font) => (
            <button
              key={font}
              className="text-left w-full rounded-lg px-2 py-2 text-sm text-gray-700 hover:bg-ourTeal-500 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              type="button"
              onClick={() => handleFontChange(font)}
            >
              {font}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

function HelperBar() {
  const {
    brushColor,
    setBrushColor,
    brushSize,
    setBrushSize,
    selectedMode,
    sendObjectBackward,
    sendObjectForward,
  } = useCanvasContext();

  const colors = [
    { name: "ourBlue-900", hex: "#256CF9", borderColor: "ourBlue-800" },
    { name: "ourTeal-900", hex: "#2D8F87", borderColor: "ourTeal-800" },
    { name: "ourGreen-900", hex: "#119822", borderColor: "ourGreen-800" },
    { name: "ourPurple-900", hex: "#9C27B0", borderColor: "ourPurple-800" },
    { name: "ourGold-900", hex: "#FFBD51", borderColor: "ourGold-800" },
    { name: "ourOrange-900", hex: "#FE692A", borderColor: "ourOrange-800" },
    { name: "ourPink-900", hex: "#FF808C", borderColor: "ourPink-800" },
    { name: "gray-700", hex: "#9E9E9E", borderColor: "gray-600" },
    { name: "black", hex: "#000", borderColor: "gray-700" },
  ];

  const handleColorSelect = (color) => {
    setBrushColor(color.hex);
    if (colorPickerTimeoutRef.current) {
      clearTimeout(colorPickerTimeoutRef.current); // Clear the previous timeout if it exists
    }
    colorPickerTimeoutRef.current = setTimeout(() => {
      setShowColorPicker(false); // Close the color picker after a delay
    }, 1200); // Delay in ms
  };

  const [showColorPicker, setShowColorPicker] = useState(false);
  const colorPickerTimeoutRef = useRef(null); // Use useRef to keep a mutable reference that doesn't trigger re-renders

  return (
    <div className="flex justify-between  rounded-2xl h-16  w-full items-center">
      <SmallShadowFlutedGlass>
        <div className="flex justify-between  rounded-2xl h-24  w-[700px] items-center p-4">
          <div className="flex gap-2 items-center">
            {colors.map(({ name, hex, borderColor }) => (
              <button
                key={name}
                onClick={() => handleColorSelect({ hex })}
                style={{
                  backgroundColor: hex,
                  borderColor: brushColor === hex ? borderColor : "white",
                  borderWidth: 2,
                  borderRadius: "9999px",
                  height: brushColor === hex ? "1.5rem" : "1rem",
                  width: brushColor === hex ? "1.5rem" : "1rem",
                  opacity: brushColor === hex ? 1 : 0.5,
                }}
                className={`focus:outline-none transition-all duration-300`}
              />
            ))}

            <button onClick={() => setShowColorPicker(!showColorPicker)}>
              <PlusCircle className="hover:bg-white rounded-full" />
            </button>
            {showColorPicker && (
              <div className="z-30 absolute bottom-12 right-64">
                <ChromePicker
                  onChangeComplete={handleColorSelect}
                  color={brushColor}
                />
              </div>
            )}
          </div>
          <input
            type="range"
            min="1"
            max="40"
            value={brushSize}
            className="w-1/3"
            onChange={(e) => setBrushSize(e.target.value)}
          />
          <span className="flex h-10 gap-4 items-center">
            <button onClick={sendObjectBackward}>
              <SendBackIcon
                className={
                  "h-6 w-6 text-ourPurple-700 hover:scale-110 transform transition duration-400 opacity-50 hover:opacity-100 hover:cursor-pointer"
                }
              />
            </button>
            <button onClick={sendObjectForward}>
              <SendForwardIcon
                className={
                  "h-6 w-6 text-ourPurple-700 hover:scale-110 transform transition duration-400 opacity-50 hover:opacity-100 hover:cursor-pointer"
                }
              />
            </button>
          </span>
          {selectedMode === "ENTERING_TEXT" && <FontDropDown />}
        </div>
      </SmallShadowFlutedGlass>
    </div>
  );
}
const IconButtonForToolbar = ({ isActive, mode, onClick }) => {
  const modeIcons = {
    ENTERING_TEXT: Type,
    CHANGING_COLOR: Palette,
    DRAWING: Pen,
    CHANGING_PEN_SIZE: CircleDot,
    ERASER: Eraser,
    ADDING_IMAGE: Image,
    DROPPING_TOKEN: Wallet,
  };

  const Icon = modeIcons[mode];
  const buttonClasses = `rounded-full border border-4 ${
    isActive ? "border-ourTeal-500 transform " : "border-gray-300/20"
  } group hover:border-ourTeal-500/70 hover:cursor-pointer focus:outline-none `;

  const iconWrapperClasses = `p-2 ${
    isActive
      ? "bg-gradient-to-b from-ourTeal-600/80 via-ourTeal-600/90 to-ourTeal-600 border border-1 border-ourTeal-900"
      : "bg-gray-200"
  } rounded-full group-hover:opacity-70 group-hover:bg-gradient-to-b from-ourTeal-600/80 via-ourTeal-600/90 to-ourTeal-600 group-hover:border-ourTeal-900/30`;

  const iconClasses = `stroke-${
    isActive ? "ourTeal-900" : "gray-400"
  } group-hover:stroke-ourTeal-900`;

  return (
    <button className={buttonClasses} onClick={onClick} aria-pressed={isActive}>
      <div className={iconWrapperClasses}>
        <Icon className={iconClasses} size={18} />
      </div>
    </button>
  );
};
