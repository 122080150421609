import { SolanaIcon } from "../../assets/icons/iconComponents";
import etherIcon from "../../assets/etherIcon.png";
export default function PricingSection({
  showSOL,
  txCost,
  solPrice,
  no_of_tx,
}) {
  return (
    <div className="flex flex-col gap-2 py-2">
      <span className="flex w-full justify-between items-center">
        <p className="text-sm text-grayscale-700">Number of Transactions</p>
        <p className="text-grayscale-500">{no_of_tx}</p>
      </span>
      <span className="flex w-full justify-between items-center">
        <span className="text-sm text-grayscale-700">Mint Price</span>
        <PricingCell
          price_SOL={`${txCost.mint_price}`}
          price_USD={getPriceInUSD(txCost.mint_price, solPrice)}
          showSOL={showSOL}
        />
      </span>

      <span className="flex w-full justify-between items-center">
        <span className="text-sm text-grayscale-700">Network Fees</span>
        <PricingCell
          price_SOL={`${txCost.estNetworkFee}`}
          price_USD={getPriceInUSD(txCost.estNetworkFee, solPrice)}
          showSOL={showSOL}
        />
      </span>
      <span className="flex w-full justify-between items-center">
        <span className="text-sm text-grayscale-700">Total</span>
        <PricingCell
          price_SOL={`${txCost.total}`}
          price_USD={getPriceInUSD(txCost.total, solPrice)}
          is_total
          showSOL={showSOL}
        />
      </span>
    </div>
  );
}

function PricingCell({ price_USD, price_SOL, is_total = false, showSOL }) {
  const usd_display_price = price_USD <= 0 ? "<$0.01" : ` $${price_USD}`;

  const pricingDisplay = showSOL ? (
    <div>
      <span className="flex gap-1 items-center">
        <img src={etherIcon} className="h-4 w-4" />
        <p>{parseFloat(price_SOL).toFixed(3)}</p>
      </span>
    </div>
  ) : (
    <span>{usd_display_price}</span>
  );

  price_SOL = parseFloat(price_SOL).toFixed(4);
  return (
    <div
      className={`text-md text-grayscale-900  gap-2 ${
        is_total ? "font-bold" : "font-semibold opacity-80"
      }`}
    >
      {pricingDisplay}
    </div>
  );
}
function getPriceInUSD(solAmount, solPrice) {
  return (solAmount * solPrice).toFixed(2);
}
