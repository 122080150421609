import { XCircle } from "lucide-react";
import { SolanaIcon } from "../../assets/icons/iconComponents";
import { useWallDisplayContext } from "../../utils/contexts/wallDisplayContext";
import ethIcon from "../../assets/etherIcon.png";
export default function SingleCellForPurchaseRow({ showSOL, solPrice, cell }) {
  const solAmount = cell.current_price;
  const formattedSolPrice = (solPrice * solAmount).toFixed(2);
  const { removeFromShoppingCart } = useWallDisplayContext();
  const priceSection = showSOL ? (
    <span className="flex gap-1 items-center">
      <img src={ethIcon} className="h-12 w-12" />
      <p>{solAmount}</p>
    </span>
  ) : (
    <p>${formattedSolPrice}</p>
  );
  return (
    <div className="flex w-full h-min justify-between items-center">
      <div className="flex w-full space-x-2   items-center">
        <span className="w-[45px] h-[45px] bg-ourBlue-900 rounded-xl">
          {""}
        </span>
        <span className="flex flex-col ">
          <p className="text-grayscale-900 font-semibold text-[16px]">
            owner {/* {cell.wall_id} */}
          </p>
          <span className="flex px-[4px] py-[3px] bg-grayscale-300/50 rounded-md w-min  items-center justify-center">
            <p className="text-grayscale-500 font-regular text-[12px] ">
              {cell.x_position}
            </p>
            <p className="text-grayscale-500 font-regular text-[12px] ">
              {cell.y_position}
            </p>
          </span>
        </span>
      </div>
      <div className="flex items-center gap-2 mr-1">
        <div
          className="flex items-center justify-end text-[15px] text-grayscale-700 font-bold"
          style={{ width: "100px" }}
        >
          {priceSection}
        </div>
        <button onClick={() => removeFromShoppingCart(cell)}>
          <XCircle
            size={14}
            className="stroke-grayscale-400 hover:fill-red-300 hover:stroke-red-400 hover:scale-110 transition duration-800 ease hover:cursor-pointer"
          />
        </button>
      </div>
    </div>
  );
}
