import { motion } from "framer-motion";
import ufoWifFriends from "../../assets/icons/ufoWifFriends.png";
import {
  KidoK,
  KidoI,
  KidoD,
  KidoO,
} from "../../assets/icons/logoLetters/logoLettersExport";
import "./ufoStyles.css";
import sky from "../../assets/landingPageImages/sky.png";
import palmTree from "../../assets/landingPageImages/palmTree.png";
export default function FloatingUFOWifFriends({ cellSize }) {
  return (
    <div
      className="w-full h-full items-center justify-center flex flex-col gap-12 overflow-clipped relative"
      style={{
        height: `${cellSize - 50}px`,
        backgroundImage: `url(${sky})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <motion.div
        className="ufo-float absolute"
        transition={{
          duration: 2,
          ease: "easeInOut",
        }}
      >
        <img src={ufoWifFriends} alt="UFO with friends" />
      </motion.div>
      <img src={palmTree} alt="palm trees briv" />
      {/* <KidoO className="stroke-ourOrange-900 fill-ourOrange-900 h-12 w-12" /> */}
    </div>
  );
}
