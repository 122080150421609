import { useAuthenticationClientContextTwo } from "../../testing/auth2Flow/onboardingContextClientTwo";
import "./activityDisplay.css";
import solIcon from "../../../assets/SOL.png";
import {
  ShyftIcon,
  SolScanIcon,
  SolanaIcon,
} from "../../../assets/icons/iconComponents";

import { PublicKey, clusterApiUrl, Connection } from "@solana/web3.js";
import { useWallet } from "@solana/wallet-adapter-react";
import { getTokensForCollection } from "../../../utils/helpers/get_collection";

export default function ActivityDisplay() {
  const { currentUser } = useAuthenticationClientContextTwo();
  const wallet = useWallet();

  return (
    <div className="flex  w-full inter relative gap-2">
      <div className="flex w-2/3 flex-col">
        <h1 className="text-2xl font-semibold text-grayscale-400">Today</h1>
        <div className="flex justify-between w-full bg-ourPurple-600/10 rounded-xl gap-2 items-center px-6 py-3 ">
          <div className="flex flex-col   justify-center">
            <div className="flex gap-2 items-center">
              <div className="flex relative h-8 w-14">
                <img
                  src={currentUser.pfp_url}
                  className="h-8 w-8 rounded-full absolute border border-1 border-white "
                />
                <img
                  src={currentUser.pfp_url}
                  className="h-8 w-8 rounded-full absolute left-6 border border-1 border-white"
                />
              </div>
              <p className="text-lg font-medium text-grayscale-600">
                {currentUser.user_name} minted 12 cells
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-1 items-center">
            <p className="text-sm font-medium text-grayscale-500">
              12 mins ago
            </p>
            <span className="flex gap-2 items-center">
              <SolanaIcon size={"3"} />
              <p className="text-sm font-medium text-grayscale-500"> 2.2</p>
              <p className="text-sm font-medium text-grayscale-300">|</p>
              <ShyftIcon size={"4"} />
              <SolScanIcon size={"4"} />
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-1/3 ">
        <h1 className="text-2xl font-semibold text-grayscale-400">Stats</h1>
        <div className="bg-white p-3">
          {/* <button onClick={() => getTokensForCollection()}>fuck</button> */}
        </div>
      </div>
    </div>
  );
}

function getCollection() {}
