export default function AuthenticatedTrending() {
  return (
    <div className="flex flex-col bg-ourBlue-400 text-ourBlue-800 font-sans font-bold h-full w-full rounded-3xl">
      <div
        className="flex flex-col items-start justify-end rounded-3xl p-6 w-1/6 h-max bg-white bg-opacity-60"
        style={{
          // boxShadow: "inset 0 0 12px #CDD3FE",
          transition: "width 1s",
          position: "relative",
          overflow: "hidden",
        }}
      >
        TRENDING
      </div>
    </div>
  );
}
