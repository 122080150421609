import React, { useState } from "react";
import { Store, SlidersHorizontal, ZoomIn } from "lucide-react";
import "./orbitBar.css";
import { useAuthenticationClientContextTwo } from "../../pages/testing/auth2Flow/onboardingContextClientTwo";

export default function OrbitHelperBar({
  onScaleChange,
  currentScale,
  showPrices,
  onToggleShowPrices,
}) {
  const [showTools, setShowTools] = useState(false);
  const { currentUser } = useAuthenticationClientContextTwo();
  const toggleTools = () => {
    setShowTools(!showTools);
  };
  // Additional styles for floating the bar above the grid
  const floatingBarStyle = {
    position: "absolute", // Position the bar absolutely
    bottom: "20px", // Distance from the bottom of the parent container
    left: "50%", // Center the bar horizontally
    transform: "translateX(-50%)", // Offset the bar by half its width to center it
    zIndex: 1000, // High z-index to ensure it floats above other elements
  };

  const handleZoomSliderChange = (e) => {
    onScaleChange(Number(e.target.value));
  };
  return (
    <div
      className={`flex flex-col items-center bg-ourEgg  p-2   shadow-xl ease-linear ${
        showTools ? "rounded-xl w-64" : "rounded-full w-52"
      }`}
      style={floatingBarStyle} // Apply the floating style
    >
      {showTools && (
        <div className="flex flex-col space-y-2 pt-2">
          <div className="flex items-center space-x-2">
            <ZoomIn className="w-6 h-6" />
            <input
              type="range"
              className="w-full"
              min="0.2" // Minimum scale factor
              max="3" // Maximum scale factor
              step="0.025" // Step size
              value={currentScale}
              onChange={handleZoomSliderChange}
            />
          </div>
          <div className="flex items-center justify-between bg-ourEgg  rounded-md">
            <p className="font-bold">Show Prices</p>
            <ToggleSwitch isOn={showPrices} onToggle={onToggleShowPrices} />
          </div>
        </div>
      )}
      <div
        className={`flex items-center justify-center space-x-4 w-full ${
          showTools ? "mt-2" : "my-auto"
        }`}
      >
        <Store className="w-6 h-6 stroke-ourTeal-900" />
        <img
          src={currentUser.pfp_url}
          alt="pfp"
          className="w-10 h-10 rounded-full"
        />
        <button onClick={toggleTools}>
          <SlidersHorizontal className="w-6 h-6 stroke-ourTeal-900" />
        </button>
      </div>
    </div>
  );
}

const ToggleSwitch = ({ isOn, onToggle }) => {
  return (
    <div
      className={`w-12 h-7 flex items-center bg-gray-300 rounded-lg p-1 cursor-pointer ${
        isOn ? "bg-ourPurple-500" : "bg-borderPrimary"
      }`}
      onClick={onToggle}
      style={{
        boxShadow: "inset 0 0 10px #D3D3D3",
        transition: "width 0.3s",
        position: "relative", // Position relative for absolute child elements
      }}
    >
      {/* Switch */}
      <div
        className={` w-5 h-5 rounded-md shadow-md transform ${
          isOn
            ? "bg-ourPurple-900 translate-x-5"
            : " bg-insetPrimary translate-x-0"
        } transition-transform`}
      ></div>
    </div>
  );
};
