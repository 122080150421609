export default function SendBackIcon({ className }) {
  return (
    <svg
      width="176"
      height="176"
      viewBox="0 0 176 176"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid"
      fill="currentColor" // Set fill to currentColor
      className={className} // Apply className prop
    >
      <rect width="124.644" height="126.182" rx="16" />
      <path
        d="M129.721 48.8176V66.4004H156.176V157.934H68.6711V131.043H50.3561V159C50.3561 167.837 57.5196 175 66.3561 175H159C167.837 175 175 167.837 175 159V64.8176C175 55.9811 167.837 48.8176 159 48.8176H129.721Z"
        // fill="#757575"
        // stroke="#757575"
      />
    </svg>
  );
}
