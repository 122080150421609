import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

class CellImageManager {
  constructor(storage, setImageFiles) {
    this.storage = storage;
    this.setImageFiles = setImageFiles;
  }

  prepareImageForCanvas(imageFile) {
    const uniqueId = uuidv4();
    // Create a blob URL for the image file
    const blobURL = URL.createObjectURL(imageFile);

    this.setImageFiles((prevFiles) => [
      ...prevFiles,
      { id: uniqueId, file: imageFile, url: blobURL },
    ]);

    return { url: blobURL, uniqueId };
  }

  async uploadImage(userId, cellId, imageBlob, uniqueId) {
    try {
      const imageRef = ref(this.storage, `${userId}/${cellId}/${uniqueId}`);
      const snapshot = await uploadBytes(imageRef, imageBlob);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image to Firebase", error);
      throw error;
    }
  }
}

export default CellImageManager;
