import { useState } from "react";
import WallComponentSimplified from "../../../../../components/modularWall/simplifiedWallComponent";
import { ChevronRight } from "lucide-react";
import UnmintedCellSidebarContainer from "./orbModalUnmintedSidebar";
import { useWallDisplayContext } from "../../../../../utils/contexts/wallDisplayContext";
import CheckoutModalDisplay from "../../../../../components/checkoutDisplay/checkoutModalDisplay";
export default function OrbModalWallDisplay({
  showSidebar,
  displayedUser,
  setShowSidebar,
  handleHideSidebar,
  currentUser,
}) {
  const [showPurchaseFlow, setShowPurchaseFlow] = useState(false);
  const { selectedDisplayOption } = useWallDisplayContext();
  return (
    <>
      <div
        style={{ width: showSidebar ? "70%" : "100%" }}
        className="flex grow h-full"
      >
        <WallComponentSimplified
          userId={displayedUser.friend_id}
          showSidebar={showSidebar}
          setShowCellModal={setShowSidebar}
          displayedUser={displayedUser}
        />
      </div>
      {showSidebar && (
        <div
          style={{
            maxWidth: "500px",
            width: "35%",
            flexBasis: "35%", // Ensures the element takes up 40% of the parent's width
          }}
          className="flex flex-col h-full gap-2  relative"
        >
          <button
            onClick={handleHideSidebar}
            className="absolute h-min top-12 -left-4 transform -translate-y-1/2 bg-grayscale-300 rounded-full shadow-md p-1 opacity-60 hover:opacity-100 hover:scale-110 transition duration-300 ease hover:cursor-pointer z-30"
          >
            <ChevronRight className="stroke-grayscale-800" />
          </button>
          {selectedDisplayOption == "checkout" ? (
            <CheckoutModalDisplay />
          ) : (
            <UnmintedCellSidebarContainer currentUser={currentUser} />
          )}
        </div>
      )}
    </>
  );
}
