import React from "react";
import "./buttonStyles.css";
import { motion } from "framer-motion";

export function PrimaryButton({ action, title, disabled }) {
  const disabledClass = disabled ? "opacity-50 cursor-not-allowed" : "";
  return (
    <div
      className={`w-full border border-[2px] border-ourPurple-500/50 rounded-[16px] group transition-all duration-20 border-ourPurple-800/40 font-regular inter active:scale-95 overflow-visible	 transition transform spring duration-400 `}
      // whileHover={{ scale: 1.02 }} // Apply the hover effect here
    >
      <button
        className={`flex w-full items-center justify-center bg-gradient-to-t from-ourPurple-800 to-ourPurple-700 via-ourPurple-800/90 py-2 px-6 text-white font-bold rounded-[14px] duration-10 border border-ourPurple-900 border-1 inter ${disabledClass}`}
        onClick={action}
      >
        {title}
      </button>
    </div>
  );
}
export function SecondaryButton({ action, title }) {
  return (
    <div
      className={`w-full border border-4 border-ourTeal-600/50 rounded-2xl group  transition-all duration-20 hover:border-ourTeal-800/50 inter `}
    >
      <button
        className={`flex w-full items-center justify-center bg-gradient-to-t from-ourTeal-800 to-ourTeal-800 via-ourTeal-800/90 py-2 px-6 text-white font-bold rounded-xl duration-20 border border-ourTeal-900 border-1  inter `}
        onClick={action}
      >
        {title}
      </button>
    </div>
  );
}
export function SecondaryButtonWifIcon({ action, title, icon }) {
  return (
    <div
      className={`w-full border border-4 border-ourTeal-600/50 rounded-2xl group  transition-all duration-20 hover:border-ourTeal-800/50 inter `}
    >
      <button
        className={`flex w-full items-center justify-center bg-gradient-to-t from-ourTeal-800 to-ourTeal-800 via-ourTeal-800/90 py-2 px-6 text-white font-bold rounded-xl duration-20 border border-ourTeal-900 border-1  inter `}
        onClick={action}
      >
        <div className="flex gap-2 items-center">
          <span>{icon}</span>
          {title}
        </div>
      </button>
    </div>
  );
}
export function PrimaryIconButton({ action, title, color, icon }) {
  return (
    <div
      className={`w-full border border-4 border-${color}-500/30 rounded-2xl group hover:scale-100 transition-all duration-20 inter `}
    >
      <button
        className={`flex w-full items-center justify-center bg-gradient-to-t from-${color}-800 to-${color}-800 via-${color}-800/90 py-2 px-6 text-white font-bold rounded-xl duration-10 border border-${color}-900 border-1 inter `}
        onClick={action}
      >
        <div className="flex space-x-2">
          <span>{icon}</span>
          {title}
        </div>
      </button>
    </div>
  );
}
export function PrimaryButtonWithDisable({ action, title, color, disabled }) {
  const buttonClasses = `flex w-full items-center justify-center bg-gradient-to-t from-${color}-800 to-${color}-800 via-${color}-800/90 py-2 px-6 text-white font-bold rounded-xl duration-10 border border-${color}-900 border-1 ${
    disabled ? "opacity-50 cursor-not-allowed" : ""
  }`;
  const divClasses = `w-full border border-4 border-${color}-500/50 rounded-2xl group transition-all duration-20 ${
    disabled ? "" : "hover:border-ourPurple-800/40"
  }`;
  return (
    <div className={divClasses} style={{ borderRadius: "20px" }}>
      <button
        className={buttonClasses}
        onClick={action}
        disabled={disabled}
        style={{ borderRadius: "16px" }}
      >
        {title}
      </button>
    </div>
  );
}
export function SecondaryButtonWithDisable({
  action,
  title,
  color,
  disabled = false,
}) {
  const buttonClasses = `flex w-full items-center justify-center bg-gradient-to-t from-ourTeal-800 to-ourTeal-800 via-ourTeal-800/90 py-2 px-6 text-white font-bold rounded-xl duration-10 border border-ourTeal-900 border-1 ${
    disabled ? "opacity-50 cursor-not-allowed" : ""
  }`;
  const divClasses = `w-full border border-4 border-ourTeal-500/50 rounded-2xl group transition-all duration-20 ${
    disabled ? "" : "hover:border-ourTeal-800/40"
  }`;
  return (
    <div className={divClasses} style={{ borderRadius: "20px" }}>
      <button
        className={buttonClasses}
        onClick={action}
        disabled={disabled}
        style={{ borderRadius: "16px" }}
      >
        {title}
      </button>
    </div>
  );
}
export function SmallPrimaryButtonWithDisable({
  action,
  title,
  color,
  disabled,
}) {
  const disabledClass = disabled ? "opacity-50 cursor-not-allowed" : "";

  return (
    <div
      className={
        "w-full border border-[2px] border-ourPurple-500/50 rounded-[16px] group transition-all duration-20 border-ourPurple-800/40 font-regular inter active:scale-95 overflow-visible	 transition transform spring duration-400"
      }
      style={{ borderRadius: "16px" }}
    >
      <button
        className={`flex w-full items-center justify-center bg-gradient-to-t from-ourPurple-800 to-ourPurple-700 via-ourPurple-800/90 py-2 px-6 text-white font-bold rounded-[14px] duration-10 border border-ourPurple-900 border-1 inter ${disabledClass}`}
        onClick={action}
        disabled={disabled}
        style={{ borderRadius: "12px" }}
      >
        <p className="text-sm">{title}</p>
      </button>
    </div>
  );
}
export function SmallSecondaryButtonWithDisable({
  action,
  title,
  color,
  disabled = false,
}) {
  const buttonClasses = `flex w-full items-center justify-center bg-gradient-to-t from-ourTeal-800 to-ourTeal-800 via-ourTeal-800/90 py-2 px-6 text-white font-bold rounded-xl duration-10 border border-ourTeal-900 border-1 ${
    disabled ? "opacity-50 cursor-not-allowed" : ""
  }`;
  const divClasses = `w-full border border-4 border-ourTeal-500/50 rounded-2xl group transition-all duration-20 ${
    disabled ? "" : "hover:border-ourTeal-800/40"
  }`;
  return (
    <div className={divClasses} style={{ borderRadius: "16px" }}>
      <button
        className={buttonClasses}
        onClick={action}
        disabled={disabled}
        style={{ borderRadius: "12px" }}
      >
        <p className="text-sm">{title}</p>
      </button>
    </div>
  );
}
