import { useState, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";
import FlutedGlass from "../../pages/authentication/flutedGlass";
import {
  Info,
  PlusCircle,
  BrickWall,
  UserRoundCheck,
  UserRoundMinus,
  Orbit,
  Store,
  ArrowLeft,
  Check,
  Minus,
  Plus,
} from "lucide-react";
import { useWallDisplayContext } from "../../utils/contexts/wallDisplayContext";
import FriendStatusButton from "../elements/friendStatusButton";

export function OtherUserOrbitBar({ displayedUser }) {
  // Additional styles for floating the bar above the grid
  const {
    showMarketHelper,
    setShowMarketHelper,
    setShowPrices,
    cellShoppingCart,
    selectedDisplayOption,
    setSelectedDisplayOption,
  } = useWallDisplayContext();

  const [flutedGlassStyle, setFlutedGlassStyle] = useSpring(() => ({
    to: { width: "4rem", height: "8rem" },
    from: { width: "11rem", height: "22rem" },
  }));

  const handleSecondaryButtonAction = () => {
    if (selectedDisplayOption === "wall") {
      const currentUrl = window.location.href;
      const newUrl = `${currentUrl}?for-friend_id=${displayedUser.friend_id}`;
      window.history.pushState({ path: newUrl }, "", newUrl);
      setSelectedDisplayOption("orbit");
    } else if (selectedDisplayOption === "orbit") {
      const currentUrl = window.location.href;
      const urlWithoutParams = currentUrl.split("?")[0];
      window.history.pushState(
        { path: urlWithoutParams },
        "",
        urlWithoutParams
      );
      setSelectedDisplayOption("wall");
    } else if (selectedDisplayOption === "checkout") {
      setSelectedDisplayOption("wall");
    }
  };
  function handleMarketButtonPress() {
    if (selectedDisplayOption === "checkout") {
      setSelectedDisplayOption("wall");
    } else {
      setShowMarketHelper((prevState) => !prevState); // Toggle the showMarketHelper state
      setShowPrices((prevState) => !prevState); // Toggle the showMarketHelper state

      setFlutedGlassStyle({
        to: {
          width: showMarketHelper ? "11rem" : "96rem",
          height: showMarketHelper ? "22rem" : "48rem",
        },
      });
    }
  }

  const primaryButtonIcon =
    selectedDisplayOption === "checkout" ? ArrowLeft : Store;
  const secondaryButtonIcon =
    selectedDisplayOption === "wall" ? Orbit : BrickWall;

  // Update the spring animation when showMarketHelper changes
  useEffect(() => {
    setFlutedGlassStyle({
      to: {
        width: showMarketHelper ? "22rem" : "11rem",
        height: showMarketHelper ? "8rem" : "4rem",
      },
    });
  }, [showMarketHelper, setFlutedGlassStyle]);
  const floatingBarStyle = {
    position: "absolute", // Position the bar absolutely
    bottom: "20px", // Distance from the bottom of the parent container
    left: "50%", // Center the bar horizontally
    transform: "translateX(-50%)", // Offset the bar by half its width to center it
    zIndex: 4000, // High z-index to ensure it floats above other elements
  };

  if (!displayedUser) {
    return <div></div>;
  }
  const userPFP = (
    <div className="flex relative items-center justify-center">
      <img
        src={displayedUser.pfp_url}
        alt="user"
        className="h-12 w-12 rounded-full border border-2 border-ourTeal-600 hover:scale-105 transition duration-300 ease hover:cursor-pointer"
      />
      <button>
        <span className="absolute -top-1 -right-1">
          <FriendStatusButton
            friend_id={displayedUser.id || displayedUser.friend_id}
          />
        </span>
      </button>
    </div>
  );
  return (
    <div style={floatingBarStyle}>
      <animated.div style={flutedGlassStyle}>
        <FlutedGlass>
          <div className="flex gap-2 items-center">
            <OtherUserOrbitBarIconButton
              Icon={primaryButtonIcon}
              action={handleMarketButtonPress}
            />
            {userPFP}
            {!showMarketHelper && (
              <>
                <OtherUserOrbitBarIconButton
                  Icon={secondaryButtonIcon}
                  action={handleSecondaryButtonAction}
                />
              </>
            )}
          </div>
        </FlutedGlass>
      </animated.div>
    </div>
  );
}

function OtherUserOrbitBarIconButton({ Icon, action }) {
  return (
    <button onClick={action}>
      <Icon
        className="stroke-ourTeal-900 fill-ourTeal-500 hover:fill-ourTeal-600 hover:scale-110 transition duration-300 ease hover:cursor-pointer"
        size={24}
      />
    </button>
  );
}
