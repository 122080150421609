import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { BrickWall, LogOut, Network } from "lucide-react";
import { useAuthenticationClientContextTwo } from "../../pages/testing/auth2Flow/onboardingContextClientTwo";
import "./layoutProvider.css";
export default function SettingsPopover({
  isExpanded,
  onMouseEnter,
  onMouseLeave,
}) {
  const { setIsAuthenticated } = useAuthenticationClientContextTwo();
  const { disconnect } = useWallet();

  const position = isExpanded ? "left-28" : "left-8";
  const navigate = useNavigate();

  function handleLogOut() {
    disconnect();
    setIsAuthenticated(false);
    localStorage.removeItem("lastVisitedPath"); // Reset the stored path
    navigate("/auth");
  }

  return (
    <div
      className={`absolute bottom-16 ${position} z-40 bg-white w-44 rounded-xl shadow-sm border border-1 border-grayscale-500/20 overflow-hidden transition duration-400`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="flex flex-col gap-2 ">
        <SettingsPopoverRow
          Icon={BrickWall}
          text={"Wall Settings"}
          action={() => console.log("Wall Settings")}
        />
        <SettingsPopoverRow
          Icon={Network}
          text={"Import Network"}
          action={() => console.log("Import Network")}
        />
        <SettingsPopoverRow
          Icon={LogOut}
          text={"Log Out"}
          action={handleLogOut}
        />
      </div>
    </div>
  );
}
function SettingsPopoverRow({ Icon, text, action }) {
  return (
    <button
      className="flex w-full items-start justify-start gap-2 group hover:bg-ourTeal-500/40 p-3 hover:cursor-pointer transition duration-300 ease font-provider font-medium"
      onClick={action}
    >
      <Icon
        size={20}
        className="stroke-gray-400 group-hover:stroke-ourTeal-900 group-hover:scale-105 "
      />
      <p className="text-sm  group-hover:text-ourTeal-900 text-grayscale-600  ">
        {text}
      </p>
    </button>
  );
}
