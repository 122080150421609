import React from "react";

export default function ActionButtonRow({
  primaryAction,
  secondaryAction,
  userBalance,
  cartTotal,
}) {
  const disabled = cartTotal > userBalance || cartTotal < 0.005;
  function buttonText() {
    if (disabled && cartTotal >= 0.006) {
      return "Not Enough SOL";
    } else if (disabled && cartTotal < 0.005) {
      return "Add Cells to Continue";
    } else {
      return "Purchase";
    }
  }
  return (
    <span className="flex w-full items-end justify-center gap-2">
      <button
        className={[
          "text-red-700 font-bold text-sm",
          " py-[8px] min-w-[128px]",
          "rounded-[10px] border border-1 border-red-600/20",
          "bg-red-600/20 shadow-[inset_0_1px_1px_rgba(255,255,255,0.4)]",
          "active:shadow-none",
        ].join(" ")}
        onClick={secondaryAction}
      >
        Cancel
      </button>
      <span className="h-min w-full">
        <button
          className={[
            " font-bold text-sm",
            " py-[8px] min-w-[128px] w-full",
            "rounded-[10px] ",
            "active:shadow-none",
            disabled
              ? "opacity-50 cursor-not-allowed bg-ourGold-900 shadow-[inset_0_1px_1px_rgba(255,255,255,0.4)] border border-ourGold-800 text-grayscale-900"
              : "bg-ourTeal-600 shadow-[inset_0_1px_1px_rgba(255,255,255,0.4)] border border-ourTeal-800 text-ourTeal-900",
          ].join(" ")}
          onClick={primaryAction}
          disabled={disabled}
        >
          {buttonText()}
        </button>
      </span>
    </span>
  );
}
