import React, { useState, useEffect, useCallback, useRef } from "react";

import { getUserNetwork } from "../../../utils/helpers/ourAPIHelpers";
import { useAuthenticationClientContextTwo } from "../../testing/auth2Flow/onboardingContextClientTwo";
import { useNavigate } from "react-router-dom";

import {
  handleOrbClick,
  handleClosePopover,
  handleViewOrbit,
  handleViewWall,
} from "./utilities/orbitHandlers";
import * as OrbitEventHandlers from "./utilities/orbitEventHandlers";
import * as OrbitCanvasUtilities from "./utilities/orbitCanvasUtils";
import OrbitNetworkView from "./components/orbitNetworkView";
import "./orbitStyle.css";
import { ArrowBigLeft } from "lucide-react";
import FlutedGlass from "../../authentication/flutedGlass";

// Constants and helper functions outside the component
// Constants
const DIV_SIZE = 8000;
const RADIUS = 250;
const CENTER = { x: DIV_SIZE / 2, y: DIV_SIZE / 2 };
const scaleMin = 0.3;
const scaleMax = 2;
const scaleIncrement = 0.2;
const DRAG_THRESHOLD = 5;

export default function OrbitCanvas() {
  const [isDragging, setIsDragging] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [offset, setOffset] = useState({ x: -483, y: -624 });
  const [scale, setScale] = useState(0.8);
  const [isClick, setIsClick] = useState(true);
  const [loading, setLoading] = useState(true);
  const [network, setNetwork] = useState([]);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [displayedUser, setDisplayedUser] = useState(null);
  const [popoverPosition, setPopoverPosition] = useState({ x: 0, y: 0 });
  const [offsetPreClick, setOffsetPreClick] = useState({ x: 0, y: 0 });
  const [otherUserNetwork, setOtherUserNetwork] = useState(null);
  const { currentUser } = useAuthenticationClientContextTwo();
  const [isViewingOtherUser, setIsViewingOtherUser] = useState(false);
  const [navigationHistory, setNavigationHistory] = useState([]);
  const [currentlyDisplayedUserId, setCurrentlyDisplayedUserId] = useState(
    currentUser.id
  );
  const findCurrentlyViewedUserDetails = () => {
    return navigationHistory.find(
      (user) => user.id === currentlyDisplayedUserId
    );
  };
  // console.log("selected user is", displayedUser);

  const navigate = useNavigate();
  const divRef = useRef(null);

  function handleViewWall() {
    console.log(displayedUser);

    navigate(`/${displayedUser.user_name}/wall`);
  }

  const onOrbClick = (user, position) =>
    handleOrbClick(
      user,
      position,
      setPopoverVisible,
      setDisplayedUser,
      setPopoverPosition,
      setOffset,
      setScale,
      setOffsetPreClick,
      offset
    );

  const onClosePopover = () =>
    handleClosePopover(
      setPopoverVisible,
      setDisplayedUser,
      setScale,
      setOffset,
      offsetPreClick
    );

  const onWheel = (e) =>
    OrbitEventHandlers.handleWheel(
      e,
      scale,
      setScale,
      scaleMax,
      scaleMin,
      scaleIncrement
    );

  const onMouseDown = (e) => {
    if (popoverVisible) return; // Prevent dragging when popover is visible
    OrbitEventHandlers.handleMouseDown(
      e,
      offset,
      setIsDragging,
      setIsClick,
      setStartPos
    );
  };
  const onMouseMove = (e) => {
    if (popoverVisible) return; // Prevent dragging when popover is visible
    OrbitEventHandlers.handleMouseMove(
      e,
      isDragging,
      startPos,
      setIsClick,
      setOffset
    );
  };
  const onMouseUp = () => {
    if (popoverVisible) return; // Prevent dragging when popover is visible
    OrbitEventHandlers.handleMouseUp(setIsDragging, setIsClick);
  };
  const onNavigateToUserWall = (userName) =>
    OrbitEventHandlers.navigateToUserWall(userName, isDragging, navigate);

  useEffect(() => {
    // Add wheel event listener
    const div = divRef.current;
    if (div) {
      div.addEventListener("wheel", onWheel);
    }
    return () => {
      if (div) {
        div.removeEventListener("wheel", onWheel);
      }
    };
  }, [onWheel]);

  const initialOffset = useCallback(
    () => OrbitEventHandlers.calculateInitialOffset(DIV_SIZE),
    [DIV_SIZE]
  );

  useEffect(() => {
    setOffset(initialOffset());

    const handleResize = () => {
      setOffset(initialOffset());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [initialOffset]);

  useEffect(() => {
    // Set initial offset and handle window resize
    const initialOffset = OrbitEventHandlers.calculateInitialOffset(DIV_SIZE);
    setOffset(initialOffset);

    const handleResize = () => setOffset(initialOffset);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch and process network data
    if (currentUser) {
      OrbitCanvasUtilities.fetchAndProcessNetworkData(
        getUserNetwork,
        currentUser,
        setNetwork,
        setLoading,
        RADIUS,
        CENTER
      );
    }
  }, [currentUser]);

  return (
    <div
      className="flex flex-col bg-ourBlue-400 text-ourBlue-800 shadow-lg  font-sans h-full w-full rounded-3xl p-6"
      ref={divRef}
      style={{
        transition: "width 0.5s",
        position: "relative",
        overflow: "hidden",
      }}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseUp}
      onMouseUp={onMouseUp}
    >
      <div
        style={{
          width: "8000px",
          height: "8000px",
          position: "absolute",
          cursor: isDragging ? "grabbing" : "grab",
          transform: `translate(${offset.x}px, ${offset.y}px) scale(${scale})`,
          transition: isDragging ? "none" : "transform 0.2s",
        }}
        onMouseDown={onMouseDown}
        className="bg-ourBlue-400 "
      >
        <OrbitNetworkView
          network={{ data: network, loading }}
          onOrbClick={onOrbClick}
          currentUser={currentUser}
          CENTER={CENTER}
          isCurrentUserView={true}
          popoverVisible={popoverVisible}
          popoverPosition={popoverPosition}
          displayedUser={displayedUser}
          onClosePopover={onClosePopover}
          handleViewOrbit={handleViewOrbit}
          handleViewWall={handleViewWall}
        />
      </div>
    </div>
  );
}

function BottomNavigationForOrbit({ onBack, history, currentUser }) {
  return (
    <div
      className="absolute  h-16 w-64  shadow-sm rounded-2xl  flex items-center justify-center"
      style={{
        left: "50%",
        bottom: "5%",
        transform: "translate(-50%, 50%)",
      }}
    >
      <FlutedGlass>
        <div className="flex px-4 py-2 ">
          <button onClick={onBack}>
            <ArrowBigLeft
              size={42}
              className="stroke-transparent fill-ourBlue-700 opacity-50 hover:opacity-100 hover:cursor-pointer hover:scale-110 transition duration-300"
            />
          </button>
          <span className="flex -space-x-2 ">
            <img
              key={currentUser.id}
              src={currentUser.pfp_url}
              alt={currentUser.user_name}
              className={`rounded-full h-10 w-10 mx-1 border border-2 border-ourTeal-400 z-20 hover:z-40 hover:border-ourTeal-700 hover:cursor-pointer`}
            />

            {history.map((user, index) => (
              <img
                key={index}
                src={user.pfp_url}
                alt={user.user_name}
                className={`rounded-full h-10 w-10 mx-1 border border-2 border-ourTeal-400 z-${index} hover:z-40 hover:border-ourTeal-700 hover:cursor-pointer`}
              />
            ))}
          </span>
        </div>
      </FlutedGlass>
    </div>
  );
}
