import React, { useMemo, useState, useEffect } from "react";

import { useAuthenticationClientContextTwo } from "./onboardingContextClientTwo";
import FlutedGlass from "../../authentication/flutedGlass";
import {
  SecondaryButton,
  SecondaryButtonWifIcon,
} from "../../../components/elements/primaryButton";
import twitterIcon from "../../../assets/icons/xLogo.png";
import {
  TwitterAuthProvider,
  getAuth,
  signInWithPopup,
  getAdditionalUserInfo,
} from "firebase/auth";

export default function TwitterConnectStepTwo() {
  const { referrer, setFirebaseUserDetails, goToStep, firebaseUser } =
    useAuthenticationClientContextTwo(); // Retrieve updateUserDetails from the context

  const auth = getAuth();
  const provider = new TwitterAuthProvider();

  const handleLoginWithTwitter = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
        // You can use these server side with your app's credentials to access the Twitter API.
        const credential = TwitterAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const secret = credential.secret;
        // The signed-in user info.
        const user = result.user;
        console.log(user);
        getAdditionalUserInfo(result);
        setFirebaseUserDetails(user);
        goToStep("showCreateAccount");
      })
      .catch((error) => {
        console.log(error);
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const credential = TwitterAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const LoginButton = () => (
    <SecondaryButtonWifIcon
      title={"Sign In"}
      action={handleLoginWithTwitter}
      icon={<img src={twitterIcon} className="h-4 w-4" />}
    />
  );

  return (
    <div className="flex w-[24em] h-[20em]">
      <FlutedGlass>
        <div className="flex flex-col w-full h-full p-4 justify-between font-sans">
          <div className="flex flex-col text-center items-center justify-center gap-2">
            <img src={referrer.pfpUrl} className="h-24 w-24 rounded-full " />
            <p className="text-2xl font-bold text-gray-900">
              Any friend of {referrer.displayName} is a friend of ours
            </p>
            <p className="text-gray-600">
              Sign In with X (Twitter) below to get started
            </p>
          </div>
          <div className="flex justify-center">
            <LoginButton />
          </div>
        </div>
      </FlutedGlass>
    </div>
  );
}
