import React, { useEffect, useRef } from "react";

import { fabric } from "fabric";
import {
  wassie,
  gary,
  wojak,
  hatNoDog,
  doItToEm,
  datBoi,
  ftxLogo,
  degenHat,
  doKwon,
  BONK,
  palmTree,
  candle,
} from "../../../assets/landingPageImages/imageExport";
import ourLogo from "../../../assets/icons/kido.svg";
import inviteFriendsDrawing from "../../../assets/landingPageImages/inviteYourFriendsDrawing.json";

export default function OnboardingBackgroundCanvas() {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current, {
      height: window.innerHeight,
      width: window.innerWidth,
    });
    const addImageToCanvas = (imgUrl, options = {}) => {
      fabric.Image.fromURL(imgUrl, (img) => {
        const {
          scale = 0.2,
          leftPercent = 0, // Percentage of screen width
          topPercent = 0, // Percentage of screen height
          rotation = 0, // Default rotation
          ...otherOptions
        } = options;

        const left = window.innerWidth * leftPercent;
        const top = window.innerHeight * topPercent;

        img.set({
          left,
          top,
          scaleX: scale,
          scaleY: scale,
          angle: rotation, // Set the rotation angle
          ...otherOptions,
        });

        canvas.add(img).renderAll();

        if (otherOptions.select) {
          canvas.setActiveObject(img);
        }
      });
    };

    // Add images to the canvas

    // addImageToCanvas(ftxLogo, {
    //   leftPercent: 0.12,
    //   topPercent: 0.88,
    //   scale: 0.025,
    //   zIndex: 1,
    //   rotation: 45, // Example rotation
    // });

    // Adding other images with random position and rotation
    addImageToCanvas(wojak, {
      leftPercent: 0.76,
      topPercent: 0.5,
      scale: 0.2,
      zIndex: 1,
      rotation: 45, // Example rotation
    });
    addImageToCanvas(wassie, {
      leftPercent: 0.27,
      topPercent: 0.2,
      scale: 0.2,
      zIndex: 1,
      rotation: -45, // Example rotation
    });
    addImageToCanvas(doItToEm, {
      leftPercent: 0.2,
      topPercent: 0.35,
      scale: 0.3,
      zIndex: 1,
      rotation: 12, // Example rotation
    });
    addImageToCanvas(datBoi, {
      leftPercent: 0.5,
      topPercent: 0.97,
      scale: 1,
      zIndex: 1,
      rotation: 180, // Example rotation
    });

    addImageToCanvas(ourLogo, {
      leftPercent: 0.4,
      topPercent: 0.12,
      scale: 1,
      select: true,
      // No rotation for the logo
    });
    addImageToCanvas(gary, {
      leftPercent: 0.05,
      topPercent: 0.83,
      scale: 0.2,
      rotation: 45, // Example rotation
      zIndex: 1,
      // No rotation for the logo
    });
    addImageToCanvas(degenHat, {
      leftPercent: 0.08,
      topPercent: 0.82,
      scale: 0.12,
      zIndex: 11111,
      rotation: 60, // Example rotation
    });

    addImageToCanvas(doKwon, {
      leftPercent: 0.4,
      topPercent: 0.9,
      scale: 0.1,
      // No rotation for the logo
    });

    addImageToCanvas(palmTree, {
      leftPercent: 0.6,
      topPercent: 0.12,
      scale: 0.5,
      flipX: true,
      // No rotation for the logo
    });
    addImageToCanvas(palmTree, {
      leftPercent: 0.1,
      topPercent: 0.12,
      scale: 0.5,

      // No rotation for the logo
    });
    addImageToCanvas(candle, {
      leftPercent: 0.1,
      topPercent: 0.8,
      scale: 0.5,
    });
    addImageToCanvas(candle, {
      leftPercent: 0.12,
      topPercent: 0.7,
      scale: 0.5,
    });
    addImageToCanvas(candle, {
      leftPercent: 0.14,
      topPercent: 0.6,
      scale: 0.5,
    });
    addImageToCanvas(candle, {
      leftPercent: 0.16,
      topPercent: 0.5,
      scale: 0.5,
    });

    // // Add logo with a specific position
    // for (let i = 0; i < 3; i++) {
    //   addRandomSquiggle();
    // }

    // Resize canvas on window resize
    window.addEventListener("resize", () => {
      canvas.setHeight(window.innerHeight);
      canvas.setWidth(window.innerWidth);
      canvas.renderAll();
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return <canvas ref={canvasRef} />;
}
