import React, { useEffect, useRef, useState, useMemo } from "react";
import { fabric } from "fabric";
import { useWallDisplayContext } from "../../../utils/contexts/wallDisplayContext";
import solIcon from "../../../assets/SOL.png";
import { Lock, Check } from "lucide-react";
import { useSpring, animated } from "@react-spring/web";
import etherIcon from "../../../assets/etherIcon.png";
export default function SingleCellForWall({
  cell,
  x,
  y,
  isDragging,
  setIsDragging,
  scaleFactor,
  onClick,
}) {
  const { showPrices, showMarketHelper, cellShoppingCart } =
    useWallDisplayContext();
  const canvasRef = useRef(null);

  useEffect(() => {
    let canvas;

    if (cell.latest_version_data) {
      let data;
      try {
        if (typeof cell.latest_version_data === "string") {
          data = JSON.parse(cell.latest_version_data);
        } else {
          data = cell.latest_version_data;
        }

        if (canvasRef.current && (data.drawing || data.images || data.text)) {
          const canvasSize = 200 + scaleFactor;
          const canvas = new fabric.Canvas(canvasRef.current, {
            height: canvasSize,
            width: canvasSize,
            selectable: false,
          });
          data.images.forEach((imgData) => {
            fabric.Image.fromURL(imgData.src, (img) => {
              img.set({
                left: imgData.left * scaleFactor,
                top: imgData.top * scaleFactor,
                scaleX: imgData.scaleX * scaleFactor,
                scaleY: imgData.scaleY * scaleFactor,
                angle: imgData.angle,
                opacity: imgData.opacity,
              });
              img.selectable = false;
              canvas.add(img);
            });
          });

          data.drawing.forEach((drawingData) => {
            const pathString = drawingData.path
              .map((segment) => segment.join(" "))
              .join(" ");
            const path = new fabric.Path(pathString, {
              ...drawingData,
              fill: drawingData.fill || "",
              stroke: drawingData.stroke || "",
              strokeWidth: drawingData.strokeWidth,
              scaleX: scaleFactor,
              scaleY: scaleFactor,
              left: drawingData.left * scaleFactor,
              top: drawingData.top * scaleFactor,
            });
            path.selectable = false;
            canvas.add(path);
          });
          data.text.forEach((textData) => {
            const textObj = new fabric.Text(textData.text, {
              ...textData,
              left: textData.left * scaleFactor,
              top: textData.top * scaleFactor,
              fontSize: textData.fontSize * scaleFactor,
              scaleX: textData.scaleX,
              scaleY: textData.scaleY,
              angle: textData.angle,
              opacity: textData.opacity,
            });
            textObj.selectable = false; // Make text non-selectable
            canvas.add(textObj);
          });
        }
      } catch (error) {
        console.error("Error processing cell data:", error);
      }
    }
    return () => {
      if (canvas) {
        // Dispose of the canvas instance
        canvas.dispose();
        canvas = null; // Clear the reference
      }
    };
  }, [cell.latest_version_data, scaleFactor]);

  const isCellInCart = useMemo(() => {
    return cellShoppingCart.some(
      (cartItem) => cartItem.cell_id === cell.cell_id
    );
  }, [cellShoppingCart, cell.cell_id]);
  const multiSelectDisplay = showMarketHelper && (
    <div
      className={`flex absolute top-2 right-2 border border-1 border-ourTeal-600 rounded-full ${
        isCellInCart ? "h-6 w-6 bg-ourTeal-800" : "  h-4 w-4 "
      } group-hover:opacity-100 m-2 z-40`}
    ></div>
  );

  const enabledDisplay = (
    <div
      className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 flex flex-col gap-1 items-center justify-center z-10 transition duration-400 ${
        isDragging ? "opacity-20" : "opacity-20 group-hover:opacity-100"
      }`}
    >
      <img
        src={cell.pfp_url}
        alt={cell.user_name}
        className="h-12 w-12 rounded-full"
      />
      <p
        className={
          isDragging ? "opacity-20" : "opacity-0 group-hover:opacity-100"
        }
      >
        {cell.user_name}
      </p>
    </div>
  );
  const isMintable = !cell.is_enabled && !showMarketHelper;
  const showLock = !cell.latest_version_data && !cell.is_public && !isMintable;

  const mintDisplay = (
    <div
      className={`flex flex-col w-full h-full items-center justify-center ${
        isDragging ? "opacity-0" : "opacity-0 group-hover:opacity-100"
      } transition duration-400`}
    >
      <span className="flex items-center gap-1">
        <div className="p-1 bg-white rounded-full h-min">
          <img src={etherIcon} alt="Cardano icon" className="w-4 w-4" />
        </div>
        <p className="font-bold text-3xl">{cell.current_price}</p>
      </span>
    </div>
  );
  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  function clickHandler() {
    console.log("clicked");
    onClick();
  }
  return (
    <div
      style={{
        gridColumnStart: x + 1,
        gridRowStart: y + 1,
        userSelect: "none", // Prevent user selection
      }}
      className={`w-50 h-50 box-border border border-ourTeal-600/20 bg-opacity-20 transition-bg duration-300 relative overflow-hidden group ${
        cell.is_enabled ? "bg-ourBlue-400/70" : "bg-ourTeal-400/70"
      } ${isDragging ? "cursor-grabbing" : "cursor-pointer"}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={clickHandler}
    >
      {multiSelectDisplay}
      {cell.is_enabled && enabledDisplay}
      {isMintable && mintDisplay}
      <canvas ref={canvasRef} className="absolute top-0 left-0 z-0" />
    </div>
  );
}
