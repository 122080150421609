import { useAuthenticationClientContextTwo } from "../pages/testing/auth2Flow/onboardingContextClientTwo";
import { Navigate, useLocation } from "react-router-dom";

export const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuthenticationClientContextTwo();
  const location = useLocation();
  if (!isAuthenticated) {
    // Redirect to login page or any other public page
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return children;
};
