import React, { useState, useEffect } from "react";
import { ArrowDown, ArrowUp, Search } from "lucide-react";
import { getRecentTrades } from "../../../../utils/helpers/ourAPIHelpers";
import {
  truncateAddress,
  timeSince,
} from "../../../../utils/helpers/formattingHelpers";
import solTokenIcon from "../../../../assets/solono.png";
import solScanIcon from "../../../../assets/icons/solscan.png";
import "./tradeTableStyles.css";
import etherIcon from "../../../../assets/etherIcon.png";

export default function RecentTradesTable() {
  const [tradeData, setTradeData] = useState([]);
  const [sortField, setSortField] = useState("confirmation_date");
  const [sortOrder, setSortOrder] = useState("desc"); // 'asc' or 'desc'

  useEffect(() => {
    async function fetchTradeData() {
      try {
        const data = await getRecentTrades();
        setTradeData(data);
      } catch (error) {
        console.error("Error fetching trade data:", error);
      }
    }

    fetchTradeData();
  }, []);

  // Function to toggle sort order and field
  const toggleSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  const sortedData = [...tradeData].sort((a, b) => {
    if (sortField === "price_in_sol") {
      return sortOrder === "asc"
        ? a.price_in_sol - b.price_in_sol
        : b.price_in_sol - a.price_in_sol;
    } else {
      // Default to sorting by confirmation_date
      const dateA = new Date(a.confirmation_date);
      const dateB = new Date(b.confirmation_date);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    }
  });
  const renderSortArrow = (field) => {
    if (sortField === field) {
      return sortOrder === "asc" ? (
        <ArrowUp size={14} />
      ) : (
        <ArrowDown size={14} />
      );
    }
    return null;
  };
  return (
    <div className="relative overflow-x-auto   ">
      <div className="overflow-y-auto max-h-96 scrollbar-custom-for-table">
        {" "}
        {/* Set a max height and overflow-y to auto */}
        <table className="w-full bg-borderPrimary text-sm text-left rtl:text-right text-gray-500 rounded-lg">
          <thead className="text-xs text-grayscale-500 uppercase sticky top-0 bg-white">
            {/* Make the header sticky */}
            <tr>
              <th scope="col" className="px-6 py-3">
                Buyer
              </th>
              <th scope="col" className="px-6 py-3">
                Wall
              </th>
              <th
                scope="col"
                className="px-6 py-3 sortable-header"
                onClick={() => toggleSort("price_in_sol")}
              >
                <div className="flex items-center gap-2">
                  Price {renderSortArrow("price_in_sol")}
                </div>
              </th>
              <th
                scope="col"
                className="px-6 py-3 sortable-header"
                onClick={() => toggleSort("confirmation_date")}
              >
                <div className="flex items-center gap-2">
                  TX {renderSortArrow("confirmation_date")}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.map((trade, index) => (
              <SingleTradeRow key={index} trade={trade} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

function SingleTradeRow({ trade }) {
  let profileImage = trade.buyer_has_account
    ? trade.buyer_pfp
    : "https://emojiisland.com/cdn/shop/products/Emoji_Icon_-_Cowboy_emoji_large.png?v=1571606089";
  let displayName = trade.buyer_has_account
    ? trade.buyer_user_name
    : truncateAddress(trade.buyer_address);
  return (
    <>
      <tr
        key={trade.wall_id}
        className="bg-white hover:bg-grayscale-300/10 transition duration-300 ease"
      >
        <th
          scope="row"
          className="px-6 py-2 font-medium text-gray-800 whitespace-nowrap"
        >
          <div className="flex space-x-1 items-center">
            <img src={profileImage} className="rounded-full w-8 h-8" />
            <div className="flex flex-col -space-y-0.5">
              <p className="text-base font-bold">{displayName}</p>
              <p className="text-xs text-gray-500">
                {trade.buyer_has_account
                  ? truncateAddress(trade.buyer_address)
                  : ""}
              </p>
            </div>
          </div>
        </th>
        <td className="px-6 ">
          <div className="flex space-x-1 items-center">
            <img src={trade.wall_owner_pfp} className="rounded-full w-8 h-8" />
            <div className="flex flex-col -space-y-0.5">
              <p className="text-base font-bold text-gray-800">
                {trade.owner_user_name}
              </p>
              <p className="text-xs text-gray-500">
                {truncateAddress(trade.owner_address)}
              </p>
            </div>
          </div>
        </td>
        <td className="px-6">
          <div className="flex items-center gap-2">
            <img src={etherIcon} alt="cardano" className="h-4 w-4" />
            {trade.price_in_sol}
          </div>
        </td>
        <td className="px-6">
          <div className="flex gap-2 justify-center items-center">
            {/* <a
              href={`https://solscan.io/tx/${trade.tx_hash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={solScanIcon}
                alt="View on Solscan"
                className="h-4 w-4"
              />
            </a> */}
            {timeSince(trade.confirmation_date)}
          </div>
        </td>
      </tr>
    </>
  );
}
