import React, { useState, useEffect } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import "../walletConnectStyles.css";

const CustomWalletButton = ({ showNoWalletModal }) => {
  const wallet = useWallet();
  const hasSolanaWallet = typeof window.solana !== "undefined";

  // Function to handle the button click
  const handleButtonClick = () => {
    const hasSolanaWallet = typeof window.solana !== "undefined";

    if (!hasSolanaWallet) {
      console.log("No Solana wallet detected.");
      showNoWalletModal();
    } else {
      console.log("Solana wallet detected!");
      // You can add logic here if needed when a wallet is detected
    }
  };

  return hasSolanaWallet ? (
    <WalletMultiButton />
  ) : (
    <button
      className="wallet-adapter-button wallet-adapter-button-trigger"
      onClick={handleButtonClick}
      disabled
      aria-disabled
    >
      Please Install A Wallet Extension
    </button>
  );
};

export default CustomWalletButton;
