import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
  getUsersByTwitterId,
  getUser,
} from "../../../utils/helpers/ourAPIHelpers";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { verifyUser } from "../../../utils/helpers/ourAPIHelpers";

const AuthenticationClientContextTwo = createContext();

export const useAuthenticationClientContextTwo = () =>
  useContext(AuthenticationClientContextTwo);

export const AuthenticationClientProviderTwo = ({ children }) => {
  const { connected, publicKey } = useWallet();

  const [searchParams, setSearchParams] = useSearchParams();
  const [currentStep, setCurrentStep] = useState("showLandingPage");
  const [referrer, setReferrer] = useState(null);
  const [currentUser, setCurrentUser] = useState({});
  const [firebaseUser, setFirebaseUser] = useState([]);
  const [allNetworkUsers, setAllNetworkUsers] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [shouldNavigate, setShouldNavigate] = useState(false); // New state for navigation trigger
  const [isLoading, setIsLoading] = useState(true);
  const AuthenticationFlowSteps = {
    SHOW_LANDING_PAGE: "showLandingPage", // When the user first lands on the page
    SHOW_WAIT_LIST: "showWaitList", // User signed in but no account exists
    SHOW_CONNECT_TWITTER: "showConnectTwitter", // Valid referral code entered
    SHOW_CREATE_ACCOUNT: "showCreateAccount", // User connected their Twitter account
    SHOW_NO_SOL_IN_WALLET: "noSolInWallet", // User has no SOL in their wallet
    SHOW_GIFT_CLAIM: "showGiftClaim", // User connected a new wallet
    SHOW_WALL_MINT: "showWallMint",
    SHOW_APP: "showApp", // Show the main application interface
  };

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // Store the current path in localStorage on URL change, except for '/auth'
    if (location.pathname !== "/auth" && location.pathname !== "/") {
      localStorage.setItem("lastVisitedPath", location.pathname);
    }
  }, [location.pathname]);
  // Inside your component
  useEffect(() => {
    const checkWalletAndAuthenticate = async () => {
      if (connected && publicKey) {
        try {
          const userData = await verifyUser(publicKey.toString());
          if (userData.userExists) {
            updateCurrentUser(userData.userData);
            setIsAuthenticated(true);
            let lastVisitedPath = localStorage.getItem("lastVisitedPath");
            if (lastVisitedPath === "/auth") {
              lastVisitedPath = "/orbit";
            }
            navigate(lastVisitedPath || "/orbit");
          } else {
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error("Error verifying user:", error);
        }
      } else {
        // If not connected, navigate to /auth page
        setIsAuthenticated(false);
        navigate("/auth");
      }
    };

    checkWalletAndAuthenticate();
  }, [connected, publicKey, navigate, shouldNavigate]);

  // Function to navigate to a specific step
  const goToStep = (step) => {
    if (Object.values(AuthenticationFlowSteps).includes(step)) {
      setCurrentStep(step);
      // Optional: Update URL or perform any other action when the step changes
    } else {
      console.warn("Invalid step provided:", step);
    }
  };

  const setFirebaseUserDetails = (user) => {
    setFirebaseUser(user);
  };

  const updateCurrentUser = (user) => {
    setCurrentUser(user);
  };

  const setReferrerInfo = (referrerData) => {
    setReferrer(referrerData);
  };

  const fetchNetworkUsers = async (twitterIds) => {
    try {
      const fetchedUsers = await getUsersByTwitterId(twitterIds);
      setAllNetworkUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching network users:", error);
    }
  };

  const verifyAndFetchUser = async () => {
    if (connected && publicKey) {
      try {
        const userData = await verifyUser(publicKey.toString());
        if (userData.userExists) {
          updateCurrentUser(userData.userData); // Update the user context
          setIsAuthenticated(true);
          console.log("User verified and data fetched");
        } else {
          setIsAuthenticated(false);
          navigate("/auth");
        }
      } catch (error) {
        console.error("Error verifying user:", error);
      }
    }
  };
  return (
    <AuthenticationClientContextTwo.Provider
      value={{
        currentStep,
        goToStep,
        referrer,
        setReferrerInfo,
        currentUser,
        updateCurrentUser,
        fetchNetworkUsers,
        allNetworkUsers,
        setIsAuthenticated,
        isAuthenticated,
        setFirebaseUserDetails,
        firebaseUser,
        verifyAndFetchUser,
      }}
    >
      {children}
    </AuthenticationClientContextTwo.Provider>
  );
};
