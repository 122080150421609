import React, { useMemo, useState, useEffect } from "react";

// ICONS
import { Twitter } from "lucide-react";

// ELEMENTS
import { PrimaryButton } from "../../../components/elements/primaryButton";
import { PrimaryInputWithErrorStateAndOnChange } from "../../../components/elements/primaryInput";
import CustomWalletButton from "../walletConnectionUI/CustomWalletButton";

// COMPONENTS
import FlutedGlass from "../../authentication/flutedGlass";

// STYLING
import "../walletConnectStyles.css";
import "../../authentication/authenticationPage.css";

// LOGIC
import { useWallet } from "@solana/wallet-adapter-react";
import { checkRefCode } from "../../authentication/onboardingHelpers";
import { useAuthenticationClientContextTwo } from "./onboardingContextClientTwo";
import { DiscordIcon } from "../../../assets/icons/discord";

export default function SplashPageContainer() {
  const [referralCode, setReferralCode] = useState("");
  const [showNoWalletModal, setShowNoWalletModal] = useState(false);
  const { setReferrerInfo, referrer, goToStep } =
    useAuthenticationClientContextTwo();

  const handleInputChange = (e) => {
    setReferralCode(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await checkRefCode({ refCode: referralCode });
      if (response.message === "Referral code is valid") {
        setReferrerInfo(response.referrer);
        goToStep("showConnectTwitter");
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.error("Error checking referral code:", error);
    }
  };

  return (
    <div className={`flex w-96 	h-[24rem] `}>
      <FlutedGlass>
        <div className="flex w-full h-full flex-col items-center  p-4 gap-2">
          <Header />

          <div className="flex h-full flex-col gap-3 w-full">
            <PrimaryInputWithErrorStateAndOnChange
              value={referralCode}
              onChange={handleInputChange}
              placeholder="Referral Code"
            />

            <PrimaryButton
              title={"Check Code"}
              color={"ourPurple"}
              action={handleSubmit}
            />
            <OrContainer />

            <CustomWalletButton
              showNoWalletModal={() => setShowNoWalletModal(true)}
            />
          </div>
          <div className="flex gap-2">
            <a
              href="https://discord.gg/yjy3J25ZjR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Twitter className="w-6 h-6 text-gray-600 hover:text-ourPurple-800 transition duration-400 hover:fill-ourPurple-800 ease hover:rotate-[12deg] hover:scale-105" />
            </a>
            <a
              href="https://discord.gg/yjy3J25ZjR"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DiscordIcon className="w-6 h-6 text-gray-600 hover:text-ourPurple-800 transition duration-400 ease hover:rotate-[12deg] hover:scale-105" />
            </a>
          </div>
        </div>
      </FlutedGlass>
    </div>
  );
}

function Header() {
  return (
    <div className="flex w-full flex-col items-center">
      <p className="font-sans text-4xl font-bold text-gray-600 pb-1">Welcome</p>
      <p className="text-gray-500 text-center">
        Please enter your referral code or sign in if you already have an
        account.
      </p>
    </div>
  );
}
function OrContainer() {
  return (
    <div className="flex w-full items-center">
      <hr className="w-full border-t border-gray-300 " />
      <p className="px-4 font-thin text-gray-600">or</p>
      <hr className="w-full border-t border-gray-300 " />
    </div>
  );
}
