async function fetchFromApi(endpoint, params) {
  try {
    const query = new URLSearchParams(params).toString();
    const url = `https://www.api.wallz.social/${endpoint}?${query}`;

    const response = await fetch(url);
    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      throw new Error(data.message || "Error fetching data");
    }
  } catch (error) {
    console.error(`Error in fetchFromApi: ${endpoint}`, error);
    throw error;
  }
}
async function postToApi(endpoint, body) {
  try {
    const url = `https://www.api.wallz.social/${endpoint}`;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.message || "Error fetching data");
    } else {
      return data;
    }
  } catch (error) {
    console.error(`Error in postToApi: ${endpoint}`, error);
    throw error;
  }
}
async function modificationPost(endpoint, body) {
  try {
    const url = `https://www.api.wallz.social/${endpoint}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errorData = await response.json(); // Assuming the server sends a JSON response with an error message
      throw new Error(
        errorData.error || `HTTP error! Status: ${response.status}`
      );
    }

    console.log(`POST to ${endpoint} was successful.`);
  } catch (error) {
    console.error(`Error in postToApi at ${endpoint}:`, error);
    throw error; // Rethrow the error with the message
  }
}

export async function getUserNetwork(userId) {
  return fetchFromApi("get-network-for-user", { userId });
}

export async function getCellsForWall({ wall_id, user_id }) {
  return fetchFromApi("get-wall-cells", { wallId: wall_id, userId: user_id });
}

export async function getOwnedCells(ownerId) {
  return fetchFromApi("get-owned-cells", { ownerId });
}

export async function getCellVersions(cellId) {
  return fetchFromApi("get-cell-versions", { cellId });
}
export async function verifyUser(userAddress) {
  return fetchFromApi("verify-user", { userAddress });
}
export async function getUsersByTwitterId(twitterIds) {
  return postToApi("get-users-by-twitter-id", { twitterIds });
}
// TRADING
export async function getLeaderboardByMarketCap(page = 1) {
  return fetchFromApi("get-leaderboard-by-market-cap", { page });
}
export async function getRecentTrades() {
  return fetchFromApi("get-recent-trades");
}
export async function searchForUser(username) {
  return fetchFromApi("search-for-user", { username });
}
export async function getCellById(cellId) {
  return fetchFromApi("get-cell-by-id", { cellId });
}
export async function getFriendRequests(userId) {
  return fetchFromApi("get-pending-requests", { userId });
}
export async function getWallOwners(wall_id) {
  return fetchFromApi("get-wall-owners", { wall_id });
}
export async function getWallUpdates(wallId) {
  return fetchFromApi("get-wall-updates", { wallId });
}
export async function getPortfolioData(userId) {
  return fetchFromApi("get-portfolio-data", { userId });
}
export async function getCellsForUsername(username, currentUserId) {
  return fetchFromApi("get-cells-for-username", {
    username,
    userId: currentUserId,
  });
}

export async function createNewUser(user) {
  try {
    await modificationPost("create-user", user);
  } catch (error) {
    throw error; // Rethrow the error for handling in the component
  }
}
export async function acceptFriendRequest({
  requesting_user_id,
  requested_user_id,
}) {
  const requestBody = { requesting_user_id, requested_user_id }; // Correctly format the request body

  try {
    await modificationPost("accept-friend-request", requestBody);
  } catch (error) {
    throw error; // Rethrow the error for handling in the component
  }
}
export async function handleMint({
  tx_id,
  cell_id,
  buyer_id,
  buyer_address,
  wall_id,
  price_paid,
}) {
  const requestBody = {
    tx_id,
    cell_id,
    buyer_id,
    buyer_address,
    wall_id,
    price_paid,
  };

  try {
    await modificationPost("handle-mint", requestBody);
    console.log(requestBody);
  } catch (error) {
    throw error; // Rethrow the error for handling in the component
  }
}
export async function denyFriendRequest({
  requesting_user_id,
  requested_user_id,
}) {
  const requestBody = { requesting_user_id, requested_user_id }; // Correctly format the request body

  try {
    await modificationPost("deny-friend-request", requestBody);
  } catch (error) {
    throw error; // Rethrow the error for handling in the component
  }
}
export async function sendFriendRequest({
  requesting_user_id,
  requested_user_id,
}) {
  const requestBody = { requesting_user_id, requested_user_id }; // Correctly format the request body

  try {
    await modificationPost("send-friend-request", requestBody);
  } catch (error) {
    throw error; // Rethrow the error for handling in the component
  }
}
export async function getFriendshipStatus({ current_user_id, friend_id }) {
  const requestBody = { current_user_id, friend_id };

  try {
    const data = await postToApi("get-friend-status", requestBody);
    return data; // Use the data directly as postToApi already parses the JSON
  } catch (error) {
    console.error("Error in getFriendshipStatus:", error);
    throw error; // Rethrow the error for handling in the component
  }
}
export async function addBookmark({ cell_id, user_id }) {
  try {
    const requestBody = { cell_id, user_id }; // Correctly format the request body
    await modificationPost("add-bookmark", requestBody);
  } catch (error) {
    console.error("Error adding bookmark:", error);
    throw error; // Rethrow the error for handling in the component
  }
}
