import React, { useEffect, useRef, useState } from "react";

import { fabric } from "fabric";

import { processDataOnCanvas } from "../../../utils/helpers/processCellData";

function SingleCellForBrickDisplay({
  cellData,
  size = 500,
  SCALE_FACTOR = 500 / 800,
}) {
  const canvasRef = useRef(null);
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (!cellData) {
      setShouldRender(false);
      return;
    }

    // Clear existing canvas content
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Parse and process new cell data
    const parsedData =
      typeof cellData === "string" ? JSON.parse(cellData) : cellData;
    setShouldRender(true);

    processDataOnCanvas(parsedData, canvasRef, SCALE_FACTOR, size);
  }, [cellData, SCALE_FACTOR, size]);

  useEffect(() => {
    if (shouldRender) {
      // Clear the canvas and redraw content
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Assuming processDataOnCanvas() draws on the canvas
      // Redraw logic here
      const parsedData =
        typeof cellData === "string" ? JSON.parse(cellData) : cellData;
      processDataOnCanvas(parsedData, canvasRef, SCALE_FACTOR, size);

      setShouldRender(false);
    }
  }, [shouldRender, cellData, SCALE_FACTOR, size]);
  return (
    <div className={`overflow-clipped`}>
      <canvas ref={canvasRef} width={size} height={size} />
    </div>
  );
}

export default SingleCellForBrickDisplay;
