// canvasContext.js
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
} from "react";
import CellImageManager from "../../utils/hooks/firebaseStorageHelper";
import { storage } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import SaveCanvas from "../../utils/saveCanvasClass";
import { fabric } from "../../lib/custom.fabric.build";

const CanvasContext = createContext();

export const useCanvasContext = () => useContext(CanvasContext);

const modes = ["ENTERING_TEXT", "DRAWING", "DROPPING_TOKEN", "ERASER"];

export const CanvasProvider = ({ children }) => {
  const [brushColor, setBrushColor] = useState("#000000");
  const [brushSize, setBrushSize] = useState(10);
  const [addImage, setAddImage] = useState(null);
  const [textValue, setTextValue] = useState("");
  const [formattedData, setFormattedData] = useState(null);
  const [canvasData, setCanvasData] = useState(null); // State to hold the serialized canvas data
  const [imageFiles, setImageFiles] = useState([]); // State to hold image files
  const [selectedFont, setSelectedFont] = useState("Inter"); // Default font
  const [fontSize, setFontSize] = useState(20); // Default font
  const [selectedMode, setSelectedMode] = useState(null); // No mode is active by default
  const [isCanvasDisabled, setIsCanvasDisabled] = useState(true);
  const [canvas, setCanvas] = useState(null);
  const [canvasBgColor, setCanvasBgColor] = useState("D9E8F9");
  const canvasRef = useRef(null);

  const saveCanvasHelper = new SaveCanvas();
  const imageManager = new CellImageManager(storage, setImageFiles);
  //   console.log("Upload Manager Instance: ", imageManager);

  const toggleMode = useCallback((mode) => {
    // console.log(mode, selectedMode);
    setSelectedMode((prevMode) => (prevMode === mode ? null : mode));
  }, []);
  // ERASE

  // HANDLE PEN SIZE

  const saveCanvasState = async (canvas) => {
    const serializedData = canvas.toJSON();
    // console.log(serializedData);
    try {
      const sortedData = saveCanvasHelper.formatCanvasData(serializedData);
      setFormattedData(sortedData);
      //   console.log(sortedData);
      // Iterate through all objects and log the uniqueId of images
      serializedData.objects.forEach((obj) => {
        if (obj.type === "image" && obj.uniqueId) {
          console.log("Image Unique ID:", obj.uniqueId);
        }
      });
      setCanvasData(serializedData); // Save the serialized canvas state
    } catch (error) {
      console.error("Error formatting canvas data:", error);
    }
  };
  const handleUndo = (canvas) => {
    if (canvas.current) {
      canvas.current.undo();
    }
  };

  const handleAddImage = useCallback((imageFile, blobURL, imageUUID) => {
    // console.log("calling handle add image", imageFile);
    setAddImage({ url: blobURL, id: imageUUID }); // Store blobURL and UUID
  }, []);
  function extractUuidFromBlobUrl(blobUrl) {
    // console.log(blobUrl);
    return blobUrl.split("/").pop();
  }
  const uploadImages = async (userId, cellId) => {
    try {
      const urlMap = {};
      const validImageFiles = imageFiles.filter(({ file }) => file != null);

      const uploadPromises = validImageFiles.map(({ file, url }) => {
        const canvasId = extractUuidFromBlobUrl(url);
        return imageManager
          .uploadImage(userId, cellId, file, canvasId)
          .then((downloadURL) => {
            urlMap[url] = downloadURL; // Map the blob URL to the Firebase URL
          });
      });

      await Promise.all(uploadPromises);
      // console.log("Images uploaded successfully");
      // console.log(urlMap);
      return urlMap; // Return the mapping
    } catch (error) {
      console.error("Error uploading images:", error);
      throw error;
    }
  };

  const uploadEntireCanvas = async ({ ownerId, brickId }) => {
    try {
      // Upload images first and get the URL mapping
      const urlMap = await uploadImages(ownerId, brickId);

      // Update formattedData with the new image URLs
      formattedData.images.forEach((image) => {
        if (urlMap[image.src]) {
          image.src = urlMap[image.src]; // Replace blob URL with Firebase URL
        }
      });
      console.log(formattedData);
      // Save the updated canvas data to the server
      await saveCanvasHelper.saveToServer(ownerId, brickId, formattedData);
      console.log("Canvas saved successfully");
    } catch (error) {
      console.log("Error uploading entire canvas:", error);
    }
  };
  const sendObjectBackward = () => {
    if (!canvasRef.current) {
      console.error("Canvas is not initialized yet.");
      return;
    }
    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      canvas.sendBackwards(activeObject);
      canvas.renderAll();
      saveCanvasState(canvas); // Update the canvas state to reflect the change
    }
  };

  const sendObjectForward = () => {
    const activeObject = canvas.getActiveObject();
    console.log(activeObject);
    if (activeObject) {
      canvas.bringForward(activeObject);
      canvas.renderAll();
      saveCanvasState(canvas); // Update the canvas state to reflect the change
    }
  };

  const handleEraser = () => {
    canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
    canvas.freeDrawingBrush.width = 10;
    canvas.isDrawingMode = true;
  };
  const isAddingText = selectedMode === modes.ENTERING_TEXT;
  const showPenSizeSlider = selectedMode === modes.CHANGING_PEN_SIZE;

  return (
    <CanvasContext.Provider
      value={{
        brushColor,
        setBrushColor,
        brushSize,
        setBrushSize,
        addImage,
        handleAddImage,
        showPenSizeSlider,
        isAddingText,
        textValue,
        setTextValue,
        saveCanvasState,
        imageManager,
        canvasData,
        handleUndo,
        uploadImages,
        uploadEntireCanvas,
        selectedFont,
        setSelectedFont,
        fontSize,
        setFontSize,
        modes,
        setSelectedMode,
        selectedMode,
        toggleMode,
        isCanvasDisabled,
        setIsCanvasDisabled,
        sendObjectBackward,
        sendObjectForward,
        canvasRef,
        canvas,
        setCanvas,
        handleEraser,
      }}
    >
      {children}
    </CanvasContext.Provider>
  );
};
