import React, { useState, useEffect } from "react";
import { getLeaderboardByMarketCap } from "../../../../utils/helpers/ourAPIHelpers";

export default function TrendingUsersTable() {
  const [leaderboardData, setLeaderboardData] = useState([]);
  useEffect(() => {
    async function fetchLeaderboardData() {
      try {
        const data = await getLeaderboardByMarketCap();
        setLeaderboardData(data);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    }

    fetchLeaderboardData();
  }, []);

  return (
    <div className="relative overflow-x-auto  rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
        <thead className="text-xs text-gray-700 uppercase bg-grayscale-400/10">
          <tr>
            <th scope="col" className="px-4 py-3">
              Rank
            </th>
            <th scope="col" className=" py-3">
              User
            </th>
            <th scope="col" className="px-6 py-3">
              Floor
            </th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.map((user) => (
            <tr key={user.wall_id} className="bg-white ">
              <td className="pl-4 py-2">{user.wall_rank}</td>
              <td
                scope="row"
                className=" py-2 font-medium text-grayscale-700 whitespace-nowrap"
              >
                <div className="flex space-x-1 items-center ">
                  <img
                    src={user.pfp_url}
                    alt={user.user_name}
                    className="rounded-full w-8 h-8"
                  />
                  <div className="flex flex-col -space-y-0.5 justify-center">
                    <p className="text-base font-bold">{user.user_name}</p>
                    <p className="text-xs">{user.current_market_cap} ETH</p>
                  </div>
                </div>
              </td>
              <td className="px-4 py-2">{user.cell_floor} ETH</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
