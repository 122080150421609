import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";

import { useSpring, animated } from "@react-spring/web";
import OurListItemWithAvatar from "../elements/listItemWithAvatar";
import { useCanvasContext } from "../canvas/canvasContext";
import SolScanIcon from "../../assets/SOL.png";
import { getCellVersions } from "../../utils/helpers/ourAPIHelpers";
import {
  PrimaryButton,
  SecondaryButtonWithDisable,
} from "../elements/primaryButton";
import { useAuthenticationClientContextTwo } from "../../pages/testing/auth2Flow/onboardingContextClientTwo";
import { CheckCircle2 } from "lucide-react";
export function TrailingSidebarForEditingPage({ brick }) {
  const [cellHistory, setCellHistory] = useState([]);
  const [showConfirmationBanner, setShowConfirmationBanner] = useState(false);
  const { currentUser } = useAuthenticationClientContextTwo();
  useEffect(() => {
    async function getCellVersionHistory() {
      const versionHistory = await getCellVersions(brick.cell_id);
      setCellHistory(versionHistory);
    }
    getCellVersionHistory();
  }, [brick]);

  const {
    uploadImages,
    uploadEntireCanvas,
    setIsCanvasDisabled,
    isCanvasDisabled,
  } = useCanvasContext();
  const handlePrimaryButtonClick = () => {
    if (isCanvasDisabled) {
      setIsCanvasDisabled(false);
    } else {
      uploadEntireCanvas({
        ownerId: brick.owner_id,
        brickId: brick.cell_id,
      });
      setShowConfirmationBanner(true);
      setIsCanvasDisabled(true);
    }
  };
  const trailingSideBarHeader = (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">
          {brick.wall_owner_username}'s Wall
        </h1>
      </div>
    </div>
  );
  const trailingSidebarPreviousVersions = (
    <div className="w-full">
      <h1 className="text-lg font-sans font-semibold text-gray-800/80">
        Previous Versions
      </h1>
      <div className="rounded-xl border border-2 border-ourBlue-500/40 laptop:h-18 h-72 overflow-y-scroll bg-ourBlue-400/50 rounded-lg">
        <div className="flex flex-col gap-1 p-1 ">
          {cellHistory
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((version) => (
              <OurListItemWithAvatar
                key={version.version_id}
                user={{
                  pfp: version.editor_pfp_url, // Assuming you have the user's profile picture URL
                  username: version.editor_username, // Assuming you have the user's username
                }}
                subheader={`${version.version_no} Changes`} // Assuming 'drawing' is an array of changes
                header={new Date(version.created_at).toLocaleDateString()}
              />
            ))}
        </div>
      </div>
    </div>
  );
  const trailingSidebarPreviousTx = (
    <div>
      <h1 className="text-lg font-sans font-semibold text-gray-800/80">
        Trade History
      </h1>
      <div className="rounded-xl border border-2 border-ourBlue-500/40 h-72 overflow-y-scroll bg-ourBlue-400/50 rounded-lg">
        <p className="p-4 flex items-center w-full justify-center">
          No Trade History Available
        </p>
      </div>
    </div>
  );
  return (
    <div className="flex bg-white/80  sm:h-min lg:h-full  w-full lg:w-1/4 rounded-2xl p-4">
      <div className="flex flex-col w-full gap-2">
        {trailingSideBarHeader}
        {trailingSidebarPreviousVersions}
        {trailingSidebarPreviousTx}
        <PrimaryButton
          action={() => handlePrimaryButtonClick()}
          title={isCanvasDisabled ? "Edit Cell" : "Save Changes"}
          color={"ourPurple"}
        />
        <SecondaryButtonWithDisable
          action={() =>
            uploadEntireCanvas({
              ownerId: brick.owner_id,
              brickId: brick.cell_id,
            })
          }
          title="Buy Similiar"
        />
        {showConfirmationBanner && (
          <ConfirmationBanner
            show={showConfirmationBanner}
            setShow={setShowConfirmationBanner}
          />
        )}
      </div>
    </div>
  );
}

function ConfirmationBanner({ show, setShow }) {
  const styles = useSpring({
    from: { opacity: 0, transform: "scale(0)" },
    to: { opacity: show ? 1 : 0, transform: show ? "scale(1)" : "scale(0)" },
    config: { duration: 200 }, // Animation duration
    onRest: () => {
      if (show) {
        setTimeout(() => setShow(false), 3000); // Fade out after 3 seconds
      }
    },
  });

  useEffect(() => {
    setShow(true); // Trigger the animation when the component mounts
  }, []);
  return (
    <animated.div
      style={styles}
      className="flex w-full gap-2 h-14 items-center bg-ourGreen-400/60 rounded-xl justify-center"
    >
      <CheckCircle2 strokeWidth={3} className="stroke-ourGreen-900" />{" "}
      <p className="font-semibold text-ourGreen-900">Confirmed</p>
    </animated.div>
  );
}
