import axios from "axios";
import qs from "qs";

class TwitterAuthUtils {
  constructor(clientId, clientSecret, redirectUri) {
    this.clientId = clientId;
    this.clientSecret = clientSecret;
    this.redirectUri = redirectUri;
    this.base64Credentials = btoa(`${clientId}:${clientSecret}`);
  }

  // Step 1: Construct an Authorize URL
  getAuthorizeUrl(state, codeChallenge) {
    const scope = "tweet.read users.read follows.read follows.write";
    return `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${encodeURIComponent(
      this.clientId
    )}&redirect_uri=${encodeURIComponent(
      this.redirectUri
    )}&scope=${encodeURIComponent(
      scope
    )}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=plain`;
  }

  // Step 3: Exchange authorization code for an access token
  async exchangeCodeForToken(code, codeVerifier) {
    const tokenUrl = "https://api.twitter.com/2/oauth2/token";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${this.base64Credentials}`,
    };
    const body = {
      code: code,
      grant_type: "authorization_code",
      client_id: this.clientId,
      redirect_uri: this.redirectUri,
      code_verifier: codeVerifier,
    };

    try {
      const response = await axios.post(tokenUrl, qs.stringify(body), {
        headers,
      });
      return response.data; // Contains access_token and refresh_token
    } catch (error) {
      console.error("Error exchanging code for token:", error);
      throw error;
    }
  }

  // Function to get the user's followers list
  async getFollowersList(accessToken, userId) {
    const followersUrl = `https://api.twitter.com/2/users/${userId}/followers`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(followersUrl, { headers });
      return response.data; // Contains the list of followers
    } catch (error) {
      console.error("Error getting followers list:", error);
      throw error;
    }
  }

  // Function to get the user's following list
  async getFollowingList(accessToken, userId) {
    const followingUrl = `https://api.twitter.com/2/users/${userId}/following`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      const response = await axios.get(followingUrl, { headers });
      return response.data; // Contains the list of following
    } catch (error) {
      console.error("Error getting following list:", error);
      throw error;
    }
  }
}

export default TwitterAuthUtils;
