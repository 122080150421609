import { Transaction } from "@solana/web3.js";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useAuthenticationClientContextTwo } from "../../../pages/testing/auth2Flow/onboardingContextClientTwo";
import { handleMint } from "../../helpers/ourAPIHelpers";

export async function pressPurchase({
  setDidPressPurchase,
  cells,
  user_minting,
  no_of_cells,
  tx_total,
  wallet,
  connection,
  displayedUser,
  setIsLoading,
  currentUser,
}) {
  setDidPressPurchase(true);

  const requestBody = {
    cells: cells.map((cell) => ({
      wall_owner_username: displayedUser.user_name,
      cell_x_location: cell.x_position,
      cell_y_location: cell.y_position,
      cell_wall_id: cell.wall_id,
      cell_id: cell.cell_id,
      cell_mint_price: cell.current_price,
      wall_owner_address: displayedUser.user_address,
    })),
    user_minting: user_minting,
    o_auth_key: "pe", // Assuming the o_auth_key is the wallet public key
    no_of_cells: no_of_cells,
    tx_total: tx_total,
  };

  try {
    const response = await fetch("https://kido-mint.onrender.com/mint", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    const serializedTransaction = new Uint8Array(data.serializedTransaction);
    const { blockhash } = await connection.getLatestBlockhash();
    // Deserialize the transaction
    const transaction = Transaction.from(serializedTransaction);
    // The client will now sign the transaction with their wallet
    const signedTx = await wallet.signTransaction(transaction);
    const txId = await connection.sendRawTransaction(signedTx.serialize());
    transaction.recentBlockhash = blockhash;
    for (const cell of cells) {
      await handleMint({
        tx_id: txId,
        cell_id: cell.cell_id,
        buyer_id: currentUser.id,
        buyer_address: user_minting,
        wall_id: cell.wall_id,
        price_paid: cell.current_price,
      });
    }
    setIsLoading(false);
    // Return the transaction ID if successful
    return { success: true, txId };
  } catch (error) {
    // console.error("Error during minting:", error);
    // Return an error state
    return { success: false, error: error.message };
  }
}
