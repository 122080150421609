import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { CheckCircle2, XCircle } from "lucide-react";
import {
  HeliusXRayLogo,
  ShyftIcon,
  SolExplorerIcon,
  SolScanIcon,
} from "../../assets/icons/iconComponents";
import {
  heliusXRayLink,
  shyftLink,
  solanaExplorerLink,
} from "../../utils/helpers/blockExplorerLinkGen";
import { PrimaryButton } from "../elements/primaryButton";
import { useNavigate } from "react-router-dom";

export default function DidPressPurchaseView({
  result,
  isLoading,
  purchaseCell,
}) {
  return (
    <div className="flex flex-col h-full w-full justify-center items-center">
      {isLoading ? (
        <LoadingDots />
      ) : (
        <TransactionResultView result={result} purchaseCell={purchaseCell} />
      )}
      {/* <button onClick={() => setIsLoading(true)}>Reset State</button> */}
    </div>
  );
}

function TransactionResultView({ result, purchaseCell }) {
  const navigate = useNavigate();
  function navigateToCells() {
    navigate("/yourBricks");
  }
  const tx_success_view = (
    <>
      <CheckCircle2 size={64} strokeWidth={3} className="stroke-ourGreen-900" />
      <div className="flex flex-col gap-1">
        <p className="text-ourGreen-900 font-bold text-2xl">
          Transaction Confirmed!
        </p>
      </div>
      <PrimaryButton
        color={"ourPurple"}
        title={"View Cells"}
        action={() => navigateToCells()}
      />
      <BlockExplorerRow tx_id={result.txId} />
    </>
  );

  const tx_failure_view = (
    <>
      <XCircle size={64} strokeWidth={3} className="stroke-red-600" />
      <div className="flex flex-col gap-1">
        <p className="text-red-600 font-bold text-2xl">Transaction Failed</p>
      </div>
      <PrimaryButton
        color={"ourPurple"}
        title={"Try Again"}
        action={purchaseCell}
      />
    </>
  );
  return (
    <div className="flex flex-col h-full w-full justify-center items-center gap-1">
      <motion.div
        initial={{ scale: 0 }}
        animate={{ rotate: 0, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
        className="flex flex-col items-center gap-2 w-full"
      >
        {result.success ? tx_success_view : tx_failure_view}
      </motion.div>
    </div>
  );
}

function BlockExplorerRow({ tx_id }) {
  return (
    <div className="flex flex-col p-2 rounded-lg">
      <div className="flex gap-2 items-center justify-center">
        <a
          href={heliusXRayLink(tx_id)}
          target="_blank"
          rel="noopener noreferrer"
          className="opacity-50 hover:opacity-100 transition duration-400 opacity"
        >
          <HeliusXRayLogo size={"6"} />
        </a>
        <a
          href={shyftLink(tx_id)}
          target="_blank"
          rel="noopener noreferrer"
          className="opacity-50 hover:opacity-100 transition duration-400 opacity"
        >
          <ShyftIcon size={"6"} />
        </a>
        <a
          href={solanaExplorerLink(tx_id)}
          target="_blank"
          rel="noopener noreferrer"
          className="opacity-50 hover:opacity-100 transition duration-400 opacity"
        >
          <SolExplorerIcon size={"6"} />
        </a>
      </div>
    </div>
  );
}
function LoadingDots() {
  return (
    <motion.div
      className="flex space-x-2 justify-center items-center bg-white"
      transition={{
        duration: 2,
        ease: "easeInOut",
      }}
    >
      <div className="h-8 w-8 bg-ourTeal-900 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
      <div className="h-8 w-8 bg-ourTeal-900 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
      <div className="h-8 w-8 bg-ourTeal-900 rounded-full animate-bounce"></div>
    </motion.div>
  );
}
