import { lazy, Suspense } from "react";
import { Outlet, Navigate, useRoutes, useLocation } from "react-router-dom";
import AuthenticationPage from "../../pages/authentication/authenticationPage";

export const authRoutes = [
  {
    path: "auth",
    element: <AuthenticationPage />,
  },
];
