import { X, Search } from "lucide-react";
import { PrimaryInputWithErrorStateAndOnChangeAndIcon } from "../../../../components/elements/primaryInput";
import { truncateAddress } from "../../../../utils/helpers/formattingHelpers";
import { useNavigate } from "react-router-dom";
import "../../yourBricks/scrollbar.css";
import { ArrowUpRight } from "lucide-react";
export const SearchModalInner = ({
  isOpen,
  onClose,
  searchTerm,
  setSearchTerm,
  searchResults,
}) => {
  return (
    <div className="flex flex-col  h-full relative">
      <span>
        <button
          onClick={onClose}
          className="absolute -top-2  p-1 bg-red-400 items-center justify-center rounded-full opacity-40 hover:opacity-100 transition duration-400 ease"
        >
          <X className="stroke-red-900" size={18} />
        </button>
        <div className="flex justify-between items-center pt-6 pb-2">
          <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
      </span>
      <SearchResults results={searchResults} />
    </div>
  );
};
const SearchInput = ({ searchTerm, setSearchTerm }) => (
  <PrimaryInputWithErrorStateAndOnChangeAndIcon
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    placeholder="Search users"
    icon={<Search />}
  />
);

const SearchResults = ({ results }) => (
  <div className="overflow-auto scrollbar-custom">
    {results.length > 0 ? (
      results.map((user) => <SingleUserRowFromSeach user={user} />)
    ) : (
      <div className="p-4 text-gray-600">No results found.</div>
    )}
  </div>
);

function SingleUserRowFromSeach({ user }) {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate(`/${user.user_name}/wall`, { state: { user } });
  };
  return (
    <div
      key={user.id}
      className="flex items-center justify-between px-4 py-1   hover:bg-grayscale-400/30 rounded-xl group transition-all duration-800 ease"
    >
      <button
        onClick={handleNavigation}
        className="flex items-center justify-between w-full"
      >
        <span className="flex  items-center gap-1">
          <img src={user.pfp_url} className="rounded-full w-8 h-8" />
          <div className="flex flex-col -space-y-1 items-start w-36">
            <p className="text-lg font-medium">{user.display_name}</p>
            <p className="text-sm font-regular text-gray-500">
              {user.user_name}
            </p>
          </div>
        </span>
        <span className="flex gap-2">
          <p className="text-sm text-grayscale-600 opacity-0 group-hover:opacity-100 transition duration-700 opacity">
            View Profile
          </p>
        </span>
      </button>
    </div>
  );
}
