import { useEffect, useState } from "react";
import { useAuthenticationClientContextTwo } from "../../../pages/testing/auth2Flow/onboardingContextClientTwo";
import solIcon from "../../../assets/SOL.png";
import solScanIcon from "../../../assets/icons/solscan.png";
import { ArrowUpRight } from "lucide-react";
import { getWallUpdates } from "../../../utils/helpers/ourAPIHelpers";
import etherIcon from "../../../assets/etherIcon.png";
export default function YourWallUpdate() {
  const { currentUser } = useAuthenticationClientContextTwo();
  const [combinedData, setCombinedData] = useState([]);

  useEffect(() => {
    async function fetchWallData() {
      const data = await getWallUpdates(currentUser.wall_id);
      if (data) {
        // Filter out cell versions edited by the currentUser
        const filteredVersions = data.versions.filter(
          (version) => version.user_editing !== currentUser.id
        );

        // Combine and sort trades with the filtered versions
        const combined = [...data.trades, ...filteredVersions];
        combined.sort((a, b) => {
          const dateA = new Date(a.confirmation_date || a.created_at);
          const dateB = new Date(b.confirmation_date || b.created_at);
          return dateB - dateA;
        });

        setCombinedData(combined);
      }
    }
    fetchWallData();
  }, [currentUser]);

  // Display logic for combinedData
  return (
    <div className="flex flex-col gap-2 w-full h-full mt-2 overflow-y-auto">
      <WallMarketCapContainer />
      <div className="flex flex-col overflow-y-auto gap-1 overflow-y-auto">
        {combinedData.map((item, index) => {
          return item.confirmation_date ? (
            <RecentTradeBar key={`trade-${index}`} tradeData={item} />
          ) : (
            <RecentWallChangeBar key={`change-${index}`} versionData={item} />
          );
        })}
      </div>
      {/* Map through combinedData to display recent trades and wall changes */}
    </div>
  );
}
export function RecentTradeBar({ tradeData }) {
  const { currentUser } = useAuthenticationClientContextTwo();

  return (
    <div className="flex w-full px-4 py-2 bg-white rounded-lg">
      <div className="flex w-full gap-2 items-center ">
        <div className="flex w-full gap-2 items-center ">
          <img
            src={tradeData.buyer_pfp_url}
            className="w-8 h-8 rounded-full "
            alt="pfp"
          />
          <p className="font-bold text-grayscale-600 hover:underline hover:cursor-pointer transition duration-400 opacity">
            {tradeData.buyer_user_name}
          </p>
          <p className="font-regular text-grayscale-600">bought </p>
          <span className="flex gap-1 justify-center items-center bg-grayscale-300/50 px-2 py-[3px] rounded-lg text-grayscale-900 text-sm">
            <p>
              {tradeData.x_position}
              {tradeData.y_position}
            </p>
          </span>
          <p className="font-regular text-grayscale-600">for</p>
          <span className="flex gap-1 justify-center items-center">
            <img src={etherIcon} alt="cardano icon" className="h-3 w-3" />
            <p className="font-regular text-grayscale-600">
              {tradeData.price_in_sol}
            </p>
          </span>
        </div>
        <img
          src={solScanIcon}
          alt="cardanoScan icon"
          className="h-4 w-4 opacity-50 hover:cursor-pointer hover:scale-110 hover:opacity-100 transition duration-500 ease"
        />
      </div>
    </div>
  );
}
// export function RecentTradeBar() {
//   const { currentUser } = useAuthenticationClientContextTwo();
//   return (
//     <div className="flex w-full px-4 py-2 bg-white rounded-lg">
//       <div className="flex w-full gap-2 items-center ">
//         <div className="flex w-full gap-2 items-center ">
//           <img
//             src={currentUser.pfp_url}
//             className="w-8 h-8 rounded-full "
//             alt="pfp"
//           />
//           <p className="font-bold text-grayscale-600 hover:underline hover:cursor-pointer">
//             hunter
//           </p>
//           <p className="font-regular text-grayscale-600">bought 32 cells for</p>
//           <span className="flex gap-1 justify-center items-center">
//             <img src={solIcon} alt="cardano icon" className="h-3 w-3" />
//             <p className="font-regular text-grayscale-600">12</p>
//           </span>
//         </div>

//         <img src={solScanIcon} alt="cardanoScan icon" className="h-4 w-4" />
//       </div>
//     </div>
//   );
// }
export function RecentWallChangeBar({ versionData }) {
  const { currentUser } = useAuthenticationClientContextTwo();
  return (
    <div className="flex w-full px-4 py-2 bg-white rounded-lg group">
      <div className="flex w-full gap-2 items-center ">
        <div className="flex w-full gap-2 items-center ">
          <img
            src={versionData.editor_pfp_url}
            className="w-8 h-8 rounded-full "
            alt="pfp"
          />
          <p className="font-bold text-grayscale-600 hover:underline hover:cursor-pointer">
            hunter
          </p>
          <p className="font-regular text-grayscale-600">edited</p>
          <span className="flex gap-1 justify-center items-center bg-grayscale-300/50 px-2 py-[3px] rounded-lg text-grayscale-900 text-sm">
            {versionData.x_position}
            {versionData.y_position}
          </span>
        </div>
        <ArrowUpRight className="stroke-ourGreen-900 opacity-50  group-hover:opacity-100 group-hover:scale-110 transition duration-700 ease" />
      </div>
    </div>
  );
}
export function WallMarketCapContainer() {
  const { currentUser } = useAuthenticationClientContextTwo();
  return (
    <div className="flex w-full px-4 py-2 bg-white justify-between rounded-lg group">
      <span className="flex flex-col  items-center">
        <span className="flex gap-1 justify-center items-center">
          <img src={etherIcon} alt="cardano icon" className="h-4 w-4" />
          <p className="font-bold text-3xl text-grayscale-800">
            {currentUser.wall_market_cap}
          </p>
        </span>{" "}
        <p className="text-sm text-grayscale-400">Wall Market Cap</p>
      </span>
      <span className="flex flex-col  items-center">
        <p className="text-2xl font-bold text-grayscale-800">
          {currentUser.wall_capacity}
        </p>
        <p className="text-sm text-grayscale-400">% of Cells Minted</p>
      </span>
      <span className="flex flex-col  items-center">
        <p className="text-2xl font-bold text-grayscale-800">333k</p>
        <p className="text-sm text-grayscale-400">Total Wall Views</p>
      </span>
    </div>
  );
}
